import {
  GET_GUARD_DASHBOARD,
  GET_GUARD_DASHBOARD_ERROR,
  GET_GUARD_DASHBOARD_SUCCESS,
  GET_GUARD_PROFILE,
  GET_GUARD_PROFILE_ERROR,
  GET_GUARD_PROFILE_SUCCESS,
  GET_JOBLIST,
  GET_JOBLIST_ERROR,
  GET_JOBLIST_SUCCESS,
  GET_JOBS,
  GET_JOBS_ANALYSIS,
  GET_JOBS_ANALYSIS_ERROR,
  GET_JOBS_ANALYSIS_SUCCESS,
  GET_JOBS_ERROR,
  GET_JOBS_SUCCESS,
  GET_LICENCE,
  GET_LICENCE_ERROR,
  GET_LICENCE_SUCCESS,
  GET_RATING,
  GET_RATING_ERROR,
  GET_RATING_SUCCESS,
  PATCH_JOB_STATUS,
  PATCH_JOB_STATUS_ERROR,
  PATCH_JOB_STATUS_SUCCESS,
  POST_JOB_APPLY,
  POST_JOB_APPLY_ERROR,
  POST_JOB_APPLY_SUCCESS,
  POST_LICENCE,
  POST_LICENCE_ERROR,
  POST_LICENCE_SUCCESS,
  PUT_LICENCE,
  PUT_LICENCE_ERROR,
  PUT_LICENCE_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_ERROR,
  PUT_PROFILE_SUCCESS,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  INVITE_CO_WORKERS,
  INVITE_CO_WORKERS_SUCCSESS,
  INVITE_CO_WORKERS_ERROR,
  REFER_GUARD,
  REFER_GUARD_SUCCESS,
  REFER_GUARD_ERROR,
  REFER_CO_WORKERS,
  REFER_CO_WORKERS_SUCCSESS,
  REFER_CO_WORKERS_ERROR,
} from "../actions";

const INIT_STATE = {
  getLicence: [],
  getLicenceLoading: false,
  getLicenceError: "",
  getRating: [],
  getRatingLoading: false,
  getRatingError: "",
  getJobList: [],
  getJobListLoading: false,
  getJobListError: "",
  getJobs: [],
  getJobsLoading: false,
  getJobsError: "",
  getJobsAnalysis: [],
  getJobsAnalysisLoading: false,
  getJobsAnalysisError: "",

  getGuardDashboard: [],
  getGuardDashboardLoading: false,
  getGuardDashboardError: "",
  getGuardProfile: [],
  getGuardProfileLoading: false,
  getGuardProfileError: "",

  postJobApply: [],
  postJobApplyLoading: false,
  postJobApplyError: "",
  postLicence: [],
  postLicenceLoading: false,
  postLicenceError: "",
  putProfile: [],
  putProfileLoading: false,
  putProfileError: "",
  patchJobStatus: [],
  patchJobStatusLoading: false,
  patchJobStatusError: "",
  putLicence: [],
  putLicenceLoading: false,
  putLicenceError: "",
  updateProfileImage: [],
  updateProfileImageLoading: false,
  updateProfileImageError: "",

  inviteCoWorkers: null,
  inviteCoWorkersLoading: false,
  inviteCoWorkersError: "",

  getReferedGuards: null,
  getReferedGuardsLoading: false,
  getReferedGuardsError: "",

  referCoWorkers: null,
  referCoWorkersLoading: false,
  referCoWorkersError: "",
};

const GuardSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LICENCE:
      return {
        ...state,
        getLicence: [],
        getLicenceLoading: true,
      };
    case GET_LICENCE_SUCCESS:
      return {
        ...state,
        getLicence: action.payload,
        getLicenceLoading: false,
        getLicenceError: "",
      };

    case GET_LICENCE_ERROR:
      return {
        ...state,
        getLicenceLoading: false,
        getLicenceError: action.payload?.message,
      };
    case GET_RATING:
      return {
        ...state,
        getRating: [],
        getRatingLoading: true,
      };
    case GET_RATING_SUCCESS:
      return {
        ...state,
        getRating: action.payload,
        getRatingLoading: false,
        getRatingError: "",
      };

    case GET_RATING_ERROR:
      return {
        ...state,
        getRatingLoading: false,
        getRatingError: action.payload?.message,
      };
    case GET_JOBS:
      return {
        ...state,
        getJobs: [],
        getJobsLoading: true,
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        getJobs: action.payload,
        getJobsLoading: false,
        getJobsError: "",
      };

    case GET_JOBS_ERROR:
      return {
        ...state,
        getJobsLoading: false,
        getJobsError: action.payload?.message,
      };
    case GET_JOBS_ANALYSIS:
      return {
        ...state,
        getJobsAnalysis: [],
        getJobsAnalysisLoading: true,
      };
    case GET_JOBS_ANALYSIS_SUCCESS:
      return {
        ...state,
        getJobsAnalysis: action.payload,
        getJobsAnalysisLoading: false,
        getJobsAnalysisError: "",
      };

    case GET_JOBS_ANALYSIS_ERROR:
      return {
        ...state,
        getJobsAnalysisLoading: false,
        getJobsAnalysisError: action.payload?.message,
      };
    case GET_JOBLIST:
      return {
        ...state,
        getJobList: [],
        getJobListLoading: true,
      };
    case GET_JOBLIST_SUCCESS:
      return {
        ...state,
        getJobList: action.payload,
        getJobListLoading: false,
        getJobListError: "",
      };

    case GET_JOBLIST_ERROR:
      return {
        ...state,
        getJobListLoading: false,
        getJobListError: action.payload?.message,
      };

    case GET_GUARD_DASHBOARD:
      return {
        ...state,
        getGuardDashboard: [],
        getGuardDashboardLoading: true,
      };
    case GET_GUARD_DASHBOARD_SUCCESS:
      return {
        ...state,
        getGuardDashboard: action.payload,
        getGuardDashboardLoading: false,
        getGuardDashboardError: "",
      };

    case GET_GUARD_DASHBOARD_ERROR:
      return {
        ...state,
        getGuardDashboardLoading: false,
        getGuardDashboardError: action.payload?.message,
      };
    case GET_GUARD_PROFILE:
      return {
        ...state,
        getGuardProfile: [],
        getGuardProfileLoading: true,
      };
    case GET_GUARD_PROFILE_SUCCESS:
      return {
        ...state,
        getGuardProfile: action.payload,
        getGuardProfileLoading: false,
        getGuardProfileError: "",
      };

    case GET_GUARD_PROFILE_ERROR:
      return {
        ...state,
        getGuardProfileLoading: false,
        getGuardProfileError: action.payload?.message,
      };

    case POST_JOB_APPLY:
      return {
        ...state,
        postJobApply: [],
        postJobApplyLoading: true,
      };
    case POST_JOB_APPLY_SUCCESS:
      return {
        ...state,
        postJobApply: action.payload,
        postJobApplyLoading: false,
        postJobApplyError: "",
      };

    case POST_JOB_APPLY_ERROR:
      return {
        ...state,
        postJobApplyLoading: false,
        postJobApplyError: action.payload.message,
      };
    case POST_LICENCE:
      return {
        ...state,
        postLicence: [],
        postLicenceLoading: true,
      };
    case POST_LICENCE_SUCCESS:
      return {
        ...state,
        postLicence: action.payload,
        postLicenceLoading: false,
        postLicenceError: "",
      };

    case POST_LICENCE_ERROR:
      return {
        ...state,
        postLicenceLoading: false,
        postLicenceError: action.payload.message,
      };

    case PUT_PROFILE:
      return {
        ...state,
        putProfile: [],
        putProfileLoading: true,
      };
    case PUT_PROFILE_SUCCESS:
      return {
        ...state,
        putProfile: action.payload,
        putProfileLoading: false,
        putProfileError: "",
      };

    case PUT_PROFILE_ERROR:
      return {
        ...state,
        putProfileLoading: false,
        putProfileError: action.payload.message,
      };
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        updateProfileImage: [],
        updateProfileImageLoading: true,
      };
    case UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        updateProfileImage: action.payload,
        updateProfileImageLoading: false,
        updateProfileImageError: "",
      };

    case UPDATE_PROFILE_IMAGE_ERROR:
      return {
        ...state,
        updateProfileImageLoading: false,
        updateProfileImageError: action.payload.message,
      };
    case PATCH_JOB_STATUS:
      return {
        ...state,
        patchJobStatus: [],
        patchJobStatusLoading: true,
      };
    case PATCH_JOB_STATUS_SUCCESS:
      return {
        ...state,
        patchJobStatus: action.payload,
        patchJobStatusLoading: false,
        patchJobStatusError: "",
      };

    case PATCH_JOB_STATUS_ERROR:
      return {
        ...state,
        patchJobStatusLoading: false,
        patchJobStatusError: action.payload.message,
      };
    case PUT_LICENCE:
      return {
        ...state,
        putLicence: [],
        putLicenceLoading: true,
      };
    case PUT_LICENCE_SUCCESS:
      return {
        ...state,
        putLicence: action.payload,
        putLicenceLoading: false,
        putLicenceError: "",
      };

    case PUT_LICENCE_ERROR:
      return {
        ...state,
        putLicenceLoading: false,
        putLicenceError: action.payload.message,
      };

    case INVITE_CO_WORKERS:
      return {
        ...state,
        inviteCoWorkersLoading: true,
      };
    case INVITE_CO_WORKERS_SUCCSESS:
      return {
        ...state,
        inviteCoWorkers: action.payload,
        inviteCoWorkersLoading: false,
        inviteCoWorkersError: "",
      };

    case INVITE_CO_WORKERS_ERROR:
      return {
        ...state,
        inviteCoWorkersLoading: false,
        inviteCoWorkersError: action.payload.message,
      };

    case REFER_GUARD:
      return {
        ...state,
        getReferedGuards: null,
        getReferedGuardsLoading: true,
      };
    case REFER_GUARD_SUCCESS:
      return {
        ...state,
        getReferedGuards: action.payload,
        getReferedGuardsLoading: false,
        getReferedGuardsError: "",
      };

    case REFER_GUARD_ERROR:
      return {
        ...state,
        getReferedGuardsLoading: false,
        getReferedGuardsError: action.payload?.message,
      };

    case REFER_CO_WORKERS:
      return {
        ...state,
        referCoWorkersLoading: true,
      };
    case REFER_CO_WORKERS_SUCCSESS:
      return {
        ...state,
        referCoWorkers: action.payload,
        referCoWorkersLoading: false,
        referCoWorkersError: "",
      };

    case REFER_CO_WORKERS_ERROR:
      return {
        ...state,
        referCoWorkersLoading: false,
        referCoWorkersError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
export default GuardSection;
