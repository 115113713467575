import React, { useEffect, useState } from "react";
import LicenseDetailsCard from "./LicenseDetailsCard";
import { useSelector, useDispatch } from "react-redux";
import {
  getTradeMembersAction,
  traderUpdated,
} from "../../../../redux/admin/action";
import toast from "react-hot-toast";

const LicenseDetails = ({ currMember, setCurrentPage, setCurrentMember }) => {
  const { patchLicenseStatus } = useSelector((state) => state.Adminsection);
  const dispatch = useDispatch();
  const [initial, setInitial] = useState(false);
  const [licenseUpdated, setLicenseUpdated] = useState(false);
  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(1);

  useEffect(() => {
    if (patchLicenseStatus.success && licenseUpdated) {
      toast.success(patchLicenseStatus.message);
      // dispatch(traderUpdated())
      dispatch(getTradeMembersAction({ limit: limit, skip: skip }));
      setCurrentPage(1);
      setInitial(false);
    } else if (patchLicenseStatus.success === false && initial === true) {
      toast.error(patchLicenseStatus.message);
      setInitial(false);
    }
  }, [dispatch, patchLicenseStatus]);

  return (
    <div className="w-full gap-4 justify-between py-10 flex-wrap">
      {currMember?.licenses?.map((license, i) => {
        return (
          <LicenseDetailsCard
            key={i}
            license={license}
            currMember={currMember}
            setCurrent
            setInitial={setInitial}
            setIsLicenseStatusUpdated={(data) => {
              setLicenseUpdated(data);
            }}
            setCurrentMember={(data) => {
              setCurrentMember(data);
            }}
          />
        );
      })}
    </div>
  );
};

export default LicenseDetails;
