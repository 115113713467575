import React, { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Modal } from "@mui/material";
import {
  patchClientAction,
  clientUpdated,
  getUsersAction,
} from "../../../../redux/admin/action";
import toast from "react-hot-toast";

const UserTop = ({ currUser }) => {
  const [account, setAccount] = useState(currUser?.status ?? "active");
  const [openModal, setOpenModal] = useState(false);
  const [initial, setInitial] = useState(false);
  const [statusStyles, setStatusStyles] = useState({
    height: "40px",
    minWidth: "150px",
    borderRadius: "9999px",
    color: "#067647",
    backgroundColor: "#ECFDF3",
    border: "1px solid #ABEFC6",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const { patchClientLoading, patchClient } = useSelector(
    (state) => state.SubadminSection
  );

  useEffect(() => {
    if (account === "verified" || account === "active") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#067647",
        backgroundColor: "#ECFDF3",
        border: "1px solid #ABEFC6",
      });
    } else if (account === "suspended") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#F2A50F",
        backgroundColor: "#F2A50F1A",
        border: "1px solid #F2A50F",
      });
    } else if (account === "closed") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#D92D20",
        backgroundColor: "#D92D201A",
        border: "1px solid #D92D20",
      });
    }
  }, [account]);

  useEffect(() => {
    if (patchClient.success && initial === true) {
      toast.success(patchClient.message);
      setOpenModal(false);
      setInitial(false);
      dispatch(getUsersAction({limit: 10, skip: 1, keywords: ""}));
    } else if (patchClient.success === false && initial === true) {
      toast.error(patchClient.message);
      setOpenModal(false);
      setInitial(false);
    }
  }, [dispatch, patchClient]);

  const handleSelect = (value) => {
    setAccount(value);
    setOpenModal(true);
  };

  const statusTrn = {
    active: {
      button: "Activate",
      title: "Activate account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#3F88251A]",
      color: "#3F8825",
      bt: "bg-[#3F8825] text-white",
    },
    suspended: {
      button: "Suspend",
      title: "Suspend account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
    closed: {
      button: "Close",
      title: "Close account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };

  const callDispatch = () => {
    dispatch(
      patchClientAction({
        id: currUser?.id,
        data: { status: account },
      })
    );
    setInitial(true);
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn[account]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <div className="flex justify-between mt-12 mb-6 items-center">
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn[account]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn[account]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn[account]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchClientLoading ? "loading..." : statusTrn[account]?.button}
            </button>
          </div>
        </Box>
      </Modal>

      <div className="flex items-center gap-2">
        <div>
          <h2 className="text-lg text-black font-semibold">
            {currUser.first_name} {currUser.last_name}
          </h2>
          <p className="text-gray-400 text-xs">
            {moment(currUser.updatedAt).format("dddd, MMMM Do YYYY")}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <div>
          <div className="text-[8px] font-medium mb-4">ACCOUNT STATUS</div>

          <Select
            sx={statusStyles}
            value={account}
            onChange={(e) => {
              handleSelect(e.target.value);
            }}
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"suspended"}>Suspend</MenuItem>
            {/* <MenuItem value={"closed"}>Close Account</MenuItem> */}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default UserTop;
