import React, { useCallback, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./GeneralChat.css";
import { IoMdClose, IoMdSend } from "react-icons/io";
import moment from "moment";
import { useSelector } from "react-redux";

const SOCKET_URL = `https://igardu.com/iguardu-be/`;

const socket = io(SOCKET_URL, {
  withCredentials: true,
  transports: ["websocket"],
});

const GeneralChat = ({
  isOpen,
  onClose,
  selectedJob,
  role,
  type,
  roomUrl,
  sendMessageUrl,
  getMessageUrl,
  userId,
}) => {
  const inputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [page, setPage] = useState(1);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const { token, postLogin } = useSelector((state) => state.Authsection);

  const user_data = localStorage.getItem("user_data");
  const userData = JSON.parse(user_data);
  const [conversationId, setConverstaionId] = useState(null);

  useEffect(() => {
    const handleNewMessage = (msg) => {
      const message = {
        user_id: msg?.sender_id,
        name: msg?.name,
        role: msg?.role,
        text: msg?.text,
        created_on: msg?.meta?.created_on,
        id: msg?.meta?.message_id || msg?.meta?.created_on,
      };
      console.log(message, "new message");
      setMessages((prevMessage) => [...prevMessage, message]);
    };

    socket.on("new-message", handleNewMessage);

    return () => {
      socket.off("new-message", handleNewMessage);
    };
  }, [messages, userData.name, role]);

  const getMessages = useCallback(async () => {
    if (type === "general") {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${role}/${getMessageUrl}/${conversationId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data) {
        setMessages(data);
      }
    } else if (type === "individual") {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin/${getMessageUrl}/${conversationId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data) {
        setMessages(data);
      }
    }
  }, [role, getMessageUrl, conversationId, token]);

  useEffect(() => {
    getMessages();
  }, [conversationId]);

  const createRoom = async () => {
    const payloadClient = {
      jobId: selectedJob.job_id ? selectedJob?.job_id : selectedJob?.id,
      guardId: role === "guard" ? userId : selectedJob?.guard_id,
    };
    const payloadGuard = {
      jobId: selectedJob.job_id ? selectedJob?.job_id : selectedJob?.id,
      clientId: role === "client" ? userId : selectedJob?.client_id,
    };
    const payload = {
      user_id: selectedJob?.id,
    };

    if (type === "general") {
      if (role === "client") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadClient),
          }
        );

        const data = await response.json();
        if (data && data?.success) {
          setConverstaionId(data?.data?.conversation_id);
        }
      } else if (role === "admin") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadGuard),
          }
        );

        const data = await response.json();
        if (data && data?.success) {
          setConverstaionId(data?.data?.conversation_id);
        }
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${role}/${roomUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadGuard),
          }
        );

        const data = await response.json();
        if (data && data?.success) {
          setConverstaionId(data?.data?.conversation_id);
        }
      }
    } else if (type === "individual") {
      if (role === "client") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/admin/${roomUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();
        if (data && data?.success) {
          setConverstaionId(data?.data?.conversation_id);
        }
      } else if (role === "guard") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/admin/${roomUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ user_id: selectedJob?.guard_id }),
          }
        );

        const data = await response.json();
        if (data && data?.success) {
          setConverstaionId(data?.data?.conversation_id);
        }
      }
    }
  };

  useEffect(() => {
    createRoom();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      setMessages([]);
      setHasMoreMessages(true);
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchMessages = () => {
      const payload = {
        job_id: selectedJob.job_id ? selectedJob.job_id : selectedJob.id,
        user_type: role,
        page: page,
        limit: 10,
      };
      socket.emit("fetchMessages", payload);
    };

    if (isOpen) {
      fetchMessages();
    }
  }, [page, isOpen, selectedJob, role]);

  useEffect(() => {
    if (chatBodyRef.current && page === 1) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (input.trim()) {
      const payload = {
        text: input,
        conversationId: conversationId,
      };

      if (type === "general") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/${role}/${sendMessageUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();
        if (data && data.success) {
          console.log(data, "message data");
          // const message = {
          //   user_id: userId,
          //   name: userData.name,
          //   role: role,
          //   text: data?.data?.text,
          //   created_on: new Date(),
          // };
          // // setMessages((prevMessages) => [...prevMessages, message]);
        }
      } else if (type === "individual") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/admin/${sendMessageUrl}`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();
        if (data && data.success) {
          console.log(data, "message data");
        }
      }
      setInput("");
    }
  };

  const handleScroll = () => {
    if (
      chatBodyRef.current.scrollTop === 0 &&
      !loadingOlderMessages &&
      hasMoreMessages
    ) {
      setLoadingOlderMessages(true);
      setPage((prevPage) => prevPage + 1);
      setTimeout(() => {
        setLoadingOlderMessages(false);
      }, 500);
    }
  };

  return (
    <div className={`general-chat-screen ${isOpen ? "open" : ""}`}>
      <div className="chat-header">
        <span>
          {selectedJob.title
            ? selectedJob?.title
            : selectedJob?.first_name || selectedJob?.guard_first_name}
        </span>
        <button onClick={onClose}>
          <IoMdClose size={25} color="white" />
        </button>
      </div>
      <div className="chat-body" ref={chatBodyRef} onScroll={handleScroll}>
        {messages.length > 0 ? (
          <>
            {loadingOlderMessages && (
              <div className="flex justify-center items-center">
                <p>Loading older messages...</p>
              </div>
            )}
            {messages.map((msg, index) => (
              <div key={index} className="chat-item">
                <div
                  className="chat-message"
                  style={{
                    backgroundColor:
                      msg?.user_id === userData?.id ? "#007bff" : "#f1f1f1",
                    alignSelf:
                      msg?.user_id === userData?.id ? "flex-end" : "flex-start",
                    color: msg.user_id === userData?.id ? "white" : "black",
                  }}
                >
                  <p>{msg?.text}</p>
                </div>
                <span
                  className="timestamp"
                  style={{
                    fontSize: "10px",
                    alignSelf:
                      msg?.user_id === userData?.id ? "flex-end" : "flex-start",
                  }}
                >
                  {moment(msg?.created_at).format("MMM Do YYYY, h:mm:ss a")} •{" "}
                  {msg?.name} • {msg?.role}
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>No discussion yet!</p>
          </div>
        )}
      </div>

      <div className="chat-input">
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type a message..."
        />
        <button onClick={handleSend} className="active-send">
          <IoMdSend size={25} color="white" />
        </button>
      </div>
    </div>
  );
};

export default GeneralChat;
