import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getTradeMembersAction,
  getTradeMembersBetweenDatesAction,
} from "../../../redux/admin/action";
import { Box, Drawer, Modal } from "@mui/material";
import SingleMemberPage from "./components/singleMemberPage";
import Filters from "./components/filters";
import MemberCard from "./components/memberCard";
import ListLoader from "../../loader/listLoader";
import Pagination from "../../common/Pagination";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { CiSearch } from "react-icons/ci";

const PAGE_SIZE = 8;

function TradeMembers() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tradeMembers, setTradeMembers] = useState([]);
  const { getTradeMembers, getTradeMembersLoading, isTraderUpdated } =
    useSelector((state) => state.Adminsection);
  const [totalResult, setTotalResult] = useState(null);
  const [currMember, setCurrMember] = useState(null);
  const [limit, setLimit] = useState(8);
  const [skip, setSkip] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(false);
  const [exportAttempted, setExportAttempted] = useState(false);
  const { getTradeMembersBetweenDates, getTradeMembersBetweenDatesLoading } =
    useSelector((state) => state.Adminsection);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [initialStatus, setInitialStatus] = useState("all");

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 3 || searchTerm.length === 0) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const filterAction = (action, filter) => {
    dispatch(
      getTradeMembersAction({
        action,
        value: filter,
        limit: limit,
        skip: skip,
        keywords: "",
      })
    );
  };

  useEffect(() => {
    const status = searchParams.get("status");
    if (status) {
      if (status === "hired") {
        setInitialStatus(status);
        filterAction("apc_status", status);
      } else {
        setInitialStatus(status);
        filterAction("status", status);
      }
    }
  }, []);

  const convertToCSV = (data) => {
    const header = Object.keys(data[0]);
    const csvRows = [
      header.join(","),
      ...data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  };

  const replacer = (key, value) => (value === null ? "" : value);

  const downloadCSV = (csv, filename = "trade-members.csv") => {
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (
      getTradeMembersBetweenDates &&
      getTradeMembersBetweenDates.data &&
      exportAttempted
    ) {
      if (getTradeMembersBetweenDates.data.length > 0) {
        const csv = convertToCSV(getTradeMembersBetweenDates.data);
        downloadCSV(csv, `trade-members.csv`);
        setOpen(false);
      } else if (exportAttempted) {
        toast.error("No trade members were found between these dates");
      }
    }
  }, [getTradeMembersBetweenDates, exportAttempted]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    // if (!status) {
    dispatch(
      getTradeMembersAction({
        action: "status",
        value: status ? status : "all",
        limit: limit,
        skip: skip,
        keywords: debouncedSearchTerm ? debouncedSearchTerm : "",
      })
    );
    // }
  }, [skip]);

  useEffect(() => {
    if (totalResult > 0) {
      setTotalPages(Math.ceil(totalResult / PAGE_SIZE));
    }
  }, [totalResult]);

  useEffect(() => {
    if (tradeMembers?.meta?.total > 0) {
      setTotalResult(tradeMembers?.meta?.total);
    }
  }, [tradeMembers]);

  useEffect(() => {
    if (getTradeMembers?.data?.length > 0) {
      setTradeMembers(getTradeMembers);
    }
  }, [getTradeMembers]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    dispatch(
      getTradeMembersAction({
        action: "status",
        value: status ? status : "all",
        limit: limit,
        skip: skip,
        keywords: debouncedSearchTerm ? debouncedSearchTerm : "",
      })
    );
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    if (!status) {
      dispatch(
        getTradeMembersAction({ limit: limit, skip: skip, keywords: "" })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isTraderUpdated === true && currMember === null) {
      dispatch(getTradeMembersAction());
    }
  }, [currMember, dispatch, isTraderUpdated]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  const handlePageChange = useCallback((page) => {
    setSkip(page);
  }, []);

  const getPageNumbers = useCallback(() => {
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }, [totalPages]);

  const selectMember = tradeMembers?.data?.find(
    (member) => member.guard_id === currMember
  );

  const exportCSVWithDate = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      let payload = {
        start: formattedStartDate,
        end: formattedEndDate,
      };

      dispatch(getTradeMembersBetweenDatesAction(payload));
      setStartDate(null);
      setEndDate(null);
      setExportAttempted(true);
    } else {
      toast.error("Please select both start and end dates.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={currMember !== null}
        onClose={() => setCurrMember(null)}
        className="dashboard"
      >
        {currMember !== null ? (
          <SingleMemberPage
            setCurrMember={(data) => {
              setCurrMember(data);
            }}
            currMember={selectMember ? selectMember : currMember}
            setPage={(data) => {
              setSkip(data);
            }}
          />
        ) : null}
      </Drawer>
      <div className="w-full min-h-screen bg-dull md:py-6 py-3 md:px-10 px-3">
        <div className="flex flex-col items-start md:flex-row justify-between gap-5 items-center">
          <div className="border p-2 rounded-[15px] w-full bg-white flex items-center gap-2">
            <CiSearch size={25} color="gray" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search trade members by name or email (type atleast three characters)"
              className="outline-0 w-full"
            />
          </div>
          <button
            className="bg-[#0070de] hover:!bg-[#4585FF] text-white p-2 rounded-[5px] ml-2 w-[230px]"
            onClick={() => setOpen(true)}
          >
            Export to CSV
          </button>
        </div>
        <div className="flex items-center justify-between">
          <Filters
            filter={filterAction}
            initialStatus={initialStatus}
            setSearchParam={(data) => {
              // navigate(`/trade-members`);
              if (searchParams.get("status") != data) {
                searchParams.delete("status");
                navigate(`/trade-members?status=${data}`);
                setSkip(1);
              }
            }}
          />
        </div>

        <div className="flex flex-wrap gap-[1%] w-full justify-start">
          {getTradeMembersLoading ? (
            <div className="flex justify-center">
              <ListLoader />
            </div>
          ) : tradeMembers?.data?.length > 0 ? (
            tradeMembers?.data?.map((v, i) => (
              <MemberCard
                key={i}
                id={v.id}
                currMember={v}
                setCurrMember={(data) => setCurrMember(data)}
                {...v}
              />
            ))
          ) : (
            <div className="w-full bg-white round md:py-6 py-3 md:px-10 px-3">
              <h1 className="text-[20px] my-4 text-center mx-auto">
                No data yet.
              </h1>
            </div>
          )}
        </div>
        {totalPages > 0 && (
          <div className="pagination_box">
            <Pagination
              currentPage={skip}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              getPageNumbers={getPageNumbers}
            />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center text-[20px] font-medium">
            Select Dates
          </div>
          <div className="my-5 gap-5 flex flex-col">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter Start Date"
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Enter End Date"
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="mt-10 flex justify-center">
            <button
              className="bg-[#0070de] text-white p-2 rounded-[5px] w-[100px] flex justify-center"
              onClick={exportCSVWithDate}
            >
              {getTradeMembersBetweenDatesLoading ? (
                <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
              ) : (
                <span>Export</span>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default TradeMembers;
