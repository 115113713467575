import React, { useState } from "react";
// import { poppins } from "../fonts";

const HowManyNeed = ({ setFieldValue, values }) => {
  return (
    <>
      <h2
        className={
          "w-full text-center my-16 text-2xl font-medium "
          //  + poppins.className
        }
      >
        How many do you need?
      </h2>

      <div className="w-full justify-center gap-5 flex min-h-[50vh]">
        <button
        type="button"
          onClick={() =>
            setFieldValue(
              "quantity",
              values?.quantity > 1 ? values?.quantity - 1 : 1
            )
          }
          className="w-16 h-16 rounded-full bg-[#EDF2FF] text-4xl hover:border-2 hover:border-black text-black"
        >
          -
        </button>

        <span className="text-6xl">{values?.quantity}</span>

        <button
        type="button"
          onClick={() => setFieldValue("quantity", values?.quantity + 1)}
          className="w-16 h-16 rounded-full bg-[#EDF2FF] text-4xl text-black hover:border-2 hover:border-black"
        >
          +
        </button>
      </div>
    </>
  );
};

export default HowManyNeed;
