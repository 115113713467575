import {
  SEND_MESSAGE_DATA,
  SEND_MESSAGE_DATA_SUCCESS,
  SEND_MESSAGE_DATA_ERROR,
  MESSAGE_DATA,
  MESSAGE_DATA_SUCCESS,
  MESSAGE_DATA_ERROR,
} from "../actions";

export const getMessagesAction = (info) => ({
  type: MESSAGE_DATA,
  payload: info,
});
export const getMessagesSuccess = (info) => ({
  type: MESSAGE_DATA_SUCCESS,
  payload: info,
});
export const getMessagesError = (error) => ({
  type: MESSAGE_DATA_ERROR,
  payload: error,
});

export const sendMessageAction = (info) => ({
  type: SEND_MESSAGE_DATA,
  payload: info,
});
export const sendMessageSuccess = (info) => ({
  type: SEND_MESSAGE_DATA_SUCCESS,
  payload: info,
});
export const sendMessageError = (error) => ({
  type: SEND_MESSAGE_DATA_ERROR,
  payload: error,
});
