// import securitySecondImage from "../../../assets/images/easySteps/Frame 33682 (1).jpg";
// import securityFirstImage from "../../../assets/images/easySteps/Frame 33682.jpg";
// import securityThirdImage from "../../../assets/images/easySteps/Frame 33683.jpg";
// import clientThirdImage from "../../../assets/images/easySteps/client3.jpg";
// import clientSeccondImage from "../../../assets/images/easySteps/client2.jpg";
// import clientFirstImage from "../../../assets/images/easySteps/client1.jpg";
import React, { useState } from "react";
import content_1 from "../../../../assets/images/home/content_1.webp";
import content_2 from "../../../../assets/images/home/content_2.webp";
import content_3 from "../../../../assets/images/home/content_3.webp";
import content_4 from "../../../../assets/images/home/content_4.webp";
import content_5 from "../../../../assets/images/home/content_5.webp";
import content_6 from "../../../../assets/images/home/content_6.webp";
import check_blue from "../../../../assets/images/home/check_blue.svg";
import { motion } from "framer-motion";

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _initialRight = { x: "40%", opacity: 0 };
const _whileInViewRight = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const _viewport = { once: true };

const data = {
  security: [
    {
      id: 1,
      image: content_1,
      title_1: "STEP 1",
      title_2: "Register your IGardu account",
      description:
        "Begin Your Journey with iGuardu: Register Your Account and Share Vital Information",
      steps: [
        "Share basic information for profile setup",
        "Specify your geographical scope or service region",
        "Upload your security license",
        "Select all relevant positions to maximize opportunities",
      ],
      reverse: false,
    },

    {
      id: 2,
      image: content_2,
      title_1: "STEP 2",
      title_2: "Update your profile to fit your profession",
      description:
        "Tailor your profile to fit your profession: Enhance your iGuardu account with relevant details",
      steps: [
        "Update basic information to reflect your professional identity",
        "Update your professional details",
        "Specify your geographical scope or service region",
        "Ensure your profile includes valid licenses",
      ],
      reverse: true,
    },

    {
      id: 3,
      image: content_3,
      title_1: "STEP 3",
      title_2: "Get job notification instantly and apply",
      description:
        "Receive real-time job alerts to stay informed about new opportunities. Our system notifies you instantly when relevant positions are available, keeping you ahead in your job search.",
      steps: [
        "Receive instant notifications",
        "Apply with ease",
        "Stay ahead in your profession",
        "Convenient application management",
      ],
      reverse: false,
    },
  ],

  clients: [
    {
      id: 4,
      image: content_5,
      title_1: "STEP 1",
      title_2: "Post your job need with brief description",
      description:
        "Share your job needs with a brief description: Find the perfect match for your requirements",
      steps: [
        "Share your job needs swiftly.",
        "Attract qualified candidates effectively",
        "Streamline hiring with a brief description",
        " Ensure precision for smooth candidate selection",
      ],
      reverse: false,
    },

    {
      id: 5,
      image: content_5,
      title_1: "STEP 2",
      title_2: "Get access to the right man for your job",
      description:
        "Efficiently Connect with Qualified Professionals Tailored to Your Job Needs, Ensuring Precision and Accuracy in Your Recruitment Process",
      steps: [
        "Connect with the perfect security swiftly",
        " Find the ideal fit for your job requirement",
        "Streamline your hiring process effectively",
        "Ensure accuracy in candidate selection.",
      ],
      reverse: true,
    },

    {
      id: 6,
      image: content_6,
      title_1: "STEP 3",
      title_2: "Get job notification instantly and apply",
      description:
        "Share Your Feedback: Tell Us About Your Experience because it:",
      steps: [
        "Guides others' decisions",
        "Drives service improvement",
        "Builds trust in our community",
        "Holds us accountable for excellence",
      ],
      reverse: false,
    },
  ],
};

const StepItem = ({ item }) => {
  return (
    <div
      className={`flex lg:items-center w-full mt-[64px] mx-auto justify-between   gap-[32px] sm:gap-[90px] ${
        item.reverse ? "flex-col-reverse" : "flex-col-reverse"
      }  ${item?.reverse ? "lg:flex-row-reverse" : "lg:flex-row"}`}
    >
      <div className="flex-1">
        <motion.h4
          initial={!item?.reverse ? _initialLeft : _initialRight}
          whileInView={!item?.reverse ? _whileInViewLeft : _whileInViewRight}
          viewport={_viewport}
          className="text-[#2065EC] text-left font-[600] leading-[26px] text-[16px] mb-[20px]"
        >
          {item.title_1}
        </motion.h4>

        <motion.h1
          initial={!item?.reverse ? _initialLeft : _initialRight}
          whileInView={!item?.reverse ? _whileInViewLeft : _whileInViewRight}
          viewport={_viewport}
          className="text-[#101828] text-left font-[600] leading-[30px] sm:leading-[38px] text-[24px] sm:text-[30px] lg:max-w-[600px]  mb-[16px]"
        >
          {item.title_2}
        </motion.h1>

        <motion.p
          initial={!item?.reverse ? _initialLeft : _initialRight}
          whileInView={!item?.reverse ? _whileInViewLeft : _whileInViewRight}
          viewport={_viewport}
          className="text-[#667085] text-left font-[400] leading-[24px] sm:leading-[28px] text-[16px] sm:text-[18px] lg:max-w-[600px]  mb-[20px]"
        >
          {item.description}
        </motion.p>

        {item?.steps?.length
          ? item?.steps.map((step, index) => {
              return (
                <motion.div
                  key={index}
                  initial={!item?.reverse ? _initialLeft : _initialRight}
                  whileInView={{
                    ...(!item?.reverse ? _whileInViewLeft : _whileInViewRight),
                    transition: {
                      duration: 0.8,
                      delay: 0.1 * index,
                    },
                  }}
                  viewport={_viewport}
                  className="flex items-center gap-[12px] mb-[16px]"
                >
                  <img
                    src={check_blue}
                    alt="check_blue"
                    className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px] "
                  />
                  <span className="text-[#667085] font-[400] leading-[24px] sm:leading-[28px] text-[16px] sm:text-[18px] ">
                    {step}
                  </span>
                </motion.div>
              );
            })
          : null}
      </div>
      <div className="flex-1">
        <motion.img
          initial={item?.reverse ? _initialLeft : _initialRight}
          whileInView={item?.reverse ? _whileInViewLeft : _whileInViewRight}
          viewport={_viewport}
          src={item?.image}
          alt="contnet_image"
          className="mx-auto lg:mx-0 w-full h-full object-cover"
        />
      </div>
    </div>
  );
};
const EasyStep = ({ hideToggle = false, activeTab, setActiveTab, header }) => {
  return (
    <>
      <div
        className={`w-full  px-[20px] pt-[20px] mt-[70px] sm:mt-[100px] mb-[50px] lg:mb-[100px]`}
      >
        <div className="max-w-[1340px] mx-auto">
          {header}
          {hideToggle ? null : (
            <>
              <motion.h4
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                  },
                }}
                viewport={_viewport}
                className="text-[#2065EC] text-center font-[600] leading-[26px] text-[16px] mx-auto mb-[12px]"
              >
                HOW IT WORKS
              </motion.h4>

              <motion.h1
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                    delay: 0.3,
                  },
                }}
                viewport={_viewport}
                className="text-[#1E293B] text-center font-[600] leading-[44px] text-[36px] mx-auto mb-[20px]"
              >
                Few Easy Steps and Done
              </motion.h1>

              <motion.p
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                    delay: 0.4,
                  },
                }}
                viewport={_viewport}
                className="text-[#374151] text-center font-[400] leading-[30px] text-[18px] max-w-[569px] mx-auto mb-[32px]"
              >
                Here are the steps to set up your igardu profile, whether you're
                an artisan looking for work or an individual seeking an
                craftsmen.
              </motion.p>

              <motion.div
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                    delay: 0.4,
                  },
                }}
                viewport={_viewport}
                className="mx-auto bg-[#F3F5F7] rounded-[30px] h-[53px] w-full max-w-[343px] justify-center flex"
              >
                <button
                  onClick={() => setActiveTab("security")}
                  className={`rounded-[30px] cursor-pointer ${
                    activeTab === "security"
                      ? "bg-[#145EEE] text-white"
                      : "text-[#90A3BF]"
                  } h-full flex items-center justify-center flex-1`}
                >
                  <span className="text-inherit">Security personnel</span>
                </button>
                <button
                  onClick={() => setActiveTab("clients")}
                  className={`rounded-[30px] cursor-pointer ${
                    activeTab === "clients"
                      ? "bg-[#145EEE] text-white"
                      : "text-[#90A3BF]"
                  }  h-full flex items-center justify-center flex-1`}
                >
                  <span className="text-inherit">Clients</span>
                </button>
              </motion.div>
            </>
          )}
          {/* 
          { */}

          {activeTab === "security"
            ? data[activeTab].map((item) => {
                return <StepItem key={item.id} item={item} />;
              })
            : null}
          {activeTab === "clients"
            ? data[activeTab].map((item) => {
                return <StepItem key={item.id} item={item} />;
              })
            : null}
        </div>
      </div>

      {/* <div className="p-14 bg-[#145DEB]">
      <div>
        <div className=" text-center text-[#A8C5FF] lg:text-base text-sm  font-medium mb-1">
          HOW IT WORKS
        </div>
        <div className=" text-center text-white lg:text-4xl text-2xl font-semibold mb-4">
          Few Easy Steps and Done
        </div>
        <div className=" text-center text-[#A8C5FF] text-lg lg:w-1/2 mb-8 mx-auto font-['Instrument Sans'] lg:text-base text-sm">
          Here are the steps to set up your igardu profile, whether you're an
          artisan looking for work or an individual seeking a craftsman.
        </div>
        <div className="flex justify-center">
          
          <div className=" p-0.5 bg-[#F3F5F7] rounded-3xl justify-center inline-flex">
            <div
              onClick={() => setActiveTab("security")}
              className={`px-6 py-3 cursor-pointer ${
                activeTab === "security" ? "bg-blue-600 text-white" : "bg-gray-100 text-slate-400"
              } rounded-3xl  `}
            >
              Security personnel
            </div>
            <div
              onClick={() => setActiveTab("clients")}
              className={`px-6 py-3 cursor-pointer ${
                activeTab === "clients" ? "bg-blue-600 text-white" : "bg-gray-100 text-slate-400 "
              } rounded-3xl flex `}
            >
              <span className="m-auto">

              Clients
              </span>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 mt-16">
          <div className=" flex flex-col order-1">
            <div className="relative flex m-auto">
              <label className="absolute -left-2 border-4 border-[#145DEB] bg-white py-2 px-4 rounded-full -top-5">
                1
              </label>
              <img
                className="w-[271.17px] h-[187.18px] m-auto rounded-[10.64px]"
                src={
                  activeTab === "security"
                    ? securityFirstImage
                    : clientFirstImage
                }
                height={212.71}
                width={212.71}
              />
            </div>
          </div>
          <div className="flex flex-col lg:order-2 order-3">
            <div className="relative flex m-auto">
              <label className="absolute -left-6 border-4 border-[#145DEB] bg-white py-2 px-4 rounded-full -top-5">
                2
              </label>
              <img
                className="w-[290.52px] h-[214.84px]  rounded-[10.64px]"
                src={
                  activeTab === "security"
                    ? securitySecondImage
                    : clientSeccondImage
                }
                height={212.71}
                width={212.71}
              />
            </div>
          </div>
          <div className="flex flex-col lg:order-3 order-5">
            <div className="relative flex m-auto">
              <label className="absolute -left-6 border-4 border-[#145DEB] bg-white py-2 px-4 rounded-full -top-5">
                3
              </label>
              <img
                className="w-[290.52px] h-[214.84px] m-auto rounded-[10.64px]"
                src={
                  activeTab === "security"
                    ? securityThirdImage
                    : clientThirdImage
                }
                height={212.71}
                width={212.71}
              />
            </div>
          </div>
          <div className=" mx-auto text-center mt-3 text-white lg:text-xl text-base font-thin w-2/3 font-['Instrument Sans'] leading-loose mt-6 lg:order-4 order-2 ">
            {activeTab === "security"
              ? "Register your IGardu account."
              : "Post your job need with brief description"}
          </div>
          <div className=" mx-auto text-center mt-3 text-white lg:text-xl text-base font-thin w-2/3 font-['Instrument Sans'] leading-loose mt-6 lg:order-5 order-4 ">
            {activeTab === "security"
              ? "Update your profile to fit your profession"
              : "Get access to the right man for your job"}
          </div>
          <div className=" mx-auto text-center mt-3 text-white lg:text-xl text-base font-thin w-2/3 font-['Instrument Sans'] leading-loose mt-6 order-6 ">
            {activeTab === "security"
              ? "Get job notification instantly and apply"
              : "Drop a review about your experience"}
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default EasyStep;

// import { useState } from "react";
// import "./easyStep.css";
// const EasyStep = () => {

//   return (
//     <div className="w-screen h-[748px] bg-blue-600">
//       <div className="max-w-[1440px] flex justify-center relative mx-auto w-full">
//         <div className="w-[887px] h-[887px] left-[-444px] top-[-443px] absolute opacity-5 bg-white rounded-full blur-[200px]" />
//         <div className="w-[967px] h-[967px] left-[956px] top-[265px] absolute opacity-5 bg-white rounded-full blur-[200px]" />

//         <div className="mx-auto mt-[60px] flex-col justify-center items-center gap-[61px] inline-flex">
//           <div className="flex-col justify-center items-center gap-[30px] flex">
//             <div className="w-[600px] h-[158px] flex-col justify-center items-center gap-[15px] inline-flex">
//               <div className="w-[188px] text-center text-indigo-300 text-[15px] font-medium font-['Instrument Sans'] capitalize leading-relaxed tracking-widest">
//                 hOW IT WORKS
//               </div>
//               <div className="w-full text-center text-white text-[35px] font-semibold font-['Neue Haas Grotesk Display Pro'] leading-[52.50px]">
//                 Few Easy Steps and Done
//               </div>
//               <div className="w-full text-center text-indigo-300 text-lg font-normal font-['Instrument Sans'] leading-[30px]">
//                 Here are the steps to set up your igardu profile, whether you're
//                 an artisan looking for work or an individual seeking an
//                 craftsmen.
//               </div>
//             </div>
//             <div className="h-14 pl-0.5 pr-0.5 py-0.5 bg-gray-100 rounded-[30px] justify-start items-start gap-[0.50px] inline-flex">
//               <div
//                 onClick={() => setActiveTab("security")}
//                 className={`self-stretch px-6 py-[13px] cursor-pointer ${
//                   activeTab === "security" ? "bg-blue-600" : "bg-gray-100"
//                 }  rounded-[30px] justify-center items-center gap-2.5 inline-flex`}
//               >
//                 <div className={`text-center ${
//                   activeTab === "security" ? "text-white" : "text-slate-400"
//                 }  text-base font-semibold font-['Instrument Sans'] leading-normal`}>
//                   Security personnel
//                 </div>
//               </div>
//               <div
//                 onClick={() => setActiveTab("clients")}
//                 className={`self-stretch px-6 py-[13px] transition-all cursor-pointer ${
//                   activeTab === "clients" ? "bg-blue-600" : "bg-gray-100"
//                 }  rounded-[30px] justify-center items-center gap-2.5 inline-flex`}
//               >
//               <div className={`text-center ${
//                   activeTab === "clients" ? "text-white" : "text-slate-400"
//                 }  text-base font-semibold font-['Instrument Sans'] leading-normal`}>
//                   Clients
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="self-stretch grow shrink basis-0 justify-center items-center gap-[85.08px] inline-flex">
//             <div className="w-[239.30px] h-[328.64px] relative">
//               <div className="h-[302.24px] left-[26.59px] top-[26.59px] absolute">
//                 <div className="w-[212.71px] h-[212.71px]  pb-[0.22px] left-0 top-0 absolute bg-gray-100 rounded-[17.02px] justify-end items-center inline-flex">
//                   <img
//                     className="w-[271.17px] h-[187.18px] rounded-[10.64px]"
//                     src={activeTab === "security" ? securityFirstImage : clientFirstImage}
//                     height={212.71}
//                     width={212.71}
//                   />
//                 </div>
//                 <div className="left-0 w-[212.17px] top-[238.24px] absolute text-center text-white text-[20.27px] font-normal font-['Instrument Sans'] leading-loose">
//                   {activeTab === "security" ? "Register your IGardu account." : "Post your job need with brief description"}
//                 </div>
//               </div>
//               <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute">
//                 <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute bg-white rounded-full border-4 border-blue-500" />
//                 <div className="left-[21.27px] top-[7.44px] absolute text-center text-slate-500 text-[25.53px] font-semibold font-['Instrument Sans'] leading-[38.29px]">
//                   1
//                 </div>
//               </div>
//             </div>
//             <div className="w-[239.30px] h-[328.64px] relative">
//               <div className="h-[302.24px] left-[26.59px] top-[26.59px] absolute">
//                 <div className="w-[212.71px] h-[212.71px]  left-0 top-0 absolute bg-gray-100 rounded-[17.02px] justify-end items-center inline-flex">
//                   <img
//                     className="w-[290.52px] h-[214.84px] rounded-[10.64px]"
//                     src={activeTab === "security" ? securitySecondImage : clientSeccondImage}
//                     height={212.71}
//                     width={212.71}
//                   />
//                 </div>
//                 <div className="left-0 w-[212.17px] top-[238.24px] absolute text-center text-white text-[20.27px] font-normal font-['Instrument Sans'] leading-loose">
//                 {activeTab === "security" ? "Update your profile to fit your profession" : "Get access to the right man for your job"}
//                 </div>
//               </div>
//               <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute">
//                 <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute bg-white rounded-full border-4 border-blue-500" />
//                 <div className="left-[19.14px] top-[7.44px] absolute text-center text-slate-500 text-[25.53px] font-semibold font-['Instrument Sans'] leading-[38.29px]">
//                   2
//                 </div>
//               </div>
//             </div>

//             <div className="w-[239.30px] h-[328.64px] relative">
//               <div className="h-[302.24px] left-[26.59px] top-[26.59px] absolute">
//                 <div className="w-[212.71px] h-[212.71px] left-0 top-0 absolute bg-gray-100 rounded-[17.02px] justify-end items-center inline-flex">
//                   <img
//                     className="w-[290.52px] h-[214.84px] rounded-[10.64px]"
//                     src={activeTab === "security" ? securityThirdImage : clientThirdImage }
//                     height={212.71}
//                     width={212.71}
//                   />
//                 </div>
//                 <div className="left-0 w-[212.17px] top-[238.24px] absolute text-center text-white text-[20.27px] font-normal font-['Instrument Sans'] leading-loose">
//                 {activeTab === "security" ? "Get job notification instantly and apply" : "Drop a review about your experience"}
//                  </div>
//               </div>
//               <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute">
//                 <div className="w-[53.18px] h-[53.18px] left-0 top-0 absolute bg-white rounded-full border-4 border-blue-500" />
//                 <div className="left-[19.14px] top-[7.44px] absolute text-center text-slate-500 text-[25.53px] font-semibold font-['Instrument Sans'] leading-[38.29px]">
//                   3
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EasyStep;
