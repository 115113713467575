import { Rating } from '@mui/material';
import moment from 'moment';
import React from 'react'
import person from "../../../../assets/images/person.png"

const ReviewCard = ({
    rating,
    title,
    description,
    first_name,
    last_name,
    created_on
}) => {
    return (
        <div className="md:col-span-1 col-span-2 rounded-xl mt-6 p-3 border border-[#CBDDFF]">
            <div className="w-full flex justify-between items-center">
                <div className="flex items-center gap-2">

                    <img src={person} width={500} height={500} alt="" className='w-10 h-10 object-contain' />

                    <div>
                        <h4 className="font-semibold">
                            {`${first_name}  ${last_name}`}
                        </h4>
                        <span className="text-xs">
                        {moment(created_on).format("DD MMMM YYYY")}
                        </span>
                    </div>
                </div>

                <Rating readOnly defaultValue={parseFloat(rating).toFixed(1)} />

            </div>

            <p className="text-xs text-gray-400 mt-5">Job type</p>
            <p className="font-semibold">{title}</p>
            <p className="text-gray-500">
              {description}
            </p>
        </div>
    )
}

export default ReviewCard