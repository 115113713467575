import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGuardDashboardAction } from "../../../redux/guard/action";
import { Backdrop, CircularProgress, Drawer } from "@mui/material";
import GuardRecentJob from "./guardRecentJob";
import GuardDashboardMyJobs from "./guardMyJobs";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Close } from "@mui/icons-material";
import moment from "moment";
import calendar from "../../../assets/images/calender.svg";
import clock from "../../../assets/images/clock.svg";
const images = [
  {
    banner_url:
      "https://t3.ftcdn.net/jpg/03/16/91/28/360_F_316912806_RCeHVmUx5LuBMi7MKYTY5arkE4I0DcpU.jpg",
    alt: "Image 1",
  },
  {
    banner_url:
      "https://static.vecteezy.com/system/resources/thumbnails/005/720/479/small/banner-abstract-background-board-for-text-and-message-design-modern-free-vector.jpg",
    alt: "Image 2",
  },
  {
    banner_url:
      "https://img.freepik.com/free-vector/stylish-glowing-digital-red-lines-banner_1017-23964.jpg",
    alt: "Image 3",
  },
];

function GuardDashboard() {
  const { token } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();
  const { getGuardDashboard } = useSelector((state) => state.GuardSection);
  const { getGuardDashboardLoading } = useSelector(
    (state) => state.GuardSection
  );

  const [dashboardData, setDashboardData] = useState();
  const [promotedJobs, setPromotedJobs] = useState([]);
  const [promotedJobDetails, setPromotedJobDetails] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchPromotedJobs = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/guard/dashboard/get-promotions`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setPromotedJobs(data.data);
    };

    fetchPromotedJobs();
  }, []);

  useEffect(() => {
    dispatch(getGuardDashboardAction());
  }, [dispatch]);

  useEffect(() => {
    if (getGuardDashboard?.success) {
      setDashboardData(getGuardDashboard?.data);
    }
  }, [getGuardDashboard]);

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        className="dashboard"
      >
        <div className="bg-white rounded-xl w-full p-10">
          <div className="flex items-center justify-end w-full">
            <div
              onClick={() => {
                setOpen(false);
                setPromotedJobDetails(null);
              }}
              className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
            >
              <Close />
            </div>
          </div>

          <div className="flex pl-14 items-center gap-2 mt-6 text-[12px]">
            Posted by {promotedJobDetails?.posted_by} on{" "}
            <span className="text-primary">
              {moment(promotedJobDetails?.date).format("DD MMMM YYYY")}
            </span>
          </div>

          <div className="border-b border-[#E5E7EB] pl-14 py-10">
            <h2 className="text-sm text-gray-400">Job description</h2>
            <p className="my-3 text-[16px]">
              {promotedJobDetails?.description}
            </p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              I am looking for
            </h2>

            <div className="flex mt-6 mb-6 gap-6">
              {promotedJobDetails?.lookingFor?.map((item, index) => (
                <div
                  className="rounded-[50px] border border-[#366CFE]  py-[10px] px-[15px] text-[#366CFE] text-xs bg-[#EDF2FF]"
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              Location
            </h2>
            <p className="my-3 text-sm text-secondary">
              {promotedJobDetails?.address}
            </p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Start date / time
              </h2>
              {promotedJobDetails?.start_time?.map((item, index) => (
                <div className="flex my-6" key={index}>
                  <div>
                    <span>Date</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[30px] ml-[5px]">
                        {moment(item.date).format("DD/MM/YYYY")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={calendar}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div className="mr-6 h-[50px] w-[40px] border-b border-[#89909D]"></div>
                  <div>
                    <span>From time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.fromTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={clock}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div>
                    <span>To time</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[40px] ml-[5px]">
                        {moment(item.toTime).format("hh:mm a")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        className="w-10 h-10"
                        src={clock}
                        alt="img1"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="w-1/3">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Budget
              </h2>
              <p className="text-gray-400 pt-4">
                £{promotedJobDetails?.budget} x hr
              </p>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="m-10 mb-0 mx-10">
        {promotedJobs.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            modules={[Pagination]}
            slidesPerView={1}
            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
          >
            {promotedJobs.map((item, index) => (
              <SwiperSlide key={index}>
                <button
                  onClick={() => {
                    setOpen(true);
                    setPromotedJobDetails(item.job_details[0]);
                  }}
                  style={{ width: "100%" }}
                >
                  <img
                    src={item.banner_url}
                    alt={"job banner"}
                    style={{
                      width: "100%",
                      height: "500px",
                      maxHeight: "300px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      paddingInline: "5px",
                    }}
                  />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="p-10 bg-[#F1F2F6] w-full">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <GuardDashboardMyJobs data={dashboardData?.myJobsData} />
          <GuardRecentJob data={dashboardData?.mostRecentJobs} />
        </div>
      </div>
      {getGuardDashboardLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getGuardDashboardLoading}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default GuardDashboard;
