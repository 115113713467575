import { Field } from "formik";
import React from "react";
import Select from "react-select";

function ReactSelect(props) {
  const { name, errorName, options, ...rest } = props;
  return (
    <Field name={name}>
      {({ form, field }) => {
        const { setFieldValue } = form;
        const { value } = field;
        return (
          <>
            <Select
              id={name}
              {...field}
              {...rest}
              isMulti
              styles={customStyles}
              selected={value}
              options={options}
              onChange={(val) => setFieldValue(name, val)}
            />

            {/* <DateView
                id={name}
                {...field}
                {...rest}
                dateFormat="MM / dd / yyyy"
                selected={value}
                onChange={(val) => setFieldValue(name, val)}
                /> */}
          </>
        );
      }}
    </Field>
  );
}

export default ReactSelect;

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "auto",
    background: "transparent",
    "&:hover": {
      boxShadow: "none",
    },
    borderBottom: "2px solid #D8D8D8",
  }),
  menu: () => ({
    backgroundColor: "white",
    boxShadow: "1px 2px 6px #888888", // should be changed as material-ui
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 2,
  }),
};
