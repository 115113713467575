import React, { useState } from "react";
import {
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getSubAdminsAction } from "../../../../redux/admin/action";
import toast from "react-hot-toast";
import { Field, Form, Formik } from "formik";
import { Checkbox, FormControlLabel } from "@mui/material";
import person from "../../../../assets/images/person.png";
import UpdateSubAdminPassword from "./UpdateSubAdminPassword";

const SubAdminDetails = ({ currMember }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.Authsection);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(currMember?.status);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const initialValues = {
    accessToUsers: currMember?.permission?.client || false,
    accessToJobs: currMember?.permission?.job || false,
    accessToTradeMembers: currMember?.permission?.guard || false,
    accessToActivity: currMember?.permission?.activity || false,
    accessToReviews: currMember?.permission?.review || false,
  };

  const [statusStyles, setStatusStyles] = useState({
    height: "40px",
    width: "100%",
    borderRadius: "9999px",
    color: "#067647",
    backgroundColor: "#ECFDF3",
    border: "1px solid #ABEFC6",
  });

  const handleSelect = async (value) => {
    setOpenModal(true); // Open modal when status is changed
    setSelectedStatus(value); // Save the selected status
  };

  const confirmStatusChange = async () => {
    setStatus(selectedStatus); // Apply the status change
    setOpenModal(false); // Close the modal

    const payload = { status: selectedStatus };
    const result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/admin/subadmin/${currMember?.id}/update`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await result.json();
    if (data.success) {
      toast.success(data.message);
      dispatch(getSubAdminsAction());
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);

    const payload = {
      client: values.accessToUsers ? values.accessToUsers : false,
      job: values.accessToJobs ? values.accessToJobs : false,
      guard: values.accessToTradeMembers ? values.accessToTradeMembers : false,
      activity: values.accessToActivity ? values.accessToActivity : false,
      review: values.accessToReviews ? values.accessToReviews : false,
    };

    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/admin/subadmin/${currMember?.id}/update-permissions`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await result.json();

    if (data && data.success) {
      toast.success(data.message);
      dispatch(getSubAdminsAction());
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const statusTrn = {
    active: {
      button: "Activate",
      title: "Activate account",
      description: `Are you sure you want to update this user's Account status to ${status} ?`,
      bg: "bg-[#3F88251A]",
      color: "#3F8825",
      bt: "bg-[#3F8825] text-white",
    },
    suspended: {
      button: "Suspend",
      title: "Suspend account",
      description: `Are you sure you want to update this user's Account status to ${status} ?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
  };

  return (
    <>
      <div className="my-5 flex items-center justify-between">
        <div>
          <img
            width={500}
            height={500}
            alt=""
            src={
              currMember?.profile_picture?.url
                ? currMember?.profile_picture?.url
                : person
            }
            className="w-8 h-8 rounded-full"
          />
          <h2 className="md:text-lg text-sm text-black font-semibold">
            {currMember?.first_name} {currMember?.last_name}
          </h2>
          <p className="font-normal text-[#366CFE] md:text-xs text-[10px]">
            {currMember?.trading_name}
          </p>
          <p className="text-gray-400 md:text-xs text-[10px]">
            {moment(currMember?.meta?.reated_on).format("DD MMM, YYYY")}
          </p>
        </div>
        <div>
          <Select
            sx={statusStyles}
            value={status}
            className="md:text-base text-xs"
            onChange={(e) => {
              handleSelect(e.target.value);
            }}
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"suspended"}>Suspend</MenuItem>
          </Select>
        </div>
      </div>

      <div className="w-full py-10  border-t border-gray-200">
        <p className="text-lg underline font-medium mb-8">Personal details </p>
        <div className="flex gap-10 flex-wrap">
          <div className="mb-5">
            <p className="text-gray-400 text-sm font-normal">Email address</p>
            <div className="font-semibold">{currMember?.email}</div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">Phone number </p>
            <div className="font-semibold">{currMember?.phone}</div>
          </div>
        </div>
      </div>

      <UpdateSubAdminPassword currMember={currMember} />

      {/* <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
    
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn[status]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              No
            </button>
            <button
              onClick={confirmStatusChange}
              className={`${statusTrn[status]?.bt} rounded-[10px]  w-[120px]`}
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal> */}

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change the status to{" "}
            <strong>{selectedStatus}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            No
          </Button>
          <Button onClick={confirmStatusChange} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div className="w-full py-10  border-t border-gray-200">
        <p className="text-lg underline font-medium mb-8">Permissions</p>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {() => (
            <Form>
              <div className="flex flex-col col-span-2">
                <div className="flex gap-4 items-center">
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="accessToUsers"
                        as={Checkbox}
                      />
                    }
                    label="Users"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="accessToJobs"
                        as={Checkbox}
                      />
                    }
                    label="Jobs"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="accessToTradeMembers"
                        as={Checkbox}
                      />
                    }
                    label="Trade Members"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="accessToActivity"
                        as={Checkbox}
                      />
                    }
                    label="Login Activity"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="accessToReviews"
                        as={Checkbox}
                      />
                    }
                    label="Reviews"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "black",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2">
                <button
                  type="submit"
                  className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                >
                  {loading ? (
                    <img
                      src={"/loading.svg"}
                      alt="loading"
                      className="w-6 h-6"
                    />
                  ) : (
                    <p>Update</p>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SubAdminDetails;
