import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
        },
    }
};

function createChartData(dataSection) {
    const parsedData = {};
  
    dataSection?.forEach(entry => {
      const monthAbbr = moment(entry.month).format('M')
      parsedData[monthAbbr] = parsedData[monthAbbr] || {};
      parsedData[monthAbbr].total_jobs = entry.total_jobs;
      parsedData[monthAbbr].total_applications = entry.total_applications;
      parsedData[monthAbbr].total_hired = entry.total_hired;
    });
  
    const labels =['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const datasets = [
      {
        label: 'Total Jobs',
        data: labels.map((month, i) => parsedData[i + 1]?.total_jobs || 0 ), // Use optional chaining for safety
        borderColor: '#366CFE',
        backgroundColor: '#2756d73e',
        fill: true
      },
      {
        label: 'Applications',
        data: labels.map((month, i) => parsedData[i + 1]?.total_applications || 0),
        borderColor: '#E2DD17',
        backgroundColor: '#e2df174a',
        fill: true
      },
      {
        label: 'Hired',
        data: labels.map((month, i) => parsedData[i + 1]?.total_hired || 0),
        borderColor: '#06BC0D',
        backgroundColor: '#06bc0c42',
        fill: true
      }
    ];
  
    return { labels, datasets };
  }

export default function ChartComponent({ jobChart, loading }) {
    const data = createChartData(jobChart)
    return (
      <Line options={options} data={data} />
    );
}
