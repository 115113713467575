import React from "react";
import Header from "./header";

import Navbar from "../../pages/main/homepage/components/navbar";

import Footer from "../../pages/main/homepage/components/footer";

import "./styles.css";

const TermsAndConditions = ({ toggle }) => {
  // const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        className={`w-full bg-gradient-to-r ${
          toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"
        }  px-[20px] pt-[20px] relative overflow-x-hidden `}
      >
        {/* <div
          className="absolute top-0 left-0 h-full w-full z-[1]"
          style={{
            background:
              "url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center",
          }}
        /> */}
        <Navbar transparent />

        <div className="max-w-[1340px] mx-auto relative z-[10] mt-[50px]">
          {/* <Nav toggle={toggle} /> */}
          <Header />
        </div>
      </div>
      <div className={`w-full  px-[20px] pt-[20px] `}>
        <div className="max-w-[1340px] mx-auto py-[50px] trms_condition">
          <h1>Privacy Policy</h1>
          <p>
            Welcome to IGardu! We take your privacy seriously and are committed
            to protecting your personal information. This Privacy Policy
            explains how we collect, use, disclose, and store your information
            when you use our website and its associated services (collectively,
            the "Platform").
          </p>

          <h2>Information We Collect</h2>

          <p>
            We collect several types of information from and about users of our
            platform:
          </p>

          <h3>Information You Provide Directly</h3>

          <ul>
            <li>
              Clients: When you create a client account, you may provide
              information such as your name, company name, email address, phone
              number, and billing information. You may also submit job
              descriptions and other details related to your security personnel
              needs.
            </li>
            <li>
              Security Personnel: When you create a security personnel account,
              you may provide information such as your name, contact
              information, resume, work experience, skills, certifications, and
              availability.
            </li>
          </ul>

          <h3>Information Collected Automatically</h3>

          <ul>
            <li>
              Log Data: We collect information that your browser automatically
              sends whenever you access the platform. This information may
              include your IP address, browser type, operating system, the pages
              you visit on our platform, the time and date of your visit, and
              other browsing activity information.
            </li>
            <li>
              Device Information: We may collect information about the device
              you use to access the platform, such as the device type, model,
              operating system version, and unique device identifiers.
            </li>
          </ul>

          <h3>Information from Third Parties</h3>

          <ul>
            <li>
              We may collect information about you from third-party sources,
              such as social media platforms or background check providers, with
              your consent.
            </li>
          </ul>

          <h2>How We Use Your Information</h2>

          <p>
            We use the information we collect for various purposes, including:
          </p>

          <ul>
            <li>To provide and operate the platform</li>
            <li>
              To process your account registration and manage your account
            </li>
            <li>
              To connect Clients with Security Personnel and facilitate job
              opportunities
            </li>
            <li>
              To send you important information about the platform, such as
              changes to our terms or policies
            </li>
            <li>To personalize your experience on the platform</li>
            <li>To improve the platform and develop new features</li>
            <li>To comply with legal and regulatory requirements</li>
          </ul>

          <h2>Sharing Your Information</h2>

          <p>
            We may share your information with third parties in the following
            circumstances:
          </p>

          <ul>
            <li>
              With Service Providers: We may share your information with
              third-party service providers who help us operate the platform,
              such as background check providers, payment processors, and email
              marketing providers.
            </li>
            <li>
              With Clients: If you are a Security Personnel, we may share your
              information with Clients who are interested in your
              qualifications.
            </li>
            <li>
              With Legal Authorities: We may disclose your information to law
              enforcement or other government officials if required by law or if
              we believe it is necessary to protect the rights, property, or
              safety of ourselves or others.
            </li>
          </ul>

          <h2>Your Choices</h2>

          <p>You have choices regarding your information:</p>

          <ul>
            <li>
              Access and Update Your Information: You can access and update your
              information in your account settings.
            </li>
            <li>
              Control Your Communications: You can unsubscribe from marketing
              emails by following the instructions in the emails you receive.
            </li>
          </ul>

          <h2>Data Security</h2>

          <p>
            We take steps to protect your information from unauthorized access,
            disclosure, alteration, or destruction. However, no internet
            transmission or electronic storage is ever completely secure. We
            cannot guarantee the security of your information.
          </p>

          <h2>Data Retention</h2>

          <p>
            We will retain your information for as long as your account is
            active or as needed to provide you with services. We may also retain
            your information for as long as necessary to comply with our legal
            obligations, resolve disputes, and enforce our agreements.
          </p>

          <h2>Children's Privacy</h2>

          <p>
            Our platform is not intended for children under the age of 18. We do
            not knowingly collect personal information from children under 18.
          </p>

          <h2>International Transfers</h2>

          <p>
            Your information may be transferred to, stored, and processed in
            countries other than your own, where data protection laws may be
            different from those in your country. By using the platform, you
            consent to the transfer of your information to these countries.
          </p>

          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on the
            platform. Your continued use of the platform after the posting of
            any revised Privacy Policy constitutes your acceptance of the
            revised Privacy Policy.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a style={{ color: "blue" }} href="malito:info@igardu.com">
              info@igardu.com
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
