export const frontLine = [
  "Cash and valuables in transit",
  "Close protection",
  "Event/Door security",
  "Key holding",
  "Premises protection",
  "Public space surveillance",
  "Security management",
  "Vehicle immobilization",
];

export const nonFrontLine = [
  "Director of Security",
  "Key Holding Services Manager",
  "Partner in Security Firm",
  "Security Administrator",
  "Security Consultant",
  "Security Manager",
  "Security Operations Manager",
  "Security Staffing Coordinator",
  "Security Supervisor",
];

export const FrontlineSubRoles = {
  "Cash and valuables in transit": [
    "Armored Car Guard",
    "Cash Transport Guard",
    "Valuable Goods Transporter",
  ],
  "Close protection": [
    "Bodyguard",
    "Executive Protection",
    "Personal Security",
    "VIP Protection",
  ],
  "Event/Door security": [
    "Club Bouncer",
    "Concert Security",
    "Event Door Staff",
    "Festival Security Staff",
    "Pub Security",
  ],
  "Key holding": ["Alarm Response ", "Key Holder"],
  "Premises protection": [
    "Apartment Building Security",
    "Church Security",
    "Construction Site Watch",
    "Hospital Security",
    "Mall Security",
    "Office Building Security",
    "School Security",
    "Shop Security",
    "Warehouse Watch",
  ],
  "Public space surveillance": [
    "CCTV Operator",
    "Monitoring Center Operator",
    "Surveillance Officer",
  ],
  "Security management": [
    "Director of Security",
    "Key Holding Services Manager",
    "Partner in Security Firm",
    "Security Administrator",
    "Security Consultant",
    "Security Manager",
    "PSecurity Operations Manager",
    "Security Staffing Coordinator",
    "Security Supervisor",
  ],
  "Vehicle immobilization": [
    "Parking Enforcement",
    "Vehicle Clamper",
    "Vehicle Immobiliser",
  ],
};
