import React from "react";

import { CalendarIcon, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field } from "formik";
import DatePicker from "../../../../components/formikComponent/datepicker";

const ProgressFour = ({ values, setFieldValue }) => {
  function limitInputLength(inputElement) {
    // console.log({ inputElement });
    if (
      typeof inputElement?.target?.value === "string" &&
      inputElement?.target?.value?.length > 16
    ) {
      inputElement.target.value = inputElement.target.value.substring(0, 16);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="w-full flex justify-center pt-16 pb-[200px]">
        <div className="w-full md:w-1/2">
          <h2 className="nekst text-4xl text-center text-secondary mt-10 mb-3">
            Add a security license to our wallet
          </h2>
          <p className="text-gray-400 text-center">
            Integrating a Robust Security License into Your Wallet for Added{" "}
            <br /> Protection and Peace of Mind
          </p>

          <div className="w-full">
            <div className="px-10 mb-10 text-sm font-semibold text-black py-4 mt-20 border-b border-gray-400">
              License details
            </div>

            <div className="flex px-10 items-start justify-between w-full">
              <div className="w-5/12">
                <p className="text-sm text-gray-500" htmlFor="License_Type">
                  License Type
                </p>

                <Field
                  as="select"
                  name="licenceType"
                  className="w-full border-b border-b-gray-400 py-1 outline-none"
                >
                  <option value="front_line">Front line</option>
                  <option value="non_front_line">Non-front line</option>
                </Field>
              </div>
              <div className="w-5/12">
                <p className="text-sm text-gray-500" htmlFor="License_Type">
                  SIA License Number
                </p>
                <Field
                  name="siaNumber"
                  type="number"
                  className="w-full border-b border-b-gray-400 py-1 outline-none"
                  id="standard-basic"
                  placeholder="SIA License Number"
                  onInput={limitInputLength}
                />
                <p className="text-xs text-gray-400">
                  SIA License Number must have 16 digits
                </p>
              </div>
            </div>

            <p className="text-sm text-black my-5 px-10 font-semibold">
              License Expiry date
            </p>

            <div className="flex px-10 items-start justify-between w-full">
              <div className="w-5/12">
                <label className="text-gray-500 text-sm">From</label>
                <div className="relative w-full">
                  <label className=" absolute right-3 top-[5px] z-20">
                    <CalendarIcon />
                  </label>

                  <DatePicker
                    placeholder="MM / DD / YYYY"
                    name="dateFrom"
                    classname="w-full px-4 border-b-gray-400 border-b py-1 outline-none z-10"
                    //   setdate={new Date()}
                  />
                </div>
              </div>

              <div className="w-5/12">
                <label className="text-gray-500 text-sm">To</label>
                <div className="relative w-full">
                  <label className=" absolute right-3 top-[5px] z-20">
                    <CalendarIcon />
                  </label>

                  <DatePicker
                    placeholder="MM / DD / YYYY"
                    name="dateTo"
                    classname="w-full px-4 border-b-gray-400 border-b py-1 outline-none"
                    setdate={values?.dateFrom}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default ProgressFour;
