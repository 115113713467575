import React from "react";

import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from "framer-motion";
import toggle_left from "../../../assets/images/how-it-works/toggle_left.webp";
import toggle_right from "../../../assets/images/how-it-works/toggle_right.webp";
import { Link } from "react-router-dom";

const Toggle = ({ handleToggle, toggle }) => {
  return (
    <div className="relative inline-block w-[64px] h-[32px]  rounded-full">
      <input
        type="checkbox"
        id="toggle"
        className="opacity-0 peer absolute w-full h-full left-0 z-10 cursor-pointer"
        onClick={() => handleToggle()}
        checked={toggle ? true : false}
      />
      <div className="w-full h-full bg-gray-200/30 rounded-full peer-checked:bg-gray-200/30 transition duration-300"></div>
      <span className="transition-all duration-300 absolute top-[2px] left-[2px] bg-white rounded-full w-[28px] h-[28px] peer-checked:left-[34px] "></span>
    </div>
  );
};

function Header({ toggle, handleToggle, login, loginRole }) {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const aa = useTransform(scrollYProgress, [0, 1], [0.8, 1.3]);

  const shiftX = useSpring(aa, {
    stiffness: 100,
    damping: 10,
    mass: 0.1,
  });

  const [ev, setev] = React.useState(0);
  useMotionValueEvent(shiftX, "change", (latest) => {
    setev(latest);
  });

  console.log("ev", ev);

  return (
    <div className="pt-[40px] sm:pt-[50px] xl:pt-[100px]">
      <div className="flex items-center gap-[10px] sm:gap-[20px] mb-[40px] sm:mb-[20px] justify-center">
        <span
          className={` text-right text-[14px] sm:text-[16px] text-[#dbe1e8] ${
            !toggle ? "" : "opacity-[0.6]"
          }`}
        >
          I'm looking for security jobs
        </span>
        <Toggle handleToggle={handleToggle} toggle={toggle} />
        <span
          className={` text-left text-[14px] sm:text-[16px] text-[#dbe1e8] ${
            !toggle ? "opacity-[0.6]" : ""
          }`}
        >
          I'm looking to hire security
        </span>
      </div>

      <motion.div
        initial={{ y: "20%", opacity: 0, scale: 0.8 }}
        viewport={{ once: true }}
        whileInView={{
          y: 1,
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5 },
        }}
      >
        <h1 className="text-[#dbe1e8] font-bold text-[clamp(52px,calc(80/1340*100vw),80px)] leading-[0.95] text-center max-w-[850px] mx-auto">
          {toggle
            ? "Unlock Your Next Security Opportunity"
            : "Unlock Your Next Security Opportunity"}
        </h1>

        <p className="text-[#dbe1e8] text-[clamp(16px,calc(18/1340*100vw),18px)]  text-center max-w-[705px] mx-auto py-[30px]">
          {toggle
            ? "Revolutionizing the security job search experience with personalized job suggestions and intelligent networking recommendations."
            : "Revolutionizing the security job search experience with personalized job suggestions and intelligent networking recommendations."}
        </p>

        <div className="flex items-center gap-[16px] md:gap-[25px] justify-center ">
          {toggle ? (
            <>
              {null}
              {/* <button className=" transition-all duration-300  bg-white hover:bg-black hover:text-white py-[10px] md:py-[12px] px-[12px] md:px-[28px] border-white border hover:border-black  rounded-[36px] text-[14px] md:text-[16px]">
                Request a demo
              </button> */}
            </>
          ) : (
            <>
              <a
                href="/post-a-job"
                className=" transition-all duration-300 text-white  bg-[#145DEB] hover:bg-black  py-[10px] md:py-[12px] px-[12px] md:px-[28px] border-[#145DEB] border hover:border-black rounded-[36px] text-[14px] md:text-[16px]"
              >
                Join for Free
              </a>

              {login ? (
                <Link
                  to={
                    loginRole !== "admin"
                      ? `/${loginRole}/dashboard`
                      : "/dashboard"
                  }
                >
                  <button className="transition-all duration-300   text-white  hover:bg-white hover:text-black py-[10px] md:py-[12px] px-[12px] md:px-[28px] border-white border rounded-[36px] text-[14px] md:text-[16px]">
                    Dashboard
                  </button>
                </Link>
              ) : (
                <Link to="/sign-in">
                  <button className="transition-all duration-300   text-white  hover:bg-white hover:text-black py-[10px] md:py-[12px] px-[12px] md:px-[28px] border-white border rounded-[36px] text-[14px] md:text-[16px]">
                    Login
                  </button>
                </Link>
              )}
            </>
          )}
        </div>
      </motion.div>

      <motion.div ref={ref}>
        <motion.div
          style={{ scaleX: ev > 1 ? 1 : ev, scaleY: ev > 1 ? 1 : ev }}
          className="w-full max-w-[clamp(150px,calc(1260/1500*100vw),1200px)]  mx-auto  mt-[20px]"
          //
        >
          <motion.img
            src={!toggle ? toggle_left : toggle_right}
            id={toggle ? "toggle_left_img" : "toggle_right_img"}
            alt="dashboard_demo_img"
            className="h-full w-full object-contain rounded-tr-2xl rounded-tl-2xl "
          />

          {/* <video
            autoPlay={true}
            id="433434"
            preload="auto"
            loop
            className=" lg:h-[100%] mx-auto"
            playsInline
            muted>
            <source
              src={toggle ? toggle_left : toggle_right}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> */}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Header;
