import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLicenceAction,
  putLicenceAction,
} from "../../../../redux/guard/action";
import * as yup from "yup";
import licenseTypes from "../component/licenseData";
import toast from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import Select from "react-select";
import ReactSelect from "../../../formikComponent/reactSelect";
import DatePicker from "../../../formikComponent/datepicker";

function AddTrade() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getLicence } = useSelector((state) => state.GuardSection);
  const { getLicenceLoading } = useSelector((state) => state.GuardSection);
  const { putLicence } = useSelector((state) => state.GuardSection);
  const { putLicenceLoading } = useSelector((state) => state.GuardSection);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [licenseId, setLicenseId] = useState("");
  const [licenseFormData, setLicenseFormData] = useState();
  const [sector, setSector] = useState();
  const [trades, setTrades] = useState();
  const [data, setData] = useState([]);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    dispatch(getLicenceAction());
  }, [dispatch]);

  useEffect(() => {
    if (putLicence?.success === true && initial === true) {
      handleNext();
      setLicenseId(id);
      setInitial(false);
      dispatch(getLicenceAction());
    } else if (putLicence?.status === false) {
      setInitial(false);
    }
  }, [dispatch, initial, putLicence]);

  useEffect(() => {
    if (showConfirmPage) {
      if (putLicence?.success === true) {
        notify(putLicence.message);
      } else if (putLicence?.status === false) {
        notifyfail(putLicence.message);
      }
    }
  }, [
    putLicence.message,
    putLicence?.status,
    putLicence?.success,
    showConfirmPage,
  ]);

  useEffect(() => {
    if (getLicence?.success === true && initial === true) {
      setData(getLicence.data.find((item) => Number(item.id) === Number(id)));
      selectLicenseType();
      setInitial(false);
      setShowConfirmPage(false);
    }
  }, [getLicence]);

  useEffect(() => {
    selectLicenseType(data?.role);
  }, [data]);

  useEffect(() => {
    selectSector(data?.sector, sector);
  }, [data, sector]);

  const handleNext = () => {
    setShowConfirmPage(true);
  };

  const initialvalue = {
    operatedAs: "front_line" || "",
    licenseSector: data?.sector || "",
    siaNumber: data?.sia_number || "",
    expiryDateFrom: moment(data?.expiry_date_from).format("MM/DD/YYYY") || "",
    expiryDateTo: moment(data?.expiry_date_to).format("MM/DD/YYYY") || "",
    trades:
      data?.trades?.map((tab) => ({
        label: tab,
        value: tab,
      })) || [],
  };

  const validationSchema = yup.object().shape({
    operatedAs: yup.string(),
    licenseSector: yup.string().required(),
    siaNumber: yup.string().required(),
    expiryDateFrom: yup.string().required(),
    expiryDateTo: yup.string(),
    // trades: ,
  });

  const onSubmit = (values) => {
    const payload = values.trades.map((item) => item.label);
    setInitial(true);
    dispatch(
      putLicenceAction({
        data: { trades: payload, id },
      })
    );
  };

  const selectLicenseType = async () => {
    const value = data?.role;
    setLicenseFormData({ ...licenseFormData, licenseType: value });
    const sectors =
      licenseTypes.find((i) => {
        return i.value === value;
      })?.sectors || [];
    setSector(sectors);
    selectSector(data?.licenseSector, sectors);
  };

  const selectSector = (value, sectors) => {
    setTrades([]);
    setLicenseFormData({ ...licenseFormData, sector: value });
    console.log(sectors, "trades");
    console.log(value, "value");

    const name = sectors?.find((i) => {
      return i.name === value;
    });

    // console.log(name, "===========");
    // return;

    const trade = sectors?.find((i) => {
      return i.name === value;
    })?.trades;
    console.log(trade, "trade");
    const data = trade?.map((tab) => ({
      label: tab,
      value: tab,
    }));

    setTrades(data);
  };
  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  return (
    <>
      {" "}
      {showConfirmPage && licenseId === id ? (
        <div className="md:p-10 p-4 bg-[#F1F2F6] w-full flex gap-7">
          <div className="w-[100%] ">
            <div className="md:min-h-[586px] md:mt-6 mt-2 bg-white rounded-2xl md:p-6 p-2 md:pt-24 pt-6 md:px-20 px-6">
              <div
                style={{ textAlign: "center", margin: "8% auto" }}
                className="md:w-1/3 w-full"
              >
                Your license has not been verified yet, your trades will appear
                once we have verfied your license
              </div>

              <div className="flex md:justify-center justify-between">
                <button
                  onClick={() => navigate("/guard/job-search")}
                  className="text-center md:w-fit w-full  px-3 py-2 text-sm"
                >
                  Find jobs
                </button>
                <button
                  className="bg-primary md:w-fit w-full px-3 py-2 rounded-[20px] text-sm text-white justify-center flex items-center"
                  onClick={() => navigate("/guard/dashboard")}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:p-10 p-3 bg-[#F1F2F6] w-full flex gap-7">
          <div className="w-[100%] ">
            <div className=" mt-6 bg-white rounded-2xl p-6 md:pt-24 pt-6 md:pl-20">
              <div
                className="text-[#242424] font-semibold	text-2xl mb-20"
                // style={{ fontFamily: poppins.style.fontFamily }}
              >
                <span>My trades / Add trade</span>
              </div>
              <div className="border-b-2 border-[#145EEE] pb-2.5 w-40">
                SIA license N.
              </div>
              <Formik
                initialValues={initialvalue}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {(formik) => (
                  <Form>
                    <div className="mt-10">
                      <div className="grid grid-cols-2 gap-10 w-full h-full flex-col poppins">
                        {/* <div className="flex   gurad-profile-form-container w-full py-[30px] h-auto justify-between"> */}
                        <div className="flex flex-col md:col-span-1 col-span-2 ">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999] text-xs pl-1"
                          >
                            License type
                          </label>
                          <input
                            type="select"
                            readOnly
                            id="first-name"
                            value={formik?.values?.operatedAs}
                            // onChange={selectLicenseType}
                            className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
                          ></input>
                        </div>
                        <div className="flex flex-col md:col-span-1 col-span-2 ">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            SIA License number
                          </label>
                          <input
                            type="text"
                            readOnly
                            id="first-name"
                            value={formik?.values?.siaNumber}
                            className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                          ></input>
                        </div>
                        {/* </div> */}

                        {/* <div className="flex   gurad-profile-form-container w-full py-[30px] h-auto justify-between"> */}
                        <div className="flex flex-col md:col-span-1 col-span-2 ">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999] text-xs pl-1"
                          >
                            Expire date from
                          </label>
                          <DatePicker
                            readOnly
                            placeholder="MM / DD / YYYY"
                            name="expiryDateFrom"
                            classname="w-full h-5 py-5 px-2 text-xs border-b-[#D8D8D8] border-b-2 outline-none"
                          />
                        </div>
                        <div className="flex flex-col md:col-span-1 col-span-2">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            Expire date to
                          </label>
                          <DatePicker
                            readOnly
                            placeholder="MM / DD / YYYY"
                            name="expiryDateTo"
                            classname="w-full h-5 py-5 px-2 text-xs border-b-[#D8D8D8] border-b-2 outline-none"
                          />

                          {/* <input type="date" id="start" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" /> */}
                        </div>
                        {/* </div> */}

                        {/* <div className="flex   gurad-profile-form-container w-full py-[30px] h-auto justify-between"> */}
                        <div className="flex flex-col md:col-span-1 col-span-2 relative">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            License sector
                          </label>
                          <input
                            value={formik?.values?.licenseSector}
                            type="text"
                            readOnly
                            id="first-name"
                            className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none mt-1"
                          ></input>
                          <div className="absolute flex inset-y-0 right-0 h-full items-center">
                            {/* <Image src={inputIcon} width={15} height={15} alt="input"></Image> */}
                          </div>
                        </div>

                        <div className="flex flex-col md:col-span-1 col-span-2  pr-2 ">
                          <label
                            htmlFor="operated-as"
                            className="text-[#999999] text-xs pl-1"
                          >
                            Select all relevant trades
                          </label>

                          <ReactSelect name="trades" options={trades} />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="flex md:justify-end justify-between mt-10">
                      <button
                        type="button"
                        onClick={() => navigate("/guard/my-trades")}
                        className=" text-center md:w-fit w-full  px-3 py-2 text-sm"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary md:w-fit px-4 w-full px-3 py-2 rounded-[20px] text-sm text-white justify-center flex items-center"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
      {getLicenceLoading || putLicenceLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLicenceLoading || putLicenceLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default AddTrade;

const customStyles = {
  control: () => ({
    display: "flex",
    alignItems: "center",
    border: 0,
    height: "auto",
    background: "transparent",
    "&:hover": {
      boxShadow: "none",
    },
    borderBottom: "2px solid #D8D8D8",
  }),
  menu: () => ({
    backgroundColor: "white",
    boxShadow: "1px 2px 6px #888888", // should be changed as material-ui
    position: "absolute",
    left: 0,
    top: `calc(100% + 1px)`,
    width: "100%",
    zIndex: 2,
  }),
};
