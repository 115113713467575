import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Filters = ({ filter, initialStatus, setSearchParam }) => {
  const [status, setStatus] = useState("all");
  const [account, setAccount] = useState("all");
  const [license, setLicense] = useState("all");

  useEffect(() => {
    if (initialStatus) {
      setStatus(initialStatus);
    }
  }, [initialStatus]);

  const onChange = (action, value) => {
    filter(action, value);
    if (action === "status") {
      setStatus(value);
      setSearchParam(value);
      setAccount("all");
      setLicense("all");
    }
    if (action === "account_status") {
      setAccount(value);
      setStatus("all");
      setLicense("all");
    }
    if (action === "expiry") {
      setLicense(value);
      setStatus("all");
      setAccount("all");
    }
  };
  const selectStyles = {
    border: "1px solid #E5E7EB",
    outline: "none",
    borderRadius: "6px",
    background: "#FFF",
    width: "100%",
    py: "2px",
    height: "40px",
    boxShadow:
      "0px 8px 16px 0px rgba(3, 7, 18, 0.08), 0px 0px 0px 1px rgba(3, 7, 18, 0.08)",
  };

  return (
    <>
      <div className="w-full rounded-xl  py-10 gap-5 flex flex-wrap md:justify-start justify-between items-end">
        <div className="sm:w-1/4 w-full">
          <h3 className="mb-2 font-semibold">Status</h3>
          <Select
            sx={selectStyles}
            value={status}
            onChange={(e) => onChange("status", e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"unverified"}>Unverified</MenuItem>
            <MenuItem value={"verified"}>Verified</MenuItem>
            <MenuItem value={"recommended"}>Recommended</MenuItem>
            <MenuItem value={"suspended"}>Suspended</MenuItem>
          </Select>
        </div>

        <div className="sm:w-1/4 w-full">
          <h3 className="mb-2 font-semibold">Account</h3>
          <Select
            sx={selectStyles}
            value={account}
            onChange={(e) => onChange("account_status", e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"suspended"}>Suspended</MenuItem>
          </Select>
        </div>

        <div className="sm:w-1/4 w-full ">
          <h3 className="mb-2 font-semibold">License Expiry</h3>
          <Select
            sx={selectStyles}
            value={license}
            onChange={(e) => onChange("expiry", e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"soon"}>Soon</MenuItem>
            <MenuItem value={"later"}>Later</MenuItem>
          </Select>
        </div>
      </div>
    </>
  );
};

export default Filters;
