import React from "react";
import { Rating } from "@mui/material";
import moment from "moment";
import { RiForbid2Line } from "react-icons/ri";
import image from "../../../../assets/images/photo.svg";
import verified from "../../../../assets/images/status/guard_verified.svg";
import recommended from "../../../../assets/images/status/guard_recommended.svg";
import radio_checked from "../../../../assets/images/radio-checked.svg";

const SingleJobPage = ({
  data,
  setCurrJob,
  declinedHandler,
  activeTab,
  handler,
}) => {
  console.log(data, "guard dataaa");
  return (
    <div
      className={
        "w-full h-full relative "
        // + poppins.className
      }
    >
      <div className="w-full">
        <div className="flex w-full pl-14 justify-between items-start">
          <div className="flex-row">
            <div className="flex mt-10 items-center	">
              <img
                width={700}
                height={700}
                src={
                  data?.profile_image
                    ? data?.profile_image
                    : "/profile-image.webp"
                }
                alt=""
                className="w-16 h-16 rounded-full"
              />
              <div className="pl-6">
                <p className="text-[20px] font-medium  leading-[24px] text-black mr-2">
                  {data?.first_name} {data?.last_name} |{" "}
                  {parseFloat(data?.average_rating ?? 5).toFixed(1)} (
                  {data?.reviews?.length ?? 0} reviews...)
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => setCurrJob(null)}
            className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 mr-6 mt-4 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
          >
            &#10005;
          </div>
        </div>
        <div className="flex pl-14 items-center gap-2 mt-6 text-[12px]">
          <Status status={data?.licenses[0]?.status} />
        </div>

        <div className="border-b border-[#E5E7EB] pl-14 py-10">
          <h2 className="text-sm text-gray-400">Guard description</h2>
          <p className="my-3 text-[16px]">{data?.guard_description}</p>
        </div>
        {data?.licenses?.map((item, index) => (
          <div className="border-b pl-14 border-[#E5E7EB] w-full" key={index}>
            <h2 className="text-[16px] w-1/2 mt-4 font-semibold text-secondary">
              License
            </h2>
            <div className="flex mt-6 mb-6">
              <div className="flex rounded-[50px] border border-[#366CFE] mr-6 py-[10px] px-[15px] text-[#366CFE] text-[12px] bg-[#EDF2FF]">
                <img
                  width={500}
                  height={500}
                  src={radio_checked}
                  className="w-4 h-4"
                  alt="img1"
                />
                <p className="pl-4">{item.sector}</p>
              </div>
            </div>
            <div className="flex mt-10 mb-6 columns-3 flex-wrap">
              {item.trades.map((i, index) => (
                <div
                  key={index}
                  className="flex rounded-[50px] min-w-[180px] m-2 border border-[#366CFE] py-[10px] px-[15px] text-[12px] bg-[#EDF2FF]"
                >
                  <p className="pl-4">{i}</p>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* <div className="border-b pl-14 border-[#E5E7EB] py-10"> */}
        <div className="pl-14 w-full">
          <h2 className="text-[16px] w-1/2 mt-4 font-semibold text-secondary">
            <div></div>
          </h2>
          <RatingComponent averageRating={data?.average_rating} />
        </div>

        <div className="border-b pl-14 border-[#E5E7EB] w-full">
          <div className="">
            <div className="flex flex-col">
              <ReviewComment dataRating={data?.reviews} />
            </div>
          </div>
        </div>

        <div className="pl-14 border-[#E5E7EB] w-full mb-[50px]">
          <div className="flex flex-row pr-12 pt-10 max-lg:pr-4 items-center ">
            {activeTab === "people" && (
              <button
                type="button"
                onClick={() => handler(activeTab, data)}
                className="px-6 py-2.5 bg-[#145EEE] text-white rounded-xl mr-4"
              >
                Invite to job
              </button>
            )}
            {activeTab === "applied" && (
              <button
                type="button"
                onClick={() => handler(activeTab, data)}
                className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4"
              >
                Shortlist
              </button>
            )}
            {activeTab === "shortlisted" && (
              <button
                type="button"
                onClick={() => handler(activeTab, data)}
                className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4"
              >
                Send hire request
              </button>
            )}
            {activeTab === "hired" && (
              <button
                type="button"
                onClick={() => handler(activeTab, data)}
                className="px-6 py-2.5 bg-[#F0BA03] text-white rounded-xl mr-4"
              >
                Write review
              </button>
            )}
            {activeTab === "refused" && (
              <button
                type="button"
                onClick={() => handler(activeTab, data)}
                className="px-6 py-2.5 bg-[#3F8825] text-white rounded-xl mr-4"
              >
                Undo refusal
              </button>
            )}
            {activeTab?.toString() !== "declined" &&
            activeTab?.toString() !== "lost" ? (
              <button
                onClick={() => declinedHandler(data)}
                className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3"
              >
                <RiForbid2Line className="text-xl text-white" />
              </button>
            ) : null}
          </div>
        </div>
        <div>.</div>
      </div>
    </div>
  );
};

export const Status = ({ status }) => {
  let style, icon, text;

  switch (status?.toLowerCase()) {
    case "verified":
      style = "text-[#3F8825]";
      icon = verified;
      text = "Verified";
      break;

    case "recommended":
      style = "text-[#800080]";
      icon = recommended;
      text = "Recommended";
      break;
    default:
      break;
  }
  return (
    <div className={`flex items-center mt-2`}>
      <div>
        <img
          width={1000}
          height={1000}
          src={icon}
          alt="logo"
          className="w-6 h-6 object-contain"
        />
      </div>
      <p className={`${style} text-[10px]`}>{text}</p>
    </div>
  );
};

const RatingComponent = ({ dataRating, averageRating }) => {
  const ratingDetails = [
    {
      starts: 5,
      RatingPercentage: "100%",
      ratingMeterValue: 0,
    },
    {
      starts: 4,
      RatingPercentage: "0%",

      ratingMeterValue: 0,
    },
    {
      starts: 3,
      RatingPercentage: "0%",

      ratingMeterValue: 0,
    },
    {
      starts: 2,
      RatingPercentage: "0%",

      ratingMeterValue: 0,
    },
    {
      starts: 1,
      RatingPercentage: "0%",

      ratingMeterValue: 0,
    },
  ];
  return (
    <div className="flex flex-row gap-7 pb-12 max-[1024px]:flex-col">
      <div className=" w-[246px] max-[1024px]:w-full  bg-white h-[219px] p-6  rounded-2xl pt-9 ">
        <center className="text-[#366CFE] text-5xl  w-full pb-5">
          {averageRating ? averageRating : "0"}
        </center>
        <center>
          <Rating
            precision={0.5}
            value={parseFloat(averageRating).toFixed(1)}
            readOnly
          />
        </center>
        <center className="text-base">Average Rating</center>
      </div>
      <div className=" w-[80%] max-[1024px]:w-full h-[219px] max-[724px]:h-full  bg-white  p-6  rounded-2xl px-12">
        {ratingDetails &&
          ratingDetails.map((rd) => {
            return (
              <div
                key={rd.id}
                className="flex  w-full pb-3 justify-between max-[724px]:flex-col"
              >
                <div className="w-[65%] ">
                  <meter
                    value={rd.ratingMeterValue}
                    min="1"
                    max="10"
                    className="w-full"
                  ></meter>
                </div>
                <div className="w-[30%] flex  ">
                  <span className="flex justify-start">
                    <Rating precision={0.5} value={rd.starts} readOnly />
                  </span>
                  <span className=" pl-4 flex justify-start text-[#366CFE] text-sm">
                    {rd.RatingPercentage}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const ReviewComment = ({ dataRating }) => {
  return (
    <div className="grid grid-cols-3 gap-5 bg-white  p-2">
      {dataRating &&
        dataRating.map((reviewData, i) => {
          return (
            <div
              key={reviewData?.id}
              className=" w-full rounded-md border border-[#E5E7EB] px-4 py-2 mr-4"
            >
              <div className="flex justify-between max-[724px]:flex-col  pb-5 ">
                <span className="flex">
                  <img src={image} width={30} height={30} alt="profile" />
                  <span className="pl-4">
                    <div className="text-[#0B1029] text-base">
                      {reviewData?.reviewer_id?.first_name}{" "}
                      {reviewData?.reviewer_id?.last_name}
                    </div>
                    <div className="text-[##595B66] text-[10px]">
                      {moment(reviewData?.createdAt).format("DD MMMM YYYY")}
                    </div>
                  </span>
                </span>
                <span>
                  <Rating precision={0.5} value={reviewData?.rating} readOnly />
                </span>
              </div>
              <div className="text-[#545454] text-[10px]">
                {reviewData?.description}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SingleJobPage;
