"use client";
import { Field } from "formik";
import React, { useState } from "react";

const ProcessThree = () => {


  return (
    <div className="max-w-6xl w-full mx-auto my-16 max-md:px-2">
      <h1 className="text-4xl mb-4 max-md:text-xl font-semibold">
        Job details
      </h1>
      <div className="">
        <label htmlFor="" className="text-secondary text-sm font-medium">
          Give your job headline
        </label>
        <Field
          name="jobTitle"
          className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          id="standard-basic"
          placeholder="Type your job headline"
        />
      </div>

      <label htmlFor="job-desc" className="text-secondary text-sm font-medium">
        Add a description to your job
      </label>
      <Field
        as="textarea"
        name="jobDescription"
        id="job-desc"
        cols="30"
        rows="10"
        className="w-full resize-none mb-3 mt-1 focus:outline-primary border-2 border-gray-300 rounded-xl p-2"
        placeholder="Enter description..."
      ></Field>
    </div>
  );
};

export default ProcessThree;
