import React from "react";
import "../../assets/css/listLoader.css";

const ListLoader = ({ type }) => {
  return (
    <div className="loader">
      <span className="loader-text">
        {type && type === "chat"
          ? "Wait till we are fetching data!"
          : "loading"}
      </span>
      <span className="load"></span>
    </div>
  );
};

export default ListLoader;
