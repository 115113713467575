import { deleteCookie, getCookie, setCookie } from "../../components/cookiesUtils";
import {
  DELETE_NAME,
  GET_NAME,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  POST_TRADER_REGISTER,
  POST_TRADER_REGISTER_ERROR,
  POST_TRADER_REGISTER_SUCCESS,
  SET_NAME,
  LOGOUT,
} from "../actions";
const INIT_STATE = {
  getProfile: {},
  getProfileLoading: false,
  getProfileError: "",
  postTraderRegister: [],
  postTraderRegisterLoading: false,
  postTraderRegisterError: "",
  name: getCookie("name") || "",
};

const ProfileSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        getProfile: {},
        getProfileLoading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfile: action.payload,
        getProfileLoading: false,
        getProfileError: "",
      };

    case GET_PROFILE_ERROR:
      return {
        ...state,
        getProfileLoading: false,
        getProfileError: action.payload.message,
      };
    case POST_TRADER_REGISTER:
      return {
        ...state,
        postTraderRegister: [],
        postTraderRegisterLoading: true,
      };
    case POST_TRADER_REGISTER_SUCCESS:
      return {
        ...state,
        postTraderRegister: action.payload,
        postTraderRegisterLoading: false,
        postTraderRegisterError: "",
      };

    case POST_TRADER_REGISTER_ERROR:
      return {
        ...state,
        postTraderRegisterLoading: false,
        postTraderRegisterError: action.payload.message,
      };
    case SET_NAME:
      setCookie("name", action.payload);
      return {
        ...state,
        name: action.payload,
      };
      case GET_NAME:
      const tokenFromCookie = getCookie("name");
      return {
        ...state,
        name: tokenFromCookie,
      };
    case DELETE_NAME:
      deleteCookie("name");
      return {
        ...state,
        name: null,
      };

    case LOGOUT:
      return {
        ...state,
        getProfile: {}
      }
    default:
      return { ...state };
  }
};
export default ProfileSection;
