import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const stripePromise = await loadStripe(
  "pk_test_51ObL8pSFaFJFGBYemzQX9kD0M6ijJfb9nVHwxG7gm9Kowbzez4kYrAAebbpDnOy3sV1C8UIdxB9tNMU2oWFivSqw00lmTkp78b"
);

const Checkout = () => {
  const fetchClientSecret = useCallback(() => {
    return fetch("http://localhost:5000/api/create-checkout-session", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
