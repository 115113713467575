import {
  GET_CLIENT_JOBS,
  GET_CLIENT_JOBS_ANALYSIS,
  GET_CLIENT_JOBS_ANALYSIS_ERROR,
  GET_CLIENT_JOBS_ANALYSIS_SUCCESS,
  GET_CLIENT_JOBS_ERROR,
  GET_CLIENT_JOBS_LIST,
  GET_CLIENT_JOBS_LIST_ERROR,
  GET_CLIENT_JOBS_LIST_SUCCESS,
  GET_CLIENT_JOBS_SUCCESS,
  GET_CLIENT_REVIEW,
  GET_CLIENT_REVIEW_ERROR,
  GET_CLIENT_REVIEW_SUCCESS,
  GET_FIND_TRADERS,
  GET_FIND_TRADERS_ERROR,
  GET_FIND_TRADERS_SUCCESS,
  GET_JOBS_ANALYSIS_SUCCESS,
  PATCH_CLIENT_JOB_STATUS,
  PATCH_CLIENT_JOB_STATUS_ERROR,
  PATCH_CLIENT_JOB_STATUS_SUCCESS,
  POST_JOB,
  POST_JOB_ERROR,
  POST_JOB_SUCCESS,
  POST_JOB_TOKEN,
  POST_JOB_TOKEN_ERROR,
  POST_JOB_TOKEN_SUCCESS,
  POST_REVIEW,
  POST_REVIEW_ERROR,
  POST_REVIEW_SUCCESS,
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_ERROR,
  GET_CLIENT_DASHBOARD_SUCCESS,
  GET_CLIENT_DASHBOARD_CHART,
  GET_CLIENT_DASHBOARD_CHART_ERROR,
  GET_CLIENT_DASHBOARD_CHART_SUCCESS,
  GET_CLIENT_POSTED_JOBS,
  GET_CLIENT_POSTED_JOBS_SUCCESS,
  GET_CLIENT_POSTED_JOBS_ERROR,
  REFERED_GUARD_DETAILS,
  REFERED_GUARD_DETAILS_SUCCSESS,
  REFERED_GUARD_DETAILS_ERROR,
  UPDATE_CLIENT_PASSWORD,
  UPDATE_CLIENT_PASSWORD_SUCCESS,
  UPDATE_CLIENT_PASSWORD_ERROR,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_ERROR,
  COUNT_JOBS,
  COUNT_JOBS_SUCCESS,
  COUNT_JOBS_ERROR,
  PAYMENT_DATA,
  PAYMENT_DATA_SUCCESS,
  PAYMENT_DATA_ERROR,
} from "../actions";

const INIT_STATE = {
  getClientJobs: [],
  getClientJobsLoading: false,
  getClientJobsError: "",
  getClientPostedJobs: [],
  getClientPostedJobsLoading: false,
  getClientPostedJobsError: "",
  getFindTraders: [],
  getFindTradersLoading: false,
  getFindTradersError: "",
  getClientJobsList: [],
  getClientJobsListLoading: false,
  getClientJobsListError: "",
  getClientJobsAnalysis: [],
  getClientJobsAnalysisLoading: false,
  getClientJobsAnalysisError: "",
  getClientReview: [],
  getClientReviewLoading: false,
  getClientReviewError: "",
  postJob: [],
  postJobLoading: false,
  postJobError: "",
  postJobToken: [],
  postJobTokenLoading: false,
  postJobTokenError: "",
  patchClientJobStatus: [],
  patchClientJobStatusLoading: false,
  patchClientJobStatusError: "",
  getDashboardData: {
    totalJobsPosted: 0,
    totalOpenJobs: 0,
    totalApplications: 0,
    totalSuspendedJobs: 0,
  },
  getDashboardLoading: false,
  getDashboardError: "",

  getDashboardChartData: {
    allUsers: [],
    jobChart: [],
  },
  getDashboardChartLoading: false,
  getDashboardChartDataError: "",

  referedGuardDetails: null,
  referedGuardDetailsLoading: false,
  referedGuardDetailsError: "",

  updateClientPassword: null,
  updateClientPasswordLoading: false,
  updateClientPasswordError: "",

  updateClientProfile: null,
  updateClientProfileLoading: false,
  updateClientProfileError: "",

  subscriptionPayment: null,
  subscriptionPaymentLoading: false,
  subscriptionPaymentError: "",

  paymentsData: null,
  paymentsDataLoading: false,
  paymentsDataError: "",
};

const ClientSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_DASHBOARD:
      return {
        ...state,
        getDashboardLoading: true,
      };

    case GET_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        // getDashboardData: {
        //   totalJobsPosted: action.payload.data.total_jobs,
        //   totalOpenJobs: action.payload.data.open_jobs,
        //   totalApplications: action.payload.data.total_applications,
        //   totalSuspendedJobs: action.payload.data.suspended_jobs,
        // },
        getDashboardData: action.payload,
        getDashboardLoading: false,
        getDashboardError: "",
      };

    case GET_CLIENT_DASHBOARD_ERROR:
      return {
        ...state,
        getDashboardLoading: false,
        getDashboardError: action.payload?.message,
      };

    case GET_CLIENT_DASHBOARD_CHART:
      return {
        ...state,
        getDashboardChartLoading: true,
      };

    case GET_CLIENT_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        getDashboardChartData: action.payload,
        getDashboardChartLoading: false,
        getDashboardChartDataError: "",
      };
    case GET_CLIENT_DASHBOARD_CHART_ERROR:
      return {
        ...state,
        getDashboardChartDataError: action.payload?.message,
      };
    case GET_CLIENT_JOBS:
      return {
        ...state,
        getClientJobs: [],
        getClientJobsLoading: true,
      };
    case GET_CLIENT_JOBS_SUCCESS:
      return {
        ...state,
        getClientJobs: action.payload,
        getClientJobsLoading: false,
        getClientJobsError: "",
      };

    case GET_CLIENT_JOBS_ERROR:
      return {
        ...state,
        getClientJobsLoading: false,
        getClientJobsError: action.payload?.message,
      };
    case GET_CLIENT_POSTED_JOBS:
      return {
        ...state,
        getClientPostedJobs: [],
        getClientPostedJobsLoading: true,
      };
    case GET_CLIENT_POSTED_JOBS_SUCCESS:
      return {
        ...state,
        getClientPostedJobs: action.payload,
        getClientPostedJobsLoading: false,
        getClientPostedJobsError: "",
      };

    case GET_CLIENT_POSTED_JOBS_ERROR:
      return {
        ...state,
        getClientPostedJobsLoading: false,
        getClientPostedJobsError: action.payload?.message,
      };
    case GET_FIND_TRADERS:
      return {
        ...state,
        getFindTraders: [],
        getFindTradersLoading: true,
      };
    case GET_FIND_TRADERS_SUCCESS:
      return {
        ...state,
        getFindTraders: action.payload,
        getFindTradersLoading: false,
        getFindTradersError: "",
      };

    case GET_FIND_TRADERS_ERROR:
      return {
        ...state,
        getFindTradersLoading: false,
        getFindTradersError: action.payload?.message,
      };
    case GET_CLIENT_JOBS_LIST:
      return {
        ...state,
        getClientJobsList: [],
        getClientJobsListLoading: true,
      };
    case GET_CLIENT_JOBS_LIST_SUCCESS:
      return {
        ...state,
        getClientJobsList: action.payload,
        getClientJobsListLoading: false,
        getClientJobsListError: "",
      };

    case GET_CLIENT_JOBS_LIST_ERROR:
      return {
        ...state,
        getClientJobsListLoading: false,
        getClientJobsListError: action.payload?.message,
      };
    case GET_CLIENT_JOBS_ANALYSIS:
      return {
        ...state,
        getClientJobsAnalysis: [],
        getClientJobsAnalysisLoading: true,
      };
    case GET_CLIENT_JOBS_ANALYSIS_SUCCESS:
      return {
        ...state,
        getClientJobsAnalysis: action.payload,
        getClientJobsAnalysisLoading: false,
        getClientJobsAnalysisError: "",
      };

    case GET_CLIENT_JOBS_ANALYSIS_ERROR:
      return {
        ...state,
        getClientReviewLoading: false,
        getClientReviewError: action.payload?.message,
      };
    case GET_CLIENT_REVIEW:
      return {
        ...state,
        getClientReview: [],
        getClientReviewLoading: true,
      };
    case GET_CLIENT_REVIEW_SUCCESS:
      return {
        ...state,
        getClientReview: action.payload,
        getClientReviewLoading: false,
        getClientReviewError: "",
      };

    case GET_CLIENT_REVIEW_ERROR:
      return {
        ...state,
        getClientJobsAnalysisLoading: false,
        getClientJobsAnalysisError: action.payload?.message,
      };
    case POST_JOB:
      return {
        ...state,
        postJob: [],
        postJobLoading: true,
      };
    case POST_JOB_SUCCESS:
      return {
        ...state,
        postJob: action.payload,
        postJobLoading: false,
        postJobError: "",
      };

    case POST_JOB_ERROR:
      return {
        ...state,
        postJobLoading: false,
        postJobError: action.payload?.message,
      };
    case POST_JOB_TOKEN:
      return {
        ...state,
        postJobToken: [],
        postJobTokenLoading: true,
      };
    case POST_JOB_TOKEN_SUCCESS:
      return {
        ...state,
        postJobToken: action.payload,
        postJobTokenLoading: false,
        postJobTokenError: "",
      };

    case POST_JOB_TOKEN_ERROR:
      return {
        ...state,
        postJobTokenLoading: false,
        postJobTokenError: action.payload,
      };
    case POST_REVIEW:
      return {
        ...state,
        postReview: [],
        postReviewLoading: true,
      };
    case POST_REVIEW_SUCCESS:
      return {
        ...state,
        postReview: action.payload,
        postReviewLoading: false,
        postReviewError: "",
      };

    case POST_REVIEW_ERROR:
      return {
        ...state,
        postReviewLoading: false,
        postReviewError: action.payload,
      };
    case PATCH_CLIENT_JOB_STATUS:
      return {
        ...state,
        patchClientJobStatus: [],
        patchClientJobStatusLoading: true,
      };
    case PATCH_CLIENT_JOB_STATUS_SUCCESS:
      return {
        ...state,
        patchClientJobStatus: action.payload,
        patchClientJobStatusLoading: false,
        patchClientJobStatusError: "",
      };

    case PATCH_CLIENT_JOB_STATUS_ERROR:
      return {
        ...state,
        patchClientJobStatusLoading: false,
        patchClientJobStatusError: action.payload.message,
      };

    case REFERED_GUARD_DETAILS:
      return {
        ...state,
        referedGuardDetailsLoading: true,
      };
    case REFERED_GUARD_DETAILS_SUCCSESS:
      return {
        ...state,
        referedGuardDetails: action.payload,
        referedGuardDetailsLoading: false,
        referedGuardDetailsError: "",
      };

    case REFERED_GUARD_DETAILS_ERROR:
      return {
        ...state,
        referedGuardDetailsLoading: false,
        referedGuardDetailsError: action.payload.message,
      };

    case UPDATE_CLIENT_PASSWORD:
      return {
        ...state,
        updateClientPasswordLoading: true,
      };
    case UPDATE_CLIENT_PASSWORD_SUCCESS:
      return {
        ...state,
        updateClientPassword: action.payload,
        updateClientPasswordLoading: false,
        updateClientPasswordError: "",
      };

    case UPDATE_CLIENT_PASSWORD_ERROR:
      return {
        ...state,
        updateClientPasswordLoading: false,
        updateClientPasswordError: action.payload.message,
      };

    case UPDATE_CLIENT_PROFILE:
      return {
        ...state,
        updateClientProfileLoading: true,
      };
    case UPDATE_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        updateClientProfile: action.payload,
        updateClientProfileLoading: false,
        updateClientProfileError: "",
      };

    case UPDATE_CLIENT_PROFILE_ERROR:
      return {
        ...state,
        updateClientProfileLoading: false,
        updateClientProfileError: action.payload.message,
      };

    case SUBSCRIPTION_PAYMENT:
      return {
        ...state,
        subscriptionPaymentLoading: true,
      };

    case SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        subscriptionPayment: action.payload,
        subscriptionPaymentLoading: false,
        subscriptionPaymentError: "",
      };

    case SUBSCRIPTION_PAYMENT_ERROR:
      return {
        ...state,
        subscriptionPaymentLoading: false,
        subscriptionPaymentError: action.payload.message,
      };

    case PAYMENT_DATA:
      return {
        ...state,
        paymentsDataLoading: true,
      };

    case PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        paymentsData: action.payload,
        paymentsDataLoading: false,
        paymentsDataError: "",
      };

    case PAYMENT_DATA_ERROR:
      return {
        ...state,
        paymentsDataLoading: false,
        paymentsDataError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
export default ClientSection;
