import { Button } from "@mui/material";
import React from "react";
import person from "../../../../assets/images/person.png";
import LicenseMiniCard from "./licenseMiniCard";
import { FaArrowCircleDown } from "react-icons/fa";

function MemberCard({
  guard_id: id,
  setCurrMember,
  average_rating,
  location,
  guard_first_name,
  guard_last_name,
  trading_name,
  location_address,
  licenses,
  profile_image,
}) {
  const scrollDiv = (direction) => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    const scrollAmount = 40;
    console.log(direction);

    if (direction === "up") {
      scrollableDiv.scrollBy({ top: -scrollAmount, behavior: "smooth" });
    } else if (direction === "down") {
      scrollableDiv.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="flex-col xl:w-[24%] lg:w-[32%] md:w-[48%] my-2 bg-white rounded-xl overflow-hidden h-[268px] relative flex px-8 max-md:w-full max-md:p-5 justify-between">
      <div>
        <div className="relative flex items-center w-full gap-3.5 mt-6">
          <img
            width={500}
            height={500}
            alt={""}
            loading="lazy"
            src={profile_image ? profile_image : person}
            className="aspect-square object-contain object-center w-10 overflow-hidden shrink-0 max-w-full rounded-full"
          />
          <div className="flex flex-col">
            <button onClick={() => setCurrMember(id)}>
              <div className="text-slate-900 text-base font-semibold whitespace-nowrap leading-5 hover:text-blue-500">
                {guard_first_name} {guard_last_name}
              </div>
            </button>
            <div className="text-slate-400 text-[8px] whitespace-nowrap">
              {trading_name}
            </div>
            <div className="flex items-center space-x-1">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3626_5499)">
                  <path
                    d="M10 3.8642L6.36643 3.62531L4.99801 0.1875L3.62959 3.62531L0 3.8642L2.78387 6.22799L1.87035 9.81371L4.99801 7.83674L8.12568 9.81371L7.21217 6.22799L10 3.8642Z"
                    fill="#EDC131"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3626_5499">
                    <rect width="10" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="text-[8px] text-[#EDC131] font-normal">
                {parseFloat(average_rating ? average_rating.toFixed(1) : "0.0")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-1 mt-4 mb-3">
          <svg
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.50001 0C2.84583 0 1.5 1.34583 1.5 3.00001C1.5 3.49659 1.62415 3.98895 1.86018 4.42566L4.33595 8.90332C4.36891 8.96302 4.43171 9 4.50001 9C4.5683 9 4.6311 8.96302 4.66406 8.90332L7.14075 4.42419C7.37586 3.98895 7.50001 3.49657 7.50001 2.99999C7.50001 1.34583 6.15418 0 4.50001 0ZM4.50001 4.5C3.67292 4.5 3.00001 3.82709 3.00001 3.00001C3.00001 2.17292 3.67292 1.50001 4.50001 1.50001C5.32709 1.50001 6 2.17292 6 3.00001C6 3.82709 5.32709 4.5 4.50001 4.5Z"
              fill="black"
            />
          </svg>
          <span className="text-[10px] font-medium">{location_address}</span>
        </div>

        <div className="h-[85px] overflow-y-scroll" id="scrollableDiv">
          {licenses?.length > 0 ? (
            licenses
              ?.slice(0, 3)
              ?.map((license, key) => (
                <LicenseMiniCard key={key} data={license} />
              ))
          ) : (
            <div className="text-[#6F6F6F] text-center pt-4">
              No license added yet.
            </div>
          )}
        </div>
        {licenses?.length > 2 && (
          <div className="w-full flex justify-center mt-2">
            {/* <button className="mt-2" onClick={() => scrollDiv("down")}> */}
            <FaArrowCircleDown size={14} color="#0070de" />
            {/* </button> */}
          </div>
        )}
      </div>

      <div className="relative flex w-full justify-center  mt-2 mb-4">
        {/* <Link href={`/dashboard/trade-members/${id}`}> */}
        <Button
          onClick={() => setCurrMember(id)}
          disableRipple
          className="!bg-primary !rounded-full hover:!bg-[#4585FF]"
          sx={{
            borderRadius: "6px",
            textTransform: "none",
            color: "white",
            px: "30px",
            fontSize: "10px",
          }}
        >
          View
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default MemberCard;
