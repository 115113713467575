import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardAction } from "../../../../redux/admin/action";
import user from "../../../../assets/images/admin/3-User.svg";
import { FaUsers } from "react-icons/fa6";
import { GoShieldCheck, GoShieldX } from "react-icons/go";
import { CgCloseR } from "react-icons/cg";
import { HiOutlineBriefcase, HiOutlineDocumentText } from "react-icons/hi";
import { PiFolderOpen } from "react-icons/pi";
import { LiaHandshake } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

function TopSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getDashboard } = useSelector((state) => state.Adminsection);
  const [curr, setCurr] = useState(null);
  useEffect(() => {
    dispatch(getDashboardAction());
  }, [dispatch]);

  const data = [
    {
      img: user,
      title: `${getDashboard?.total_users || 0}`,
      desc: "All users",
      image: true,
      to: "/users",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#F1FCED] flex">
          <GoShieldCheck className="text-2xl m-auto text-[#478E2E] " />
        </div>
      ),
      title: `${getDashboard?.total_guards || 0}`,
      desc: "All guards",
      padding: false,
      to: "/trade-members",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E2D5E1] flex">
          <GoShieldX className="text-2xl m-auto text-[#E68266]" />
        </div>
      ),
      title: `${getDashboard?.total_active_jobs || 0}`,
      desc: "Active jobs",
      padding: false,
      to: "/jobs?status=open",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E3DFDB] flex">
          <CgCloseR className="text-2xl m-auto text-[#EDB956]" />
        </div>
      ),
      title: `${getDashboard?.total_reviews || 0}`,
      desc: "Total reviews",
      bg: "#E3DFDB",
      padding: false,
      to: "/reviews",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <HiOutlineBriefcase className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_verified_guards || 0}`,
      desc: "Verified guards",
      padding: false,
      to: "/trade-members?status=verified",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <PiFolderOpen className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_recommended_guards || 0}`,
      desc: "Recommended guards",
      padding: false,
      to: "/trade-members?status=recommended",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <HiOutlineDocumentText className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_unverified_guards ?? 0}`,
      desc: "Unverified guards",
      padding: false,
      to: "/trade-members?status=unverified",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <LiaHandshake className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_hired_guards ?? 0}`,
      desc: "Hired guards",
      padding: false,
      to: "/trade-members?status=hired",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <HiOutlineBriefcase className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_jobs_posted || 0}`,
      desc: "Posted Jobs",
      padding: false,
      to: "/jobs",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <PiFolderOpen className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_filled_jobs || 0}`,
      desc: "Filled Jobs",
      padding: false,
      to: "/jobs?status=applied",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <HiOutlineDocumentText className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_closed_jobs || 0}`,
      desc: "Closed jobs",
      padding: false,
      to: "/jobs?status=suspended",
    },
    {
      img: (
        <div className="h-10 w-10 bg-[#E1E9FF] flex">
          <LiaHandshake className="text-2xl m-auto text-[#4477FE]" />
        </div>
      ),
      title: `${getDashboard?.total_suspended_guards || 0}`,
      desc: "Suspended guards",
      padding: false,
      to: "/trade-members?status=suspended",
    },
  ];

  return (
    <div className="w-full bg-white rounded-2xl p-6 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
      {data.map((v, i) => (
        <button key={i} onClick={() => navigate(v.to)}>
          <div
            onMouseEnter={() => setCurr(i)}
            onMouseLeave={() => setCurr(null)}
            onClick={() => setCurr(i)}
            key={i}
            className={
              "flex gap-5 transition-all ease-in-out my-2 cursor-pointer p-6 border-2 border-gray-200 rounded-2xl " +
              (curr === i ? "bg-primary border-primary" : "bg-white")
            }
          >
            <div className="rounded-full w-10 h-10 overflow-hidden flex">
              {v.image ? (
                <img
                  width={1000}
                  height={1000}
                  src={v.img}
                  alt="logo"
                  className={"w-10 h-10 object-contain bg-[#E1E9FF] p-2"}
                />
              ) : (
                <span className={`w-10 h-10 flex`}>{v.img}</span>
              )}
            </div>
            <div>
              <p
                className={
                  "font-semibold text-base" +
                  (curr === i ? "text-white" : "text-black")
                }
              >
                {v.title}
              </p>
              <p
                className={
                  "text-xs" + (curr === i ? "text-white" : "text-gray-400")
                }
              >
                {v.desc}
              </p>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default TopSection;
