import { Close } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import Top from "./top";
import MemberDetails from "./memberDetails";
import Introduction from "./introduction";
import LicenseDetails from "./LicenseDetails";
import LicenseDocuments from "./LicenseDocuments";
import ReviewSection from "./ReviewSection";
import UpdateMemberPassword from "./updateMemberPassword";

function SingleMemberPage({ setCurrMember, currMember, setPage }) {
  return (
    <div className="flex w-full h-screen">
      <div className="w-full overflow-y-auto">
        <div className="w-full min-h-screen bg-dull">
          <div className="bg-white rounded-xl w-full md:p-10 p-3">
            <Link
              to={"/trade-members"}
              className="flex items-center justify-end w-full"
            >
              <div
                onClick={() => setCurrMember(null)}
                className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
              >
                <Close />
              </div>
            </Link>
            <Top currMember={currMember} />
            <MemberDetails currMember={currMember} />
            <UpdateMemberPassword currMember={currMember} />
            <Introduction currMember={currMember} />
            <LicenseDetails
              currMember={currMember}
              setCurrentPage={(data) => {
                setPage(data);
              }}
              setCurrentMember={(data) => {
                setCurrMember(data);
              }}
            />
            <LicenseDocuments currMember={currMember} />
            <ReviewSection currMember={currMember} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleMemberPage;
