import { MenuItem, Select } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import clock from "../../../../assets/images/clock.svg";
import calendar from "../../../../assets/images/calender.svg";
import moment from "moment";

const SingleReviewPage = ({ setCurrReview, currReview }) => {
  const [account, setAccount] = useState("active");
  return (
    <div className="flex w-full h-screen">
      <div className="w-full overflow-y-auto">
        <div className="w-full min-h-screen bg-dull">
          <div className="bg-white rounded-xl w-full p-10">
            <div className="flex items-center justify-end w-full">
              <div
                onClick={() => setCurrReview(null)}
                className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
              >
                <Close />
              </div>
            </div>
            <div className="flex justify-between mt-12 mb-6 items-center ">
              <div className="flex items-center gap-2">
                <div>
                  <h2 className="text-lg text-black font-semibold">
                    {currReview.title}
                  </h2>
                  <p className="text-[#313030] text-xs font-medium">
                    by{" "}
                    {`${currReview.client.first_name} ${currReview.client.last_name}`}{" "}
                    on
                    <span className="text-[#366CFE]">
                      {moment(currReview?.created_on).format("DD MMMM YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <p className="text-black text-xs mb-8 font-normal">
              {currReview?.description}
            </p>
            <div className="w-full py-10  border-y border-gray-200">
              <p className="text-lg  font-medium mb-8">
                Posted by {currReview?.client?.first_name}{" "}
                {currReview?.client?.last_name}
              </p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-gray-400 text-sm font-normal">
                    Email address
                  </p>
                  <div className="font-semibold">{currReview.client.email}</div>
                </div>
                <div>
                  <p className="text-gray-400 text-sm font-normal">
                    Phone number{" "}
                  </p>
                  <div className="font-semibold">{currReview.client.phone}</div>
                </div>
              </div>
            </div>
            <div className="w-full py-10  border-y border-gray-200">
              <p className="text-lg  font-medium mb-8">
                Review made by {currReview?.client?.first_name}{" "}
                {currReview?.client?.last_name}
              </p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-gray-400 text-sm font-normal">
                    Email address
                  </p>
                  <div className="font-semibold">{currReview.client.email}</div>
                </div>
                <div>
                  <p className="text-gray-400 text-sm font-normal">
                    Phone number{" "}
                  </p>
                  <div className="font-semibold">{currReview.client.phone}</div>
                </div>
              </div>
            </div>
            <div className="w-full py-10  border-y border-gray-200">
              <p className="text-lg  font-medium mb-8">Rating</p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-black text-sm font-normal">
                    {currReview.rating}/5
                  </p>
                </div>
              </div>
            </div>
            <p className="mt-8 font-semibold text-lg">Job info</p>
            <div className="flex justify-between mt-12 mb-6 items-center ">
              <div className="flex items-center gap-2">
                <div>
                  <h2 className="text-lg text-black font-semibold">
                    {currReview.job?.title}
                  </h2>
                  <p className="text-[#313030] text-xs font-medium">
                    Posted by{currReview?.client?.first_name}{" "}
                    {currReview?.client?.last_name} on
                    <span className="text-[#366CFE]">
                      {moment(currReview.job?.created_on).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </p>
                </div>
              </div>

              {/* <div className="flex items-center gap-3">
                                <div>
                                    <div className="text-[8px] font-medium mb-4">
                                        ACCOUNT STATUS
                                    </div>

                                    <Select
                                        sx={{
                                            height: '40px',
                                            width: '150px',
                                            borderRadius: '9999px',
                                            color: '#067647',
                                            backgroundColor: '#ECFDF3',
                                            border: '1px solid #ABEFC6',


                                        }}
                                        value={account} onChange={e => setAccount(e.target.value)}>
                                        <MenuItem value={"active"}>Active</MenuItem>
                                        <MenuItem value={"inactive"}>Inactive</MenuItem>

                                    </Select>
                                </div>
                            </div> */}
            </div>
            <p className="text-base font-normal">
              Job description
              <p>{currReview.job?.description} </p>
            </p>
            <div className="w-full py-5">
              <p className="text-lg  font-medium mb-8">Number of hire</p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-black text-sm font-normal">
                    {currReview.job?.quantity}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full pb-5  border-b border-gray-200">
              <p className="text-lg  font-medium mb-8">Location</p>
              <div className="flex gap-10">
                <div className="mb-5">
                  <p className="text-black text-sm font-normal">
                    {currReview.job?.address}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b pl-14 border-[#E5E7EB] w-full">
          <div className="">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              Start date / time
            </h2>
            {currReview?.job?.startDateTime?.map((item, key) => (
              <div className="flex mt-6 mb-6">
                <div>
                  <span>Date</span>
                  <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                    <p className="pt-[8px] mr-[30px] ml-[5px]">
                      {moment(item.date).format("DD/MM/YYYY")}
                    </p>
                    <img
                      width={500}
                      height={500}
                      src={calendar}
                      className="w-10 h-10"
                      alt="img1"
                    />
                  </div>
                </div>
                <div className="mr-6 h-[50px] w-[40px] border-b border-[#89909D]"></div>
                <div>
                  <span>From time</span>
                  <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                    <p className="pt-[8px] mr-[50px] ml-[5px]">
                      {moment(item.fromTime).format("hh:mm A")}
                    </p>
                    <img
                      width={500}
                      height={500}
                      src={clock}
                      className="w-10 h-10"
                      alt="img1"
                    />
                  </div>
                </div>
                <div>
                  <span>To time</span>
                  <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                    <p className="pt-[8px] mr-[50px] ml-[5px]">
                      {moment(item.toTime).format("hh:mm A")}
                    </p>
                    <img
                      width={500}
                      height={500}
                      src={clock}
                      className="w-10 h-10"
                      alt="img1"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="w-1/3">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Budget
              </h2>
              <p className="text-gray-400 pt-4">
                £{currReview.job.budget} x hr
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleReviewPage;
