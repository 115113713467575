export const jobHeadings = [
  {
    name: "Premises Protection\n(Security guarding)\nStatic",
    value: "Premises protection",
    categories: [
      "Other",
      "Church Security",
      "Shop Security",
      "Mall Security",
      "Construction Site Watch",
      "Warehouse Watch",
      "Apartment Building Security",
      "Office Building Security",
      "School security",
      "Hospital Security",
    ],
  },
  {
    name: "Event/door Security",
    value: "Event/Door security",
    categories: [
      "Club Bouncer",
      "Event Door Staff",
      "Pub Security",
      "Concert Security",
      "Festival Security Staff",
    ],
  },
  {
    name: "Public Space Surveillance",
    value: "Public space surveillance",
    categories: [
      "CCTV Operator",
      "Surveillance Officer",
      "Monitoring Center Operator",
    ],
  },
  {
    name: "Cash and valuables in transit",
    value: "Cash and valuables in transit",
    categories: [
      "Armored Car Guard",
      "Cash Transport Guard",
      "Valuable Goods Transporter",
    ],
  },
  {
    name: "Vehicle Immobilisation",
    value: "Vehicle immobilization",
    categories: [
      "Vehicle Clamper",
      "Parking Enforcement",
      "Vehicle immobiliser",
    ],
  },
  {
    name: "Key Holding",
    value: "Key holding",
    categories: ["Key Holder", "Alarm Response"],
  },
  {
    name: "Close protection",
    value: "Close protection",
    categories: [
      "Bodyguard",
      "VIP Protection",
      "Executive Protection",
      "Personal Security",
      "Church Security",
      "Shop Security",
      "Mall Security",
      "Construction Site Watch",
      "Warehouse Watch",
      "Apartment Building Security",
      "Office Building Security",
      "School security",
      "Hospital Security",
    ],
  },
  {
    name: "Security Management\n(Non . front line)",
    categories: [
      "Security Manager",
      "Security Supervisor",
      "Director of Security",
      "Partner in Security Firm",
      "Security Consultant",
      "Security Operations Manager",
      "Security Administrator",
      "Key Holding Services Manager",
      "Security Staffing Coordinator",
    ],
  },
];

export const distanceData = ["5", "10", "15", "20", "25", "30", "35", "40"];
