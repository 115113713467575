
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "./card";


const Content = ({licenceData, license}) => {
  const navigate = useNavigate()

  return (
    <div className=" bg-[#EFF0F4] w-full px-2 md:px-4 lg:px-4 pt-5 poppins ">
      <div className="pt-7 mb-3 md:px-4 flex flex-col gap-4 pb-2 bg-white rounded-[10px] poppins">
        <div className="md:px-24 px-6 md:py-6 py-4" >
          {license?
           <div className="flex justify-between mb-10">
           <div className="float-left font-semibold text-2xl ">
             My License
           </div>
           <div className="float-right">
               <button
                 onClick={()=>navigate("/guard/my-licenses/add-license")}
                 className="bg-primary md:w-fit w-full px-3 py-2 rounded-[20px] text-sm text-white justify-center flex items-center"
               >
                 Add license
               </button>
           </div>
         </div>
           :<div >
            <h2 className="font-semibold text-2xl md:text-start text-center	">Trades</h2>
            <p className="text-[#242424] text-xs mt-2 md:text-start text-center mb-10">Your trades will appear here once your license has been verified, you can add more trades related to your license that you want to do.</p>

          </div>}
            <div className="grid md:grid-cols-3 grid-cols-1 gap-5 ">
              {licenceData?.map((trade, i) => {
                return <Card key={i} trade={trade} add={license} /> 
              })}
            </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
