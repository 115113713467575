import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import CalendarIcon from "../Icons/CalendarIcon";
import ClockIcon from "../Icons/ClockIcon";
import { ContentCopy, Delete } from "@mui/icons-material";
import "dayjs/locale/en-gb";
import { FieldArray } from "formik";
import DatePicker from "../../formikComponent/datepicker";

const PlannedDate = ({
  setActiveStep,
  values,
  setFieldValue,
  setValue,
  value,
}) => {
  const [dateTo, setdateTo] = useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <div className="pt-2 flex w-full justify-center">
        <div className="w-full p-4 md:w-4/5 mx-auto min-h-[80vh] overflow-y-scroll m-auto">
          {value === "yes" ? (
            <>
              <h2 className="w-full text-2xl text-center font-semibold">
                Select the date for each date and time
              </h2>
              <FieldArray name={`startDateTime`}>
                {({ remove, push }) => (
                  <>
                    {values.startDateTime.length > 0 &&
                      values.startDateTime.map((startDateTime, index) => (
                        <div
                          className="flex flex-wrap md:grid md:grid-cols-10 md:gap-10 mb-10"
                          key={index}
                        >
                          <div className="w-full col-span-3">
                            {index === 0 && (
                              <span className="text-sm font-medium">
                                Select Date
                              </span>
                            )}
                            <div className="relative w-full">
                              <label className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full absolute right-3 top-[5px] z-20">
                                <CalendarIcon />
                              </label>
                              <div className=" z-10 right-0 w-full">
                                <DatePicker
                                  placeholder="MM / DD / YYYY"
                                  name={`startDateTime.${index}.date`}
                                  classname="w-full h-14 p-4 border-primary border rounded-xl"
                                  setdate={new Date()}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-full col-span-3">
                            {index === 0 && (
                              <span className="text-sm font-medium">
                                From Time
                              </span>
                            )}
                            <div className="relative w-full">
                              <label className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full absolute right-3 top-[5px] z-20">
                                <ClockIcon />
                              </label>
                              <div className=" z-10 right-0 w-full">
                                <MobileTimePicker
                                  ampm
                                  className="w-full h-14 border-primary border rounded-xl "
                                  value={dayjs(
                                    values?.startDateTime[index]?.fromTime
                                  )}
                                  onAccept={(value) => {
                                    setFieldValue(
                                      `startDateTime.${index}.fromTime`,
                                      value.$d
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-full col-span-3">
                            {index === 0 && (
                              <span className="text-sm font-medium">
                                To Time
                              </span>
                            )}
                            <div className="relative w-full">
                              <label className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full absolute right-3 top-[5px] z-20">
                                <ClockIcon />
                              </label>
                              <div className=" z-10 right-0 w-full">
                                <MobileTimePicker
                                  ampm
                                  className="w-full h-14  border-primary border rounded-xl "
                                  value={dayjs(
                                    values?.startDateTime[index]?.toTime
                                  )}
                                  onAccept={(value) => {
                                    setFieldValue(
                                      `startDateTime.${index}.toTime`,
                                      value.$d
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-auto">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="p-3 rounded-xl border border-red-600 cursor-pointer hover:bg-red-600 hover:text-white text-red-600"
                            >
                              <Delete />
                            </button>
                          </div>
                        </div>
                      ))}
                    <div>
                      <button
                        type="button"
                        className="font-medium text-gray-500 text-sm mt-2"
                        onClick={() =>
                          push({
                            date: null,
                            time: null,
                          })
                        }
                      >
                        + Add date
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </>
          ) : (
            <>
              <h2 className="text-3xl text-center font-medium w-full my-10">
                Is there a planned start date for this job?
              </h2>
              <div
                onClick={() => {
                  setValue("yes");
                }}
                className={
                  "w-full my-3 gap-5 hover:border-primary hover:bg-[#366bfe13] hover:text-primary  rounded-xl cursor-pointer flex items-center border-2 p-5 py-3 " +
                  (value === "yes"
                    ? "border-primary text-primary bg-[#366bfe13]"
                    : "border-gray-200")
                }
              >
                <div className="w-5 h-5 rounded-full border border-gray-400 flex justify-center items-center">
                  {value === "yes" && (
                    <div className="w-3 h-3 bg-primary rounded-full"></div>
                  )}
                </div>

                <div className="text-sm">Yes</div>
              </div>

              <button
                type="button"
                onClick={() => {
                  setValue("no");
                  setActiveStep((p) => p + 1);
                }}
                className={
                  "w-full hover:border-primary hover:bg-[#366bfe13] hover:text-primary my-3 gap-5 rounded-xl cursor-pointer flex items-center border-2 p-5 py-3 " +
                  (value === "no"
                    ? "border-primary text-primary bg-[#366bfe13]"
                    : "border-gray-200")
                }
              >
                <div className="w-5 h-5 rounded-full border border-gray-400 flex justify-center items-center">
                  {value === "no" && (
                    <div className="w-3 h-3 bg-primary rounded-full"></div>
                  )}
                </div>

                <div className="text-sm">No</div>
              </button>
            </>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default PlannedDate;

// {dates.map((date, i) => (
//     <div
//       className="flex datepickers items-end gap-4 justify-between mt-5"
//       key={date + i}
//     >
//       <div>
//         <span className="text-sm font-medium">Date</span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <CalendarIcon />
//           </div>
//           <div className="flex flex-col">
//             <MobileDatePicker
//               value={dayjs(date.date)}
//               onAccept={(val) => {
//                 let currDates = dates;
//                 currDates[i].date = dayjs(val)
//                   .toDate()
//                   .toLocaleDateString();
//                 setDates(currDates);
//               }}
//             />
//             {/* <div className="rounded-xl min-w-[200px] border border-primary p-3">
//                                       {new Date(date.date).toLocaleDateString('en-gb')}
//                                   </div> */}
//           </div>
//         </div>
//       </div>

//       <div>
//         <span className="text-sm font-medium">From time</span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <ClockIcon />
//           </div>
//           <div className="flex flex-col">
//             <MobileTimePicker
//               ampm
//               value={dayjs(date.from)}
//               onAccept={(val) => {
//                 let currDates = dates;
//                 currDates[i].from = dayjs(val)
//                   .toDate()
//                   .toISOString();
//                 setDates(currDates);
//               }}
//             />
//             {/* <div className="rounded-xl min-w-[200px] border border-primary p-3">
//                                       {(date.from)}
//                                   </div> */}
//           </div>
//         </div>
//       </div>

//       <div>
//         <span className="text-sm font-medium">To time</span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <ClockIcon />
//           </div>
//           <div className="flex flex-col">
//             <MobileTimePicker
//               ampm
//               minTime={dayjs(date.from)}
//               value={dayjs(date.to)}
//               onAccept={(val) => {
//                 let currDates = dates;
//                 currDates[i].to = dayjs(val)
//                   .toDate()
//                   .toISOString();
//                 setDates(currDates);
//               }}
//             />
//             {/* <div className="rounded-xl min-w-[200px] border border-primary p-3">
//                                       {(date.to)}
//                                   </div> */}
//           </div>
//         </div>
//       </div>

//       <div className="flex items-center gap-2">
//         <div
//           onClick={() => {
//             // const newDates = dates.filter((v, ind) => v.id !== date.id);
//             setDates((p) => p.filter((v) => v.id !== date.id));
//           }}
//           className="p-3 rounded-xl border border-red-600 cursor-pointer hover:bg-red-600 hover:text-white text-red-600"
//         >
//           <Delete />
//         </div>

//         <div
//           onClick={() => {
//             setDates((p) => [...p, { ...date, id: Date.now() }]);
//           }}
//           className="p-3 rounded-xl border border-primary cursor-pointer hover:bg-primary hover:text-white text-primary"
//         >
//           <ContentCopy />
//         </div>
//       </div>
//     </div>
//   ))}
//   {!selectingDate && (
//     <button
//       className="font-medium text-gray-500 text-sm mt-2"
//       onClick={() => {
//         setSelectingDate(true);
//         setMessage("");
//       }}
//     >
//       + Add date
//     </button>
//   )}
// </>

// {selectingDate ? (
//   <div className="w-full datepickers">
//     <div className="w-full flex gap-10 py-2 justify-start">
//       <div>
//         <span className="text-sm font-medium">Select Date</span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <CalendarIcon />
//           </div>
//           <MobileDatePicker
//             //minDate={new Date()}
//             disablePast
//             onAccept={(val) => {
//               setCurrentTiming((p) => ({
//                 ...p,
//                 date: dayjs(val).toDate().toLocaleDateString(),
//               }));
//             }}
//             placeholder="Select dates"
//           />
//         </div>
//       </div>

//       <div>
//         <span className="text-sm font-medium">
//           Select From Time
//         </span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <ClockIcon />
//           </div>
//           <MobileTimePicker
//             ampm
//             onAccept={(val) => {
//               setCurrentTiming((p) => ({
//                 ...p,
//                 from: dayjs(val).toDate().toISOString(),
//               }));
//             }}
//             placeholder="From"
//           />
//         </div>
//       </div>

//       <div>
//         <span className="text-sm font-medium">
//           Select To Time
//         </span>
//         <div className="flex items-center flex-row-reverse border border-primary rounded-xl px-3 gap-3">
//           <div className="w-12 h-12 flex justify-center items-center bg-[#0074D92B] rounded-full">
//             <ClockIcon />
//           </div>
//           <MobileTimePicker
//             ampm
//             minTime={
//               dayjs(currentTiming["from"]) || dayjs(new Date())
//             }
//             onAccept={(val) => {
//               setCurrentTiming((p) => ({
//                 ...p,
//                 to: dayjs(val).toDate().toISOString(),
//               }));
//             }}
//             placeholder="To"
//           />
//         </div>
//       </div>
//     </div>
//     <p className="text-red-600 text-sm">{message && message}</p>
//     <button
//       onClick={(e) => {
//         if (
//           !currentTiming["date"] ||
//           !currentTiming["from"] ||
//           !currentTiming["to"]
//         ) {
//           setMessage("Please select all the dates!");
//           return undefined;
//         }
//         setDates((p) => [
//           ...p,
//           { ...currentTiming, id: Date.now() },
//         ]);
//         setCurrentTiming({});
//         setSelectingDate(false);
//       }}
//       className="bg-primary px-4 mt-3 py-2 text-white rounded-full"
//     >
//       Add
//     </button>
//   </div>
// ) : (
//   <></>
// )}
