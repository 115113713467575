import React, { useEffect, useState } from "react";
import MapWithCircle from "./MapWithCircle";
// import MapWithCircle from './MapWithCircle';
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, Select } from "@mui/material";
import { FormControl } from "@mui/base";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";

const ProgressThree = ({ values, setFieldValue }) => {
  const [selectedDistance, setSelectedDistance] = useState(values?.maxDistance);
  const selectedAddress = useSelector(
    (s) => s.traderSignUp.data.selectedLocationAddress
  );

  const distanceValues = [
    {
      distanceLable: "5 miles",
      distanceValues: 5,
    },
    {
      distanceLable: "10 Miles",
      distanceValues: 10,
    },
    {
      distanceLable: "20 Miles",
      distanceValues: 20,
    },
    {
      distanceLable: "30 Miles",
      distanceValues: 30,
    },
    {
      distanceLable: "50 Miles",
      distanceValues: 50,
    },
    {
      distanceLable: "70 Miles",
      distanceValues: 70,
    },

    {
      distanceLable: "Unlimited",
      distanceValues: 100000,
    },
  ];
  const handleChange = (event) => {
    setFieldValue("maxDistance", event.target.value);
    setSelectedDistance(event.target.value);
  };

  return (
    <div className="w-full flex flex-col justify-center  items-center pt-10 poppins pb-[200px]">
      <div className="w-full p-4 md:w-[60%]">
        <h1 className="justify-center flex text-3xl md:text-5xl text-secondary font-semibold mt-10">
          What is the maximum distance you are willing to travel for work?
        </h1>
        <p className="text-gray-400 mt-6 text-center">
          This will determine which leads we send you nearby your location.
          Later you can change.
        </p>
      </div>
      <div className="w-full p-4 mt-7 md:mx-auto lg:w-6/12">
        {/* {workDistance?.map((distance, index) => {
          return (
            <button
              type="button"
              key={index}
              onClick={() => {
                setSelectedDistance(distance);
                // setProgress(progess + 20);
                //   setIsValid(true);
                // setFormStep((current) => current + 1);
              }}
              className={`py-2 px-4 my-1 mr-3 rounded-xl text-lg  flex items-center gap-2 ${selectedDistance === distance
                  ? "bg-[#3F8820] text-white"
                  : "bg-[#E6EBE3]  hover:bg-[#3F8820]  hover:text-white"
                }`}
            >
              {distance} miles
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </span>
            </button>
          );
        })} */}
        <div className="w-full min-h-[350px]">
          <div>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: "100px", border: "0px", outline: "0px" }}
            >
              <InputLabel
                id="select-distance-2"
                sx={{
                  fontSize: "18px",
                  color: "black",
                  border: "0px",
                  outline: "none",
                }}
              >
                Select Distance
              </InputLabel>
              <Select
                labelId="select-distance-2"
                id="dselect-distance-2"
                value={selectedDistance}
                onChange={handleChange}
                IconComponent={() => (
                  <ArrowDropDownIcon style={{ color: "#366CFE" }} />
                )}
                sx={{
                  minWidth: "150px",
                  height: "40px",
                  border: "0px",
                  outline: "0px",
                  border: "2px solid #366CFE",
                  color: "#366CFE",
                  marginTop: "10px",
                  borderColor: "!white",
                  fontSize: "14px",
                  "& fieldset": {
                    border: "0px !important",
                    outline: "0px !important",
                  },
                }}
              >
                {distanceValues.map((data) => {
                  return (
                    <MenuItem
                      key={`distance + ${data.distanceValues}`}
                      value={data.distanceValues}
                      sx={{ fontSize: "14px" }}
                    >
                      {data.distanceLable}{" "}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className=" w-full h-[256px] my-[30px]">
            {
              <MapWithCircle
                address={values?.location?.name}
                circleRadius={parseInt(selectedDistance)}
                mapBorderRadius="0px"
                mapContainerClass="h-[320px] relative "
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressThree;
