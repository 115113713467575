import Navbar from "./components/navbar";
import Footer from "./components/footer";
import HeroSection from "./components/heroSection";
import EasyStep from "./components/easyStep";
// import ForClient from "./components/forClient";
import PopularTrade from "./components/PopularTrade";
import Protecting from "./components/Protecting";
import EducationSection from "./components/EducationSection";
import WellPayingClient from "./components/WellPayingClient";
import DiscoverHire from "./components/DiscoverHire";
import DiscoverCraftsmen from "./components/discover-craftsmen";
import { useState } from "react";
import ChatComponent from "../../../components/ai-chat-bot/chat-app/ChatComponent";

const HomePage = () => {
  const [activeTab, setActiveTab] = useState("security");
  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <HeroSection />
      <EasyStep activeTab={activeTab} setActiveTab={setActiveTab} />
      <DiscoverCraftsmen />
      {/* <ForClient /> */}
      <PopularTrade />
      {/* <Protecting /> */}
      <EducationSection />
      {/* <WellPayingClient />
      <DiscoverHire /> */}
      <div className="bg-[#29273E]">
        <Footer />
      </div>
      <ChatComponent />
    </div>
  );
};

export default HomePage;
