import React from "react";
// import image from "../../../../assets/images/_2061962484656.png";
// import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import check_blue from "../../../../assets/images/home/check_blue.svg";
import group_1 from "../../../../assets/images/home/group_1.svg";
import group_2 from "../../../../assets/images/home/group_2.webp";
import group_3 from "../../../../assets/images/home/group_3.svg";
import { motion } from "framer-motion";

const list_1 = [
  "Seamless review system",
  "easy to set up",
  "Trusted and licensed",
];

const _viewport = { once: true };

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const EducationSection = () => {
  return (
    <>
      <div className="bg-[#ffffff] py-[40px] lg:py-[80px] px-[20px] relative overflow-y-hidden">
        <div className="max-w-[1340px] mx-auto flex flex-col xl:flex-row gap-[32px] xl:gap-0 items-center ">
          <div className="flex-1">
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[#596780] text-center xl:text-left text-[15px] mb-[16px]"
            >
              FOR SECURITY PERSONNELS
            </motion.div>
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[32px] text-center xl:text-left  leading-[42px] font-[600] text-[#040815] max-w-[550px] mb-[35px]"
            >
              Get well-paying and reliable clients always
            </motion.div>
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[#596780] text-center xl:text-left  text-[17px] leading-[25px] max-w-[550px]"
            >
              Our platform provides you access to verified and licensed security
              personnel in the UK easily.
            </motion.div>
          </div>
          <div className="flex-1 bg-[#F5FAFF] py-[40px] sm:py-[70px] px-[16px] sm:px-[32px] rounded-[15px] w-full md:w-auto flex flex-col-reverse md:flex-row gap-[32px] lg:gap-0 items-center">
            <div>
              <motion.div
                initial={_inital}
                whileInView={_whileInView}
                viewport={_viewport}
                className="text-[30px] leading-[39px] font-[600] text-[#040815] max-w-[550px] "
              >
                Get well-paying and reliable clients always
              </motion.div>

              <div className="my-[32px] flex flex-col gap-[16px]">
                {list_1.map((step, index) => {
                  return (
                    <motion.div
                      key={index}
                      initial={_inital}
                      whileInView={_whileInView}
                      viewport={_viewport}
                      className="flex items-center gap-[12px] "
                    >
                      <img
                        src={check_blue}
                        alt="check_blue"
                        className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px] "
                      />
                      <span className="text-[#667085] font-[400] leading-[24px] text-[16px] ">
                        {step}
                      </span>
                    </motion.div>
                  );
                })}
              </div>

              <Link to="/trader-signup">
                <motion.button
                  initial={_inital}
                  whileInView={_whileInView}
                  className=" transition-all duration-300 text-white  bg-[#145DEB]   py-[14px] px-[48px] border-[#4c3cff] border  rounded-[36px] text-[14px] md:text-[16px]"
                >
                  Get Started
                </motion.button>
              </Link>
            </div>

            <motion.img
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={group_3}
              className="w-full max-w-[315px]"
              alt="group_3"
            />
          </div>
        </div>
      </div>

      <div className="bg-[#145DEB] py-[40px] lg:py-[80px] px-[20px] relative overflow-y-hidden">
        <div className="max-w-[1340px] mx-auto flex flex-col xl:flex-row gap-[32px] xl:gap-0 items-center ">
          <div className="flex-1">
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[#A8C5FF] text-center xl:text-left text-[15px] mb-[16px]"
            >
              FOR CLIENTS
            </motion.div>
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[32px] text-center xl:text-left  leading-[42px] font-[600] text-white max-w-[550px] mb-[35px]"
            >
              Exclusive access to skilled and verified security personnel
            </motion.div>
            <motion.div
              initial={_initialLeft}
              whileInView={_whileInViewLeft}
              viewport={_viewport}
              className="text-[#A8C5FF] text-center xl:text-left  text-[17px] leading-[25px] max-w-[550px]"
            >
              Our platform provides you access to verified and licensed security
              personnel in the UK easily.
            </motion.div>
          </div>
          <div className="flex-1 bg-white py-[40px] sm:py-[70px] px-[16px] sm:px-[32px] rounded-[15px] w-full md:w-auto flex flex-col-reverse md:flex-row gap-[32px] lg:gap-0 items-center">
            <div>
              <motion.div
                initial={_inital}
                whileInView={_whileInView}
                viewport={_viewport}
                className="text-[30px] leading-[39px] font-[600] text-[#040815] max-w-[550px] "
              >
                Get well-paying and reliable clients always
              </motion.div>

              <div className="my-[32px] flex flex-col gap-[16px]">
                {list_1.map((step, index) => {
                  return (
                    <motion.div
                      key={index}
                      initial={_inital}
                      whileInView={_whileInView}
                      viewport={_viewport}
                      className="flex items-center gap-[12px] "
                    >
                      <img
                        src={check_blue}
                        alt="check_blue"
                        className="w-[20px] sm:w-[26px] h-[20px] sm:h-[26px] "
                      />
                      <span className="text-[#667085] font-[400] leading-[24px] text-[16px] ">
                        {step}
                      </span>
                    </motion.div>
                  );
                })}
              </div>

              <Link to="/post-a-job">
                <motion.button
                  initial={_inital}
                  whileInView={_whileInView}
                  viewport={_viewport}
                  className=" transition-all duration-300 text-white  bg-[#145DEB]   py-[14px] px-[48px] border-[#4c3cff] border  rounded-[36px] text-[14px] md:text-[16px]"
                >
                  Get Started
                </motion.button>
              </Link>
            </div>

            <motion.img
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              src={group_1}
              className="w-full max-w-[315px]"
              alt="group_1"
            />
          </div>
        </div>
      </div>

      <div className="bg-[#145DEB] relative overflow-y-hidden pt-[0px] px-[20px]">
        <div className="max-w-[1340px] mx-auto flex flex-col xl:flex-row  xl:gap-0 items-center  ">
          <div className=" flex w-full ">
            <div className=" w-full lg:min-w-[510px] flex flex-col xl:block mb-[100px] lg:mb-[-70px] mt-[32px] xl:mt-0">
              <motion.div
                initial={_initialLeft}
                whileInView={_whileInViewLeft}
                viewport={_viewport}
                className="text-[#A8C5FF] text-center xl:text-left text-[15px] mb-[16px]"
              >
                GET STARTED TODAY!
              </motion.div>
              <motion.div
                initial={_initialLeft}
                whileInView={_whileInViewLeft}
                viewport={_viewport}
                className="text-[32px] text-center xl:text-left  leading-[42px] font-[600] text-white mb-[35px]"
              >
                Discover and Hire Craftspeople Today!
              </motion.div>
              <motion.div
                initial={_initialLeft}
                whileInView={_whileInViewLeft}
                viewport={_viewport}
                className="text-[#A8C5FF] text-center xl:text-left  text-[17px] mx-auto lg:mx-0 max-w-[500px] lg:max-w-auto leading-[25px]"
              >
                From door supervisors and close protection specialists to key
                holding and security escorts, IGardu delivers top-tier security
                personnel tailored to every requirement.
              </motion.div>

              <Link to="/post-a-job">
                <motion.button
                  initial={_initialLeft}
                  whileInView={_whileInViewLeft}
                  viewport={_viewport}
                  className="mx-auto xl:mx-0 transition-all duration-300 text-[#4c3cff]  bg-[#ffffff]   py-[14px] px-[48px]    rounded-[36px] text-[14px] md:text-[16px] mt-[35px]"
                >
                  Get Started
                </motion.button>
              </Link>
            </div>
          </div>

          {/* <div className=" w-[calc(3168px/5.5)] h-[calc(2336px/5.5)]"> */}
          <img
            src={group_2}
            className="hidden lg:block ml-auto w-[calc(3168px/3.6)] h-[calc(2336px/3.6)] translate-x-[15%] translate-y-[20%]  object-contain "
            alt="group_2"
          />
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default EducationSection;

// const EducationSection = () => {
//   return (
//     <div className="w-screen bg-blue-600">

//     <div className="max-w-[1440px] mx-auto w-screen h-[555px] relative ">
//     <div className="w-[887px] h-[887px] left-[-444px] top-[-443px] absolute opacity-5 bg-white rounded-full blur-[200px]" />
//     <div className="left-[125px] top-[53px] absolute justify-start items-center gap-[76px] inline-flex">
//         <div className="w-[406px] h-64 relative">
//             <div className="w-[406px] left-0 top-[206px] absolute text-indigo-300 text-[17px] font-normal font-['Instrument Sans'] leading-[25px]">Our platform provides you access to verified and licensed security personnel in the UK easily. </div>
//             <div className="w-[405px] h-[168.20px] left-0 top-0 absolute">
//                 <div className="w-[405px] left-0 top-[42.20px] absolute text-white text-[35px] font-semibold font-['Instrument Sans'] leading-[42px]">Exclusive access to skilled and verified security personnel</div>
//                 <div className="left-0 top-0 absolute text-indigo-300 text-[15px] font-medium font-['Instrument Sans'] leading-snug tracking-widest">FOR CLIENTS</div>
//             </div>
//         </div>
//         <div className="w-[720px] h-[450px] relative bg-gray-100 rounded-[15px]">
//             <div className="w-[293px] left-[60px] top-[50px] absolute text-gray-950 text-[28px] font-semibold font-['Instrument Sans'] leading-9">Exclusive access to skilled and verified security personnel</div>
//             <div className="w-[187px] h-[52px] px-6 py-[13px] left-[60px] top-[362px] absolute bg-blue-600 rounded-[30px] border border-blue-500 justify-center items-center gap-2.5 inline-flex">
//                 <div className="text-center text-white text-base font-medium font-['Instrument Sans'] leading-normal">Get Started</div>
//             </div>
//             <div className="left-[60px] top-[199px] absolute flex-col justify-start items-start gap-[15px] inline-flex">
//                 <div className="w-[290px] justify-start items-center gap-3 inline-flex">
//                     <div className="h-[26px] justify-start items-start gap-4 flex">
//                         <div className="py-0.5 justify-center items-center flex">
//                             <div className="w-[22px] h-[22px] p-1 bg-blue-600 rounded-[128px] justify-center items-center flex">
//                                 <div className="w-3.5 h-3.5 relative flex-col justify-start items-start flex" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="w-[290px] text-slate-500 text-base font-medium font-['Instrument Sans'] leading-normal">Seamless review system</div>
//                 </div>
//                 <div className="w-[290px] justify-start items-center gap-3 inline-flex">
//                     <div className="h-[26px] justify-start items-start gap-4 flex">
//                         <div className="py-0.5 justify-center items-center flex">
//                             <div className="w-[22px] h-[22px] p-1 bg-blue-600 rounded-[128px] justify-center items-center flex">
//                                 <div className="w-3.5 h-3.5 relative flex-col justify-start items-start flex" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grow shrink basis-0 text-slate-500 text-base font-medium font-['Instrument Sans'] leading-normal">easy to set up</div>
//                 </div>
//                 <div className="w-[210px] justify-start items-center gap-3 inline-flex">
//                     <div className="h-[26px] justify-start items-start gap-4 flex">
//                         <div className="py-0.5 justify-center items-center flex">
//                             <div className="w-[22px] h-[22px] p-1 bg-blue-600 rounded-[128px] justify-center items-center flex">
//                                 <div className="w-3.5 h-3.5 relative flex-col justify-start items-start flex" />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="grow shrink basis-0 text-slate-500 text-base font-medium font-['Instrument Sans'] leading-normal">Trusted and licensed</div>
//                 </div>
//             </div>
//             <div className="w-[356px] h-[355.60px] left-[338px] top-[63px] absolute bg-gray-100 ml-5">
//             <Image src={image} height={250} width={380} color="#F3F5F7"/>
//             </div>
//         </div>
//     </div>
// </div>
//     </div>

//   )
// }

// export default EducationSection
