import ProcessTracker from "../../../components/post-a-job/components/ProcessTracker";
import FrontlineJobSelectProgress from "./components/FrontlineJobSelectProgress";
import ProgressEight from "./components/ProgressEight";
import * as Yup from "yup";
import ProgressFour from "./components/ProgressFour";
import ProgressOne from "./components/ProgressOne";
import ProgressSeven from "./components/ProgressSeven";
import ProgressSix from "./components/ProgressSix";
import ProgressThree from "./components/ProgressThree";
import ProgressTwo from "./components/ProgressTwo";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { postTraderRegisterAction } from "../../../redux/profile/action";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../../main/homepage/components/navbar";

const TraderSignupPage = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [initial, setinitial] = useState(false);
  const [stepper, setstepper] = useState(0);
  const [locationInput, setLocationInput] = useState("London,UK");
  const { postTraderRegister } = useSelector((state) => state.ProfileSection);
  const { postTraderRegisterLoading } = useSelector(
    (state) => state.ProfileSection
  );
  const operatorAs = useSelector((s) => s.traderSignUp.data.operatorAs);
  const [regSuccess, setRegSuccess] = useState(false);
  const [params, setParams] = useState({
    jobLocation: "",
    locationLat: "",
    locationLng: "",
    jobDistance: "",
    jobRole: "",
    jobSector: "",
    jobTrades: [],
  });
  const [submitted, setSubMitted]= useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const jobLocation = searchParams.get("job_location") || "";
    const locationLat = searchParams.get("location_lat") || "";
    const locationLng = searchParams.get("location_lng") || "";
    const jobDistance = searchParams.get("job_distance") || "";
    const jobRole = searchParams.get("job_role") || "";
    const jobSector = searchParams.get("job_sector") || "";
    const jobTrades = searchParams.getAll("job_trades") || [];

    setParams({
      jobLocation,
      locationLat,
      locationLng,
      jobDistance,
      jobRole,
      jobSector,
      jobTrades,
    });
  }, []);

  useEffect(() => {
    // console.log(postTraderRegister);
    
    if (
      postTraderRegister &&
      postTraderRegister?.success &&
      !postTraderRegisterLoading && submitted
    ) {
      setRegSuccess(postTraderRegister);
      setSubMitted(false)
    }else if(postTraderRegister &&
      !postTraderRegister?.success && !postTraderRegisterLoading && submitted){
      notifyfail(postTraderRegister?.message)
      setSubMitted(false)
    }
  }, [postTraderRegister]);

  useEffect(() => {
    if (params.jobLocation !== "") {
      console.log(params, "signup - params");
    }
  }, [params]);

  const initialValues = {
    professionalDetails: {
      operation_type: "",
      registered_company_name: "",
      trading_name: "",
      company_reg_no: "",
      fullNames_of_partners: "",
    },
    location: {
      name: params.jobLocation || "",
      postcode: "000000",
      coordinates: [params.locationLat, params.locationLng],
    },
    maxDistance: params.jobDistance || "",
    licenceType: params.jobRole || "front_line",
    licenseSector: params.jobSector || "",
    dateFrom: null,
    dateTo: null,
    jobs: params.jobTrades || [],
    siaNumber: 0,
    lookingFor: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    news: false,
    tc: false,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    phone: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email address"),
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "* Passwords must match")
      .required("This field is required"),
    tc: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
  });

  const onSubmit = async (value) => {
    const payload = {
      professionalDetails: {
        operation_type: value.professionalDetails.operation_type || null,
        registered_company_name:
          value.professionalDetails.registered_company_name || null,
        trading_name: value.professionalDetails.trading_name || null,
        company_reg_no: value.professionalDetails.company_reg_no || null,
        fullNames_of_partners:
          value.professionalDetails.fullNames_of_partners || null,
      },
      location: {
        address: value.location.name,
        lat: value.location.coordinates[0],
        lng: value.location.coordinates[1],
        max_distance: value.maxDistance,
      },
      role: value.licenceType,
      licenseSector: value.licenseSector,
      expiryDateFrom: value.dateFrom,
      expiryDateTo: value.dateTo,
      siaNumber: value.siaNumber?.toString(),
      lookingFor: value.jobs,
      first_name: value.first_name,
      last_name: value.last_name,
      phone: value.phone?.toString(),
      email: value.email,
      password: value.password,
      description: value.description,
      news: value.news,
      tc: value.tc,
    };

    setinitial(true);
    dispatch(postTraderRegisterAction(payload));
    setSubMitted(true)
  };

  const handleNext = (values) => {    
    if (stepper === 0) {
      if (values?.professionalDetails?.operation_type === "") {
        return notifyfail("Fields are required");
      } else if (
        values?.professionalDetails?.operation_type === "Limited Company" ||
        values?.professionalDetails?.operation_type === "Limited Partnership"
      ) {
        if (
          values?.professionalDetails?.trading_name === "" ||
          values?.professionalDetails?.registered_company_name === "" ||
          values?.professionalDetails?.company_reg_no?.toString() === ""
        ) {
          return notifyfail("Fields are required");
        }
      } else if (
        values?.professionalDetails?.operation_type === "Self employed"
      ) {
        if (values?.professionalDetails?.trading_name === "") {
          return notifyfail("Fields are required");
        }
      } else if (
        values?.professionalDetails?.operation_type === "Ordinary Partnership"
      ) {
        if (
          values?.professionalDetails?.trading_name === "" ||
          values?.professionalDetails?.fullNames_of_partners === ""
        ) {
          return notifyfail("Fields are required");
        }
      }
    }
    if (stepper === 1 && values?.location?.name === "") {
      return notifyfail("Fields are required");
    }
    if(stepper === 2 && values?.maxDistance === ""){
      return notifyfail("Distance is required");
    }
    if (stepper === 3) {
      if (values.siaNumber.toString().length !== 16) {
        return notifyfail("Number must have 16 digits");
      } else if (values?.dateFrom === null) {
        return notifyfail("Fields are required");
      } else if (values?.dateTo === null) {
        return notifyfail("Fields are required");
      } else if (values?.dateFrom > values?.dateTo) {
        return notifyfail("To date is more than From date");
      } else {
        setstepper(stepper + 1);
      }
    }
    if (stepper === 4 && values?.licenseSector === "") {
      return notifyfail("Fields are required");
    }
    if (stepper === 5 && values?.licenceType === "front_line") {
      if (values?.jobs?.length === 0) {
        return notifyfail("Fields are required");
      } else {
        setstepper(stepper + 1);
      }
    }
    setstepper(stepper + 1);
  };
  const handleBack = () => {
    setstepper(stepper - 1);
  };

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  const setAddress = (value) => {
    setLocationInput(value);
  };

  return (
    <>
      <Navbar />
      <div className="w-full bg-white overflow-y-scroll">
        {regSuccess ? (
          <div className="w-3/12 md:w-full mx-auto my-[60px] text-center">
            <p className="text-[32px] text-primary">Registration successful.</p>
            <p>A verification link has been sent to your email. </p>
            <p>Pls verify and login to continue. </p>

            <button
              className="bg-primary w-[90px] px-3 py-2 rounded-[20px] mt-[50px] text-sm text-white"
              onClick={() => router("/")}
            >
              {"< Back"}
            </button>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize // Enables reinitialization with new initialValues
          >
            {({ setFieldValue, values, errors }) => (
              <Form>
                {stepper === 0 && (
                  <ProgressOne
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                  />
                )}
                {stepper === 1 && (
                  <ProgressTwo
                    setAddress={setAddress}
                    setFieldValue={setFieldValue}
                    setActiveStep={setstepper}
                    values={values}
                    text="What’s your work address?"
                  />
                )}
                {stepper === 2 && (
                  <ProgressThree
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                )}
                {stepper === 3 && (
                  <ProgressFour values={values} setFieldValue={setFieldValue} />
                )}
                {values?.licenceType === "front_line" ? (
                  <>
                    {stepper === 4 && (
                      <ProgressSix
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {stepper === 5 && (
                      <FrontlineJobSelectProgress
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {stepper === 6 && <ProgressSeven />}
                    {stepper === 7 && <ProgressEight />}
                  </>
                ) : (
                  <>
                    {stepper === 4 && (
                      <ProgressSix
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {stepper === 5 && <ProgressSeven />}
                    {stepper === 6 && <ProgressEight />}
                  </>
                )}
                <ProcessTracker
                  handleBack={handleBack}
                  handleNext={handleNext}
                  progress={
                    values?.licenceType === "front_line"
                      ? (stepper / 7) * 100
                      : (stepper / 6) * 100
                  }
                  values={values}
                  errors={errors}
                  lastText="Continue"
                />
              </Form>
            )}
          </Formik>
        )}
        {postTraderRegisterLoading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={postTraderRegisterLoading}
            // onClick={handleClose}
          >
            <CircularProgress style={{ color: "#083558" }} />
          </Backdrop>
        ) : null}
      </div>
    </>
  );
};

export default TraderSignupPage;
