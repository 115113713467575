import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field } from "formik";
import "../../assets/css/datePicker.css";

function DatePicker(props) {
  const { label, placeholder, name, setdate, errorName, classname, ...rest } =
    props;

  return (
    <>
      <div>
        <label htmlFor={name}>{label}</label>
        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            const { value } = field;
            return (
              <>
                <ReactDatePicker
                  id={name}
                  {...field}
                  {...rest}
                  calendarStartDay={1}
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  dateFormatCalendar={"MMM yyyy"}
                  dateFormat="MM / dd / yyyy"
                  placeholderText="mm / dd / yyyy"
                  minDate={setdate}
                  selected={value}
                  withPortal
                  className={classname}
                  onChange={(val) => setFieldValue(name, val)}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={100}
                  showYearDropdown
                  showMonthDropdown
                />
              </>
            );
          }}
        </Field>
        {/* <ErrorMessage name={errorName} /> */}
      </div>
    </>
  );
}

export default DatePicker;
