import React from "react";
import "./ChatButton.css";
import { IoChatbubblesOutline } from "react-icons/io5";

const ChatButton = ({ onClick }) => {
  return (
    <div className="chat-button" onClick={onClick}>
      <IoChatbubblesOutline size={30} color="white" />
    </div>
  );
};

export default ChatButton;
