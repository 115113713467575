import React, { Fragment, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { AiOutlineDelete, AiOutlineEyeInvisible } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaRegClock } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getClientPostedJobsAction,
  patchClientJobStatusAction,
} from "../../../redux/client/action";
import toast from "react-hot-toast";
const PostedJobsSection = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [account, setAccount] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [jobId, setjobid] = useState();
  const [initial, setInitial] = useState(false);
  const [data, setdata] = useState([]);
  const {
    getClientPostedJobs,
    getClientPostedJobsLoading,
    patchClientJobStatus,
    patchClientJobStatusLoading,
  } = useSelector((state) => state.ClientSection);

  useEffect(() => {
    if (
      patchClientJobStatus?.message === "Job successfully updated." &&
      initial === true
    ) {
      notify(patchClientJobStatus?.message);
      dispatch(getClientPostedJobsAction());
      setOpenModal(false);
      setInitial(false);
      setAccount("");
      setjobid("");
    }
  }, [patchClientJobStatus]);
  useEffect(() => {
    if (getClientPostedJobs?.data?.length > 0) {
      setdata(getClientPostedJobs?.data);
    }
  }, [getClientPostedJobs]);

  const jobHandler = (action, id) => {
    setAccount(action);
    setjobid(id);
    setOpenModal(true);
  };

  const statusTrn = {
    suspended: {
      button: "Suspend",
      title: "Suspend Job",
      description: `Are you sure you want to update this job status to ${account} ?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
    delete: {
      button: "Delete",
      title: "Delete Job",
      description: `Are you sure you want to update this job status to ${account} ?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };
  const callDispatch = () => {
    dispatch(
      patchClientJobStatusAction({
        data: { job_id: jobId, status: account },
      })
    );
    setInitial(true);
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn[account]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };
  return (
    <div className="h-fit  w-full flex flex-col bg-white  rounded-2xl  py-6 md:col-span-2 col-span-5 ">
      <div className="font-medium text-lg px-8 py-4">Posted jobs</div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn[account]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn[account]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn[account]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchClientJobStatusLoading
                ? "loading..."
                : statusTrn[account]?.button}
            </button>
          </div>
        </Box>
      </Modal>
      {data?.map((recentJob, index) => {
        return (
          <div key={index} className="poppins">
            <div className="px-8 py-3 grid grid-cols-12 border-y border-[#E9E9F3]">
              <div className="col-span-8 ">
                <div className="font-medium text-[#242424] text-sm">
                  {recentJob?.title}
                </div>
                <div className="text-[10px] text-[#7D7D7D]">
                  {moment(recentJob?.meta?.created_on).fromNow()}
                </div>
              </div>
              <div className="col-span-3 flex justify-between">
                <div className="my-auto">
                  <FaRegClock className="text-[#366CFE] " />
                </div>

                {/* <div className="text-sm my-auto">{item.typeOfJob}</div> */}
              </div>
              <div className="col-span-1">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className=" px-4 py-2 z-0">
                    <HiOutlineDotsVertical className="text-[#366CFE] text-xl" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-10 right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <Menu.Item>
                        <Link
                          to={`/client/my-jobs`}
                          className="flex w-full items-center rounded-md px-4 py-2 gap-2  text-[#6C6C6C] text-sm hover:text-[#366CFE]"
                        >
                          <FiEye className="text-xl" />
                          View jobs
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          type="button"
                          onClick={() => jobHandler("suspended", recentJob.id)}
                          className="flex w-full items-center rounded-md px-4 py-2 gap-2  text-[#6C6C6C] text-sm hover:text-[#366CFE]"
                        >
                          <AiOutlineEyeInvisible className="text-xl" />
                          Suspended
                        </button>
                      </Menu.Item>
                      {/* <Menu.Item>
                        <button
                          type="button"
                          onClick={() => jobHandler("delete", recentJob.id)}
                          className="flex w-full items-center rounded-md px-4 py-2 gap-2  text-[#6C6C6C] text-sm hover:text-[#366CFE]"
                        >
                          <AiOutlineDelete className="text-xl" />
                          Delete
                        </button>
                      </Menu.Item> */}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="border-b border-[#E9E9F3]"></div>
          </div>
        );
      })}

      <button
        type="button"
        onClick={() => router("/client/my-jobs")}
        className="text-center text-[#366CFE] p-3.5 text-sm inter font-normal"
      >
        See all Jobs
      </button>
    </div>
  );
};

export default PostedJobsSection;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  borderRadius: "20px",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};
