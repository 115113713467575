import { Backdrop, CircularProgress } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateAdminPasswordAction } from "../../../redux/subadmin/action";
import { TiCameraOutline } from "react-icons/ti";
import axios from "axios";
import { getProfileAction } from "../../../redux/profile/action";

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME; // replace with your own cloud name
const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET_NAME;

const Profile = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role, token } = useSelector((state) => state.Authsection);
  const { updateAdminPassword, updateAdminPasswordLoading } = useSelector(
    (state) => state.SubadminSection
  );
  const [getProfileLoading, setProfileLoading] = useState(false);
  const [updatePressed, setUpdatePressed] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminProfile, setAdminProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/subadmin/profile`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await result.json();
      // console.log(data, "admin profile");
      setAdminProfile(data.data[0]);
    };

    fetchProfile();
  }, []);

  const initialValues = {
    first_name: adminProfile?.first_name || "",
    last_name: adminProfile?.last_name || "",
    email: adminProfile?.email || "",
    countryCode: adminProfile?.country_code || "",
    phone: adminProfile?.phone || "",
  };

  const initialPassValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    old_password: Yup.string().required("Old password is required"),
    new_password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const onSubmit = async (value) => {
    // setIsSubmitted(true);
    setLoading(true);
    const payload = {
      first_name: value.first_name,
      last_name: value.last_name,
      phone: value.phone,
      country_code: value.countryCode ? value.countryCode : "+44",
      // profile_pic: profileImage
    };

    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subadmin/profile/update-profile`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await result.json();
    
    if (data && data.success) {
      toast.success(data.message);
      setIsSubmitted(false);
      setLoading(false);
      dispatch(getProfileAction(token));
    }
  };

  useEffect(() => {
    if (updateAdminPassword?.success && updatePressed) {
      toast.success(updateAdminPassword?.message);
      navigate(`/subadmin/dashboard`);
    } else if (!updateAdminPassword?.success && updatePressed) {
      toast.error(updateAdminPassword?.message);
    }
  }, [updateAdminPassword]);

  const onSubmitPass = (value) => {
    const passwordDetails = {
      old_password: value.old_password ? value.old_password : "",
      new_password: value.new_password ? value.new_password : "",
      confirm_password: value.confirm_password ? value.confirm_password : "",
    };

    console.log(passwordDetails);

    dispatch(updateAdminPasswordAction(passwordDetails));
    setUpdatePressed(true);
  };

  const updateProfilePicture = async (payload) => {
    const result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/subadmin/profile/update-profile-image`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await result.json();
    if (data && data.success) {
      toast.success(data.message);
      dispatch(getProfileAction(token));
      setLoading(false)
    }
  };

  const uploadAvatar = async ({ event }) => {
    const files = event.target.files;
    try {
      if (files && files?.length > 0) {
        const _selectedFile = files[0];
        setProfileImage(URL.createObjectURL(_selectedFile));
        console.log(_selectedFile, "selected image");
        const fileSizeInMB = _selectedFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          alert("File size should be 2MB or less.");
          return;
        }
        setLoading(true)
        const formData = new FormData();
        formData.append("file", _selectedFile);
        formData.append("upload_preset", uploadPreset);
        axios
          .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)
          .then((res) => {
            // console.log(res, "image upload");
            const payload = {
              url: res.data.secure_url,
              name: "profile_pic",
            };
            updateProfilePicture(payload);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false)
          });
      }
    } catch (error) {
      toast.error("Error updaing profile image");
      setLoading(false)
    } finally {
    }
  };

  return (
    <>
      <div className="mt-10 flex w-full bg-[#F1F2F6] px-10 py-10 pb-0 gap-4">
        <center className="h-[365px] w-[250px] bg-white rounded-2xl flex items-center justify-center flex-col mb-6">
          <div className="text-sm	">Profile</div>
          <div className=" my-6 relative bg-[#E4E4E4] rounded-full">
            <img
              src={
                profileImage
                  ? profileImage
                  : adminProfile?.profile_picture?.url
                  ? adminProfile?.profile_picture?.url
                  : data?.profile_image
                  ? data?.profile_image
                  : "/profile-image.webp"
              }
              alt="admin"
              width={95}
              height={95}
              className="rounded-full object-cover profile_image"
            />
            <label
              htmlFor="user_profile_avatar"
              className="bg-[#366CFE] rounded-full p-2.5 cursor-pointer absolute bottom-0 right-0 mb-0.5"
            >
              <TiCameraOutline className="text-white" />
            </label>
          </div>

          <input
            type="file"
            className=" hidden"
            id="user_profile_avatar"
            accept="image/*"
            onChange={(e) => {
              uploadAvatar({ event: e });
              e.target.value = null;
            }}
          />

          <div className="text-base	">
            {data?.first_name} {data?.last_name}
          </div>
        </center>

        <div className="w-full flex flex-col  bg-white rounded-xl h-fit">
          <div className="md:px-12 px-6 py-6 border-b-2 border-b-[#D8D8D8]">
            Personal details
          </div>
          <div>
            <div className="w-full md:px-16 px-6">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({}) => (
                  <Form>
                    <div className="grid grid-cols-2 w-full gap-12 py-[30px]  h-full  poppins">
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="first-name "
                          className="text-[#999999] text-xs pl-1"
                        >
                          First Name
                        </label>
                        <Field
                          type="text"
                          id="first-name"
                          name="first_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="first-name "
                          className="text-[#999999]  text-xs pl-1"
                        >
                          Last Name
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="email-address"
                          className="text-[#999999]  text-xs pl-1"
                        >
                          Email Address
                        </label>
                        <Field
                          type="email"
                          id="email-address"
                          disabled={data?.email === "" ? false : true}
                          name="email"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="phone-no"
                          className="text-[#999999] text-[10px] pl-1"
                        >
                          Mobile phone
                        </label>
                        <div className="flex">
                          <Field
                            as="select"
                            name="countryCode"
                            className=" mr-4 focus:outline-none w-[80px] text-xs border-b-2 border-[#D8D8D8]"
                          >
                            <option>+44</option>
                            <option>+144</option>
                            <option>+91</option>
                            <option>+34</option>
                          </Field>
                          <Field
                            type="textß"
                            id="phone-no"
                            name="phone"
                            className="w-full border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2">
                        <button
                          type="submit"
                          className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                        >
                          {loading ? (
                            <img
                              src={"/loading.svg"}
                              alt="loading"
                              className="w-6 h-6"
                            />
                          ) : (
                            <p>Update</p>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full min-h-screen bg-[#F1F2F6] px-10 py-10">
        <div className="w-full flex flex-col  bg-white rounded-xl h-fit">
          <div className="md:px-12 px-6 py-6 border-b-2 border-b-[#D8D8D8]">
            Update password
          </div>
          <div>
            <div className="w-full md:px-16 px-6">
              <Formik
                initialValues={initialPassValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitPass}
              >
                {({}) => (
                  <Form>
                    <div className="flex flex-col gap-4 w-full py-[30px]  h-full poppins">
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="old-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Old Password
                        </label>
                        <Field
                          type="password"
                          id="old-password"
                          name="old_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage name="old_password" />
                      </div>

                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="new-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          New Password
                        </label>
                        <Field
                          type="password"
                          name="new_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage name="new_password" />
                      </div>

                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="confirm-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Confirm New Password
                        </label>
                        <Field
                          type="password"
                          id="confirm-password"
                          name="confirm_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage
                          name="confirm_password"
                          className="error-message"
                        />
                      </div>

                      <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2 mt-5">
                        <button
                          type="submit"
                          className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                        >
                          {updateAdminPasswordLoading ? (
                            <img
                              src={"/loading.svg"}
                              alt="loading"
                              className="w-6 h-6"
                            />
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {getProfileLoading || loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          //   open={getProfileLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
};

export default Profile;
