import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import paymentCancel from "../../components/lottie/paymentCancel.json";
import { useNavigate } from "react-router-dom";

const PaymentCancel = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        navigate("/");
        clearInterval(intervalId);
      }
    }, 1000);
  }, [navigate, secondsRemaining]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentCancel,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Lottie options={defaultOptions} height={"35%"} width={"20%"} />
      <p className="font-medium text-[20px] text-[red]">
        Payment Canceled. You will be redirected to homepage...
      </p>
    </div>
  );
};

export default PaymentCancel;
