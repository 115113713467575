import React, { useEffect, useState } from "react";
import arrow_right_white from "../../assets/images/about-us/arrow_right_white.svg";

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const _viewport = { once: true };

function BecomeAPart({ image }) {
  const navigate = useNavigate();
  const { token, role } = useSelector((state) => state.Authsection);
  const [login, setLogin] = useState(false);
  const [loginRole, setLoginRole] = useState("");

  useEffect(() => {
    if (token && role) {
      setLogin(true);
      setLoginRole(role);
    } else {
      setLogin(false);
      setLoginRole("");
    }
  }, [token, role]);

  const handleClick = () => {
    if(!login){
      navigate("/sign-in")
    }else{
      if(role === 'client'){
        navigate("/client/my-jobs")
      }else if(role === 'guard'){
        navigate("/guard/job-search")
      }else{
        navigate("/jobs")
      }
    }
  }

  return (
    <motion.div
      initial={_inital}
      whileInView={_whileInView}
      viewport={_viewport}
      className={`w-full  px-0 lg:px-[20px] py-[0px] lg:py-[50px] my-[50px]  `}
    >
      <div className="px-[16px] sm:px-[32px] lg:px-0 pt-[64px] lg:pt-0 gap-[32px] lg:gap-0 lg:h-[383px] max-w-[1340px] mx-auto flex flex-col lg:flex-row items-center justify-between bg-[#145DEB] lg:rounded-[16px] overflow-hidden">
        <div className="flex basis-[52%] flex-col justify-between px-[0] lg:px-[54px] py-[0] lg:py-[48px] h-full">
          <h1 className="text-[#ffffff] text-center lg:text-left font-[600] max-w-[517px] leading-[clamp(62px,calc(78/1440*100vw),78px)] text-[clamp(52px,calc(70/1440*100vw),70px)] ">
            Become a part of IGardu
          </h1>

          <div className="flex mx-auto flex-col sm:flex-row lg:mx-0 items-center gap-[16px] mt-[32px]">
            <button className=" transition-all duration-300 text-[#145DEB]  bg-[#ffffff]   py-[12px] px-[28px] border-[#ffffff] border  rounded-[36px] text-[15px] xl:text-[16px]" onClick={handleClick}>
              Open Positions
            </button>
            <button className="flex items-center gap-[6px] transition-all duration-300   text-[#ffff]   py-[12px] px-[28px] border-[#E5E5E7] border rounded-[36px] text-[15px] xl:text-[16px]" onClick={handleClick}>
              See Contract Roles{" "}
              <img src={arrow_right_white} alt="arrow_right_white" />
            </button>
          </div>
        </div>

        <img
          src={image}
          alt="about_become_part"
          className=" max-h-[300px] sm:max-h-[400px] lg:max-h-full w-full lg:basis-[48%] object-cover"
        />
      </div>
    </motion.div>
  );
}

export default BecomeAPart;
