import React from 'react';

const LeftArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path className='group-hover:stroke-primary' d="M14 6H1M1 6L5.72727 11M1 6L5.72727 1" stroke="#646378" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default LeftArrow;
