import { Backdrop, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postForgetPasswordAction } from "../../../redux/auth/action";
import toast from "react-hot-toast";

const FRONTEND_URL = "http://localhost:3000";

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [initial, setInitial] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validate = () => {
    setErrors({});
    const error = {};

    if (email == "") {
      error.email = "Email is required";
    } else if (!isValidEmail(email)) {
      error.email = "Please enter a valid email";
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      return false;
    }

    return true;
  };

  const { postForgetPassword, postForgetPasswordLoading } = useSelector(
    (state) => state.Authsection
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (postForgetPassword?.status === true && initial === true) {
      notify(
        "Password reset link sent to your email successfully. Please check the email."
      );
      navigate("/sign-in");
      setInitial(false);
    } else if (postForgetPassword?.success === false && initial === true) {
      notifyfail(postForgetPassword?.error);
      setInitial(false);
    }
  }, [postForgetPassword]);

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    const payload = {
      email: email,
      // link: `${FRONTEND_URL}/reset-password/${email}`,
    };

    dispatch(postForgetPasswordAction(payload));
    setInitial(true);
    // notify("Password reset link sent to your email");
  };
  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <p className="nekst text-center  font-bold  text-[25px] md:text-[30px] lg:text-[40px] leading-[45px]  text-[#29273E]">
        Forgot password?
      </p>
      <p className="text-sm mt-6 mb-3 text-gray-400 w-full text-center">
        No worries, we’ll send you reset instructions.
      </p>
      {/*form */}
      <div className="mt-3 w-full md:w-[411px] flex-col gap-2 flex">
        <label htmlFor="" className={"text-sm font-bold "}>
          Email
        </label>
        <TextField
          placeholder="Email"
          id="outlined-size-full"
          variant="outlined"
          size="small"
          fullWidth={true} // Set to true to make it full width
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {errors.email && (
        <div className="text-left text-red-500 font-semibold text-[14px] mt-2">
          <span>{errors.email}</span>
        </div>
      )}
      <button
        onClick={() => handleSubmit()}
        className={
          "w-full md:w-[411px] text-md md:text-md text-white items-center px-3 py-2 mt-3 border-2 rounded-md bg-[#305CF1] flex justify-center border-primary hover:bg-white hover:text-[#305CF1] duration-300 text-sm "
        }
      >
        Reset password
      </button>
      <Link to="/sign-in">
        <p
          className={
            " text-left mt-[40px] text-sm  text-secondary leading-[27px]  font-medium "
          }
        >
          &#8592; Back to Login
        </p>
      </Link>
      {postForgetPasswordLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={postForgetPasswordLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </div>
  );
}

export default ForgetPassword;
