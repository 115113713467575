import React from "react";

const JobFilter = ({ filterAction, disabled }) => {
  const selectStyles =
    "w-[100%] mr-6 shadow-md rounded-[10px] h-[55px] border-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none outline-neutral-700 border-r-[10px] border-transparent disabled:opacity-25";
  return (
    <div className="lg:w-1/3 w-full grid grid-cols-2 gap-3 mb-4">
      <div className="w-full">
        <label className="block mb-2 text-sm font-medium">Hourly rate</label>
        <select
          title=""
          disabled={disabled}
          name="budget"
          onChange={(e) => filterAction("budget", e.target.value)}
          data-te-select-placeholder="Example placeholder"
          className={selectStyles}
        >
          {/* <option value="" className="focus:outline-none"></option> */}
          <option value="highest" className="focus:outline-none">
            Highest to lowest
          </option>
          <option value="lowest">Lowest to Highest</option>
        </select>
      </div>
      <div className="w-full">
        <label htmlFor="countries" className="block mb-2 text-sm font-medium">
          Job posted
        </label>
        <select
          disabled={disabled}
          name="created_at"
          id="operated-as"
          onChange={(e) => filterAction("created_at", e.target.value)}
          className={selectStyles}
        >
          {/* <option value="" className="focus:outline-none"></option> */}
          <option value="oldest">Oldest to newest</option>
          <option value="newest" className="focus:outline-none">
            Newest to Oldest
          </option>
        </select>
      </div>
    </div>
  );
};

export default JobFilter;
