import {
  all,
  fork,
  call,
  takeEvery,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  GET_GUARD_DASHBOARD,
  GET_GUARD_PROFILE,
  GET_JOBLIST,
  GET_JOBS,
  GET_JOBS_ANALYSIS,
  GET_LICENCE,
  GET_RATING,
  INVITE_CO_WORKERS,
  PATCH_JOB_STATUS,
  POST_JOB_APPLY,
  POST_LICENCE,
  PUT_LICENCE,
  PUT_PROFILE,
  REFER_CO_WORKERS,
  REFER_GUARD,
  UPDATE_PROFILE_IMAGE,
} from "../actions";
import {
  getGuardDashboardError,
  getGuardDashboardSuccess,
  getGuardProfileError,
  getGuardProfileSuccess,
  getJobListError,
  getJobListSuccess,
  getJobsAnalysisError,
  getJobsAnalysisSuccess,
  getJobsError,
  getJobsSuccess,
  getLicenceError,
  getLicenceSuccess,
  getRatingError,
  getRatingSuccess,
  getReferedGuardsError,
  getReferedGuardsSuccess,
  inviteCoWorkersError,
  inviteCoWorkersSuccess,
  patchJobStatusError,
  patchJobStatusSuccess,
  postJobApplyError,
  postJobApplySuccess,
  postLicenceError,
  postLicenceSuccess,
  putLicenceError,
  putLicenceSuccess,
  putProfileError,
  putProfileSuccess,
  referCoWorkersError,
  referCoWorkersSuccess,
  updateProfileImageActionError,
  updateProfileImageActionSuccess,
} from "./action";

const ApiLinks = process.env.REACT_APP_BASE_URL;
// let token;
// if (typeof window !== 'undefined') {
//   token = window.localStorage.getItem('token');
// }

function* getLicenceCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(getLicenceAsync, paylaod, token);

    yield put(getLicenceSuccess(getApiRes));
  } catch (error) {
    yield put(getLicenceError());
  }
}

const getLicenceAsync = async (payload, token) => {
  try {
    const res = await fetch(`${ApiLinks}/guard/license/list`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
export function* watchgetLicenceData() {
  yield takeLatest(GET_LICENCE, getLicenceCall);
}

//get ratings
function* getRatingCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector

    // console.log(token);
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getRatingAsync, paylaod, token);

    yield put(getRatingSuccess(getApiRes));
  } catch (error) {
    yield put(getRatingError());
  }
}

const getRatingAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/review`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetRatingData() {
  yield takeLatest(GET_RATING, getRatingCall);
}
//get Jobs
function* getJobsCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getJobsAsync, paylaod, token);

    yield put(getJobsSuccess(getApiRes));
  } catch (error) {
    yield put(getJobsError());
  }
}

const getJobsAsync = (payload, token) => {
  const filter = payload?.payload?.action
    ? `${payload?.payload?.action}=${payload?.payload?.value}`
    : "";

  return fetch(`${ApiLinks}/guard/jobs/search?${filter}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchGetJobsData() {
  yield takeLatest(GET_JOBS, getJobsCall);
}
//get Jobs Analysis
function* getJobsAnalysisCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getJobsAnalysisAsync, paylaod, token);

    yield put(getJobsAnalysisSuccess(getApiRes));
  } catch (error) {
    yield put(getJobsAnalysisError());
  }
}

const getJobsAnalysisAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/application/analytics`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetJobsAnalysisData() {
  yield takeLatest(GET_JOBS_ANALYSIS, getJobsAnalysisCall);
}
//get Job List
function* getJobListCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // console.log(token);

    // Make the API call with the token
    const getApiRes = yield call(getJobListAsync, paylaod, token);

    yield put(getJobListSuccess(getApiRes));
  } catch (error) {
    yield put(getJobListError());
  }
}

const getJobListAsync = async (payload, token) => {
  try {
    const filter = payload?.payload?.action
      ? `${payload?.payload?.action}=${payload?.payload?.value}`
      : "";
    const res = await fetch(
      `${ApiLinks}/guard/application/${payload.payload.status}?${filter}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
export function* watchGetJobListData() {
  yield takeLatest(GET_JOBLIST, getJobListCall);
}

//get Guard Dashboard
function* getGuardDashboardCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // console.log(token);

    // Make the API call with the token
    const getApiRes = yield call(getGuardDashboardAsync, paylaod, token);

    yield put(getGuardDashboardSuccess(getApiRes));
  } catch (error) {
    yield put(getGuardDashboardError());
  }
}

const getGuardDashboardAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/dashboard`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log("data from guard dashboard", data);

      return data;
    })
    .catch((err) => {
      console.log("err getting dashboard data", err);
      return err;
    });

export function* watchGetGuardDashboardData() {
  yield takeLatest(GET_GUARD_DASHBOARD, getGuardDashboardCall);
}

//get Guard Profile
function* getGuardProfileCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getGuardProfileAsync, paylaod, token);

    yield put(getGuardProfileSuccess(getApiRes));
  } catch (error) {
    yield put(getGuardProfileError());
  }
}

const getGuardProfileAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/profile`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetGuardProfileData() {
  yield takeLatest(GET_GUARD_PROFILE, getGuardProfileCall);
}

//post job apply
function* postJobApplyCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);

    const getApiRes = yield call(postJobApplyAsync, paylaod, token);

    yield put(postJobApplySuccess(getApiRes));
  } catch (error) {
    yield put(postJobApplyError());
  }
}
const postJobApplyAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/application/apply`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostJobApplyData() {
  yield takeLatest(POST_JOB_APPLY, postJobApplyCall);
}
//create license
function* postLicenceCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(postLicenceAsync, paylaod, token);
    yield put(postLicenceSuccess(getApiRes));
  } catch (error) {
    yield put(postLicenceError());
  }
}
const postLicenceAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/license/create`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostLicenceData() {
  yield takeEvery(POST_LICENCE, postLicenceCall);
}

//upadte profile
function* putProfileCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(putProfileAsync, paylaod, token);

    yield put(putProfileSuccess(getApiRes));
  } catch (error) {
    yield put(putProfileError());
  }
}
const putProfileAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/profile`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPutProfileData() {
  yield takeLatest(PUT_PROFILE, putProfileCall);
}

//upadte job status
function* patchJobStatusCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(patchJobStatusAsync, paylaod, token);

    yield put(patchJobStatusSuccess(getApiRes));
  } catch (error) {
    yield put(patchJobStatusError());
  }
}
const patchJobStatusAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/application/${payload.payload.data.id}`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: payload.payload.status }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
export function* watchpatchJobStatusData() {
  yield takeLatest(PATCH_JOB_STATUS, patchJobStatusCall);
}

//upadte license
function* putLicenceCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(putLicenceAsync, paylaod, token);

    yield put(putLicenceSuccess(getApiRes));
  } catch (error) {
    yield put(putLicenceError());
  }
}
const putLicenceAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/license/update`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload.data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPutLicenceData() {
  yield takeLatest(PUT_LICENCE, putLicenceCall);
}

function* updateProfileImageCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(updateProfileImageAsync, paylaod, token);

    yield put(updateProfileImageActionSuccess(getApiRes));
  } catch (error) {
    yield put(updateProfileImageActionError());
  }
}

const updateProfileImageAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/profile/update-profille-image`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchUpdateProfileImageData() {
  yield takeLatest(UPDATE_PROFILE_IMAGE, updateProfileImageCall);
}

function* inviteCoWorkersCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(inviteCoWorkersAsync, paylaod, token);

    yield put(inviteCoWorkersSuccess(getApiRes));
  } catch (error) {
    yield put(inviteCoWorkersError());
  }
}

const inviteCoWorkersAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/jobs/invite-co-workers`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchInviteCoWorker() {
  yield takeLatest(INVITE_CO_WORKERS, inviteCoWorkersCall);
}

function* getReferedGuardsCall(payload) {
  try {
    const { token } = yield select((state) => state.Authsection);

    const getApiRes = yield call(getReferedGuardsAsync, payload, token);

    yield put(getReferedGuardsSuccess(getApiRes));
  } catch (error) {
    yield put(getReferedGuardsError());
  }
}

const getReferedGuardsAsync = async (payload, token) => {
  try {
    const res = await fetch(
      `${ApiLinks}/guard/jobs/recommend-co-workers/${payload.payload}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};

export function* watchReferedGuardsData() {
  yield takeEvery(REFER_GUARD, getReferedGuardsCall);
}

function* referCoWorkersCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(referCoWorkersAsync, paylaod, token);

    yield put(referCoWorkersSuccess(getApiRes));
  } catch (error) {
    yield put(referCoWorkersError());
  }
}

const referCoWorkersAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/jobs/refer-co-workers`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchReferCoWorker() {
  yield takeLatest(REFER_CO_WORKERS, referCoWorkersCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchgetLicenceData),
    fork(watchGetRatingData),
    fork(watchGetJobsData),
    fork(watchGetJobsAnalysisData),
    fork(watchGetJobListData),
    fork(watchGetGuardDashboardData),
    fork(watchGetGuardProfileData),
    fork(watchPostLicenceData),
    fork(watchPutProfileData),
    fork(watchPostJobApplyData),
    fork(watchpatchJobStatusData),
    fork(watchPutLicenceData),
    fork(watchUpdateProfileImageData),
    fork(watchInviteCoWorker),
    fork(watchReferedGuardsData),
    fork(watchReferCoWorker),
  ]);
}
