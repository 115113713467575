
import { Box } from '@mui/material'
import * as React from 'react'
import { jobHeadings } from '../data';
import LeftArrow from './LeftArrow';
import { FaSearch } from 'react-icons/fa';

const ProcessTwo = ({ setActiveStep, curr, setCurr, jobTitle, setJobTitle, setFieldValue, values }) => {

    const [searchVal, setSearchVal] = React.useState("");

    React.useEffect(() => {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 200)
        }
    }, [])

    return (
        <div className="max-w-6xl w-full min-h-[50vh] rounded-lg  p-10 pt-0 mx-auto my-5">
            <Box sx={{ width: '100%' }}>
                <div className="w-full mb-5 text-center text-3xl font-semibold">
                I am looking for 
                </div>
                <div className="mx-auto mb-2 w-max lg:w-[400px] flex justify-center relative">
                    
                    <input
                        onChange={e => setSearchVal(e.target.value)}
                        value={searchVal}
                        type="text" className="border border-gray-300 outline-none focus:outline-primary w-full max-w-[500px]  max-md:w-full max-md:mb-0 p-2 pl-12 rounded-lg" placeholder="Search here" />
                    {/* <Image src="/Search.svg" alt="" width={16} height={16} className="object-contain absolute top-3 left-4 cursor-pointer saturate-0 brightness-0" /> */}
                    <FaSearch className="object-contain absolute top-3 left-4 cursor-pointer saturate-0 brightness-0"/>
                </div>

                <div className="w-full flex flex-wrap gap-4 items-center">
                    <div className="flex w-full items-center justify-between">
                        <div className="text-sm w-full font-medium flex gap-4">{!curr ? null :<button type='button' onClick={()=>setCurr("")}  className="my-auto"> <LeftArrow  /> </button>} </div>
                    </div>


                    {!curr ? <>
                        {jobHeadings?.sort()?.filter(cat => cat?.name.toLowerCase().includes(searchVal))?.map((v, i) => (
                            <div key={v.name + i} onClick={() => {
                                setFieldValue("lookingFor[0]",v.name);
                                setCurr(v.name)
                                setSearchVal("")
                                
                            }} className={"px-3 py-2 cursor-pointer flex items-center gap-2 rounded-2xl border-2 text-center text-sm font-medium hover:border-primary hover:bg-[#366CFE17] hover:text-primary " + (curr === v.name ? "border-primary text-primary" : "border-gray-300 text-secondary")}>
                                <input readOnly type="radio" className="cursor-pointer" checked={curr && curr === v.name} /> <span> {v.name}</span>
                            </div>
                        ))}

                    </>
                        :
                        <>
                            {jobHeadings.find((v) => v.name === curr).categories.sort()?.filter(cat => cat?.toLowerCase().includes(searchVal))?.map((v, i) => (
                                <div key={v + i} onClick={() => {
                                    setJobTitle(v);
                                    setFieldValue("lookingFor[1]",v);
                                    setSearchVal("")
                                    // handleNext(values);
                                }} className={"px-3 py-2 hover:bg-[#366CFE17] cursor-pointer flex items-center gap-2 rounded-2xl border-2 text-center text-sm font-medium hover:border-primary hover:text-primary " + (jobTitle === v ? "border-primary text-primary" : "border-gray-300 text-secondary")}>
                                    <input readOnly type="radio" className="cursor-pointer" checked={jobTitle && jobTitle === v} /> <span> {v}</span>
                                </div>
                            ))}
                        </>
                    }


                </div>
                {/* </CustomTabPanel> */}


            </Box>
        </div>







    )
}

export default ProcessTwo