export const PATCH_LICENSE_SUBADMIN = "PATCH_LICENSE_SUBADMIN";
export const PATCH_LICENSE_SUCCESS_SUBADMIN = "PATCH_LICENSE_SUCCESS_SUBADMIN";
export const PATCH_LICENSE_ERROR_SUBADMIN = "PATCH_LICENSE_ERROR_SUBADMIN";

//subadmin
export const GET_DASHBOARD_SUBADMIN = "GET_DASHBOARD_SUBADMIN";
export const GET_DASHBOARD_ERROR_SUBADMIN = "GET_DASHBOARD_ERROR_SUBADMIN";
export const GET_DASHBOARD_SUCCESS_SUBADMIN = "GET_DASHBOARD_SUCCESS_SUBADMIN";
export const GET_DASHBOARD_CHARTS_SUBADMIN = "GET_DASHBOARD_CHARTS_SUBADMIN";
export const GET_DASHBOARD_CHARTS_ERROR_SUBADMIN =
  "GET_DASHBOARD_CHARTS_ERROR_SUBADMIN";
export const GET_DASHBOARD_CHARTS_SUCCESS_SUBADMIN =
  "GET_DASHBOARD_CHARTS_SUCCESS_SUBADMIN";
export const GET_TRADE_MEMBERS_SUBADMIN = "GET_TRADE_MEMBERS_SUBADMIN";
export const GET_TRADE_MEMBERS_ERROR_SUBADMIN =
  "GET_TRADE_MEMBERS_ERROR_SUBADMIN";
export const GET_TRADE_MEMBERS_SUCCESS_SUBADMIN =
  "GET_TRADE_MEMBERS_SUCCESS_SUBADMIN";
export const GET_USERS_SUBADMIN = "GET_USERS_SUBADMIN";
export const GET_USERS_ERROR_SUBADMIN = "GET_USERS_ERROR_SUBADMIN";
export const GET_USERS_SUCCESS_SUBADMIN = "GET_USERS_SUCCESS_SUBADMIN";
export const GET_ADMIN_JOBS_SUBADMIN = "GET_ADMIN_JOBS_SUBADMIN";
export const GET_ADMIN_JOBS_ERROR_SUBADMIN = "GET_ADMIN_JOBS_ERROR_SUBADMIN";
export const GET_ADMIN_JOBS_SUCCESS_SUBADMIN =
  "GET_ADMIN_JOBS_SUCCESS_SUBADMIN";
export const GET_REVIEWS_SUBADMIN = "GET_REVIEWS_SUBADMIN";
export const GET_REVIEWS_ERROR_SUBADMIN = "GET_REVIEWS_ERROR_SUBADMIN";
export const GET_REVIEWS_SUCCESS_SUBADMIN = "GET_REVIEWS_SUCCESS_SUBADMIN";
export const PATCH_TRADE_MEMBERS_SUBADMIN = "PATCH_TRADE_MEMBERS_SUBADMIN";
export const PATCH_TRADE_MEMBERS_ERROR_SUBADMIN =
  "PATCH_TRADE_MEMBERS_ERROR_SUBADMIN";
export const PATCH_TRADE_MEMBERS_SUCCESS_SUBADMIN =
  "PATCH_TRADE_MEMBERS_SUCCESS_SUBADMIN";
export const DELETE_USERS_SUBADMIN = "DELETE_USERS_SUBADMIN";
export const DELETE_USERS_ERROR_SUBADMIN = "DELETE_USERS_ERROR_SUBADMIN";
export const DELETE_USERS_SUCCESS_SUBADMIN = "DELETE_USERS_SUCCESS_SUBADMIN";
export const PATCH_JOBS_SUBADMIN = "PATCH_JOBS_SUBADMIN";
export const PATCH_JOBS_ERROR_SUBADMIN = "PATCH_JOBS_ERROR_SUBADMIN";
export const PATCH_JOBS_SUCCESS_SUBADMIN = "PATCH_JOBS_SUCCESS_SUBADMIN";
export const TRADER_UPDATED_SUBADMIN = "TRADER_UPDATED_SUBADMIN";

export const PATCH_CLIENT_SUBADMIN = "PATCH_CLIENT_SUBADMIN";
export const PATCH_CLIENT_SUCCESS_SUBADMIN = "PATCH_CLIENT_SUCCESS_SUBADMIN";
export const PATCH_CLIENT_ERROR_SUBADMIN = "PATCH_CLIENT_ERROR_SUBADMIN";
export const CLIENT_UPDATED_SUBADMIN = "CLIENT_UPDATED_SUBADMIN";
export const PATCH_REVIEWS_SUBADMIN = "PATCH_REVIEWS_SUBADMIN";
export const PATCH_REVIEWS_SUCCESS_SUBADMIN = "PATCH_REVIEWS_SUCCESS_SUBADMIN";
export const PATCH_REVIEWS_ERROR_SUBADMIN = "PATCH_REVIEWS_ERROR_SUBADMIN";

export const JOB_UPDATED_SUBADMIN = "JOB_UPDATED_SUBADMIN";
export const LOGOUT_SUBADMIN = "LOGOUT_SUBADMIN";

export const GET_TRADE_MEMBERS_BETWEEN_DATES_SUBADMIN =
  "GET_TRADE_MEMBERS_BETWEEN_DATES_SUBADMIN";
export const GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR_SUBADMIN =
  "GET_TRADE_MEMBERS_BETWEEN_DATES_ERROR_SUBADMIN";
export const GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS_SUBADMIN =
  "GET_TRADE_MEMBERS_BETWEEN_DATES_SUCCESS_SUBADMIN";

export const GET_USERS_BETWEEN_DATES_SUBADMIN =
  "GET_USERS_BETWEEN_DATES_SUBADMIN";
export const GET_USERS_BETWEEN_DATES_ERROR_SUBADMIN =
  "GET_USERS_BETWEEN_DATES_ERROR_SUBADMIN";
export const GET_USERS_BETWEEN_DATES_SUCCESS_SUBADMIN =
  "GET_USERS_BETWEEN_DATES_SUCCESS_SUBADMIN";

export const GET_JOBS_BETWEEN_DATES_SUBADMIN =
  "GET_JOBS_BETWEEN_DATES_SUBADMIN";
export const GET_JOBS_BETWEEN_DATES_ERROR_SUBADMIN =
  "GET_JOBS_BETWEEN_DATES_ERROR_SUBADMIN";
export const GET_JOBS_BETWEEN_DATES_SUCCESS_SUBADMIN =
  "GET_JOBS_BETWEEN_DATES_SUCCESS_SUBADMIN";

export const GET_FILTERED_TRADE_MEMBERS_SUBADMIN =
  "GET_FILTERED_TRADE_MEMBERS_SUBADMIN";
export const GET_FILTERED_TRADE_MEMBERS_ERROR_SUBADMIN =
  "GET_FILTERED_TRADE_MEMBERS_ERROR_SUBADMIN";
export const GET_FILTERED_TRADE_MEMBERS_SUCCESS_SUBADMIN =
  "GET_FILTERED_TRADE_MEMBERS_SUCCESS_SUBADMIN";

export const REFER_GUARD_SUBADMIN = "REFER_GUARD_SUBADMIN";
export const REFER_GUARD_SUCCESS_SUBADMIN = "REFER_GUARD_SUCCESS_SUBADMIN";
export const REFER_GUARD_ERROR_SUBADMIN = "REFER_GUARD_ERROR_SUBADMIN";

export const REFER_CO_WORKERS_SUBADMIN = "REFER_CO_WORKERS_SUBADMIN";
export const REFER_CO_WORKERS_SUCCSESS_SUBADMIN =
  "REFER_CO_WORKERS_SUCCSESS_SUBADMIN";
export const REFER_CO_WORKERS_ERROR_SUBADMIN =
  "REFER_CO_WORKERS_ERROR_SUBADMIN";

export const REFERED_GUARD_DETAILS_SUBADMIN = "REFERED_GUARD_DETAILS_SUBADMIN";
export const REFERED_GUARD_DETAILS_SUCCSESS_SUBADMIN =
  "REFERED_GUARD_DETAILS_SUCCSESS_SUBADMIN";
export const REFERED_GUARD_DETAILS_ERROR_SUBADMIN =
  "REFERED_GUARD_DETAILS_ERROR_SUBADMIN";

export const UPDATE_CLIENT_PASSWORD_SUBADMIN =
  "UPDATE_CLIENT_PASSWORD_SUBADMIN";
export const UPDATE_CLIENT_PASSWORD_SUCCESS_SUBADMIN =
  "UPDATE_CLIENT_PASSWORD_SUCCESS_SUBADMIN";
export const UPDATE_CLIENT_PASSWORD_ERROR_SUBADMIN =
  "UPDATE_CLIENT_PASSWORD_ERROR_SUBADMIN";

export const UPDATE_ADMIN_PASSWORD_SUBADMIN = "UPDATE_ADMIN_PASSWORD_SUBADMIN";
export const UPDATE_ADMIN_PASSWORD_SUCCESS_SUBADMIN =
  "UPDATE_ADMIN_PASSWORD_SUCCESS_SUBADMIN";
export const UPDATE_ADMIN_PASSWORD_ERROR_SUBADMIN =
  "UPDATE_ADMIN_PASSWORD_ERROR_SUBADMIN";

export const UPDATE_CLIENT_PROFILE_SUBADMIN = "UPDATE_CLIENT_PROFILE_SUBADMIN";
export const UPDATE_CLIENT_PROFILE_SUCCESS_SUBADMIN =
  "UPDATE_CLIENT_PROFILE_SUCCESS_SUBADMIN";
export const UPDATE_CLIENT_PROFILE_ERROR_SUBADMIN =
  "UPDATE_CLIENT_PROFILE_ERROR_SUBADMIN";

export const UPDATE_PASSOWRD_SUBADMIN = "UPDATE_PASSOWRD_SUBADMIN";
export const UPDATE_PASSOWRD_SUCCESS_SUBADMIN =
  "UPDATE_PASSOWRD_SUCCESS_SUBADMIN";
export const UPDATE_PASSOWRD_ERROR_SUBADMIN = "UPDATE_PASSOWRD_ERROR_SUBADMIN";

export const SUBSCRIPTION_PAYMENT_SUBADMIN = "SUBSCRIPTION_PAYMENT_SUBADMIN";
export const SUBSCRIPTION_PAYMENT_SUCCESS_SUBADMIN =
  "SUBSCRIPTION_PAYMENT_SUCCESS_SUBADMIN";
export const SUBSCRIPTION_PAYMENT_ERROR_SUBADMIN =
  "SUBSCRIPTION_PAYMENT_ERROR_SUBADMIN";

export const PAYMENT_DATA_SUBADMIN = "PAYMENT_DATA_SUBADMIN";
export const PAYMENT_DATA_SUCCESS_SUBADMIN = "PAYMENT_DATA_SUCCESS_SUBADMIN";
export const PAYMENT_DATA_ERROR_SUBADMIN = "PAYMENT_DATA_ERROR_SUBADMIN";

export const MESSAGE_DATA_SUBADMIN = "MESSAGE_DATA_SUBADMIN";
export const MESSAGE_DATA_SUCCESS_SUBADMIN = "MESSAGE_DATA_SUCCESS_SUBADMIN";
export const MESSAGE_DATA_ERROR_SUBADMIN = "MESSAGE_DATA_ERROR_SUBADMIN";

export const SEND_MESSAGE_DATA_SUBADMIN = "SEND_MESSAGE_DATA_SUBADMIN";
export const SEND_MESSAGE_DATA_SUCCESS_SUBADMIN =
  "SEND_MESSAGE_DATA_SUCCESS_SUBADMIN";
export const SEND_MESSAGE_DATA_ERROR_SUBADMIN =
  "SEND_MESSAGE_DATA_ERROR_SUBADMIN";

export const LOGIN_DETAILS_DATA_SUBADMIN = "LOGIN_DETAILS_DATA_SUBADMIN";
export const LOGIN_DETAILS_DATA_SUCCESS_SUBADMIN =
  "LOGIN_DETAILS_DATA_SUCCESS_SUBADMIN";
export const LOGIN_DETAILS_DATA_ERROR_SUBADMIN =
  "LOGIN_DETAILS_DATA_ERROR_SUBADMIN";

export const UPLOAD_JOB_BANNER_SUBADMIN = "UPLOAD_JOB_BANNER_SUBADMIN";
export const UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN =
  "UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN";
export const UPLOAD_JOB_BANNER_ERROR_SUBADMIN =
  "UPLOAD_JOB_BANNER_ERROR_SUBADMIN";

export const GET_UPLOAD_JOB_BANNER_SUBADMIN = "GET_UPLOAD_JOB_BANNER_SUBADMIN";
export const GET_UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN =
  "GET_UPLOAD_JOB_BANNER_SUCCESS_SUBADMIN";
export const GET_UPLOAD_JOB_BANNER_ERROR_SUBADMIN =
  "GET_UPLOAD_JOB_BANNER_ERROR_SUBADMIN";

export const UPDATE_JOB_BANNER_SUBADMIN = "UPDATE_JOB_BANNER_SUBADMIN";
export const UPDATE_JOB_BANNER_SUCCESS_SUBADMIN =
  "UPDATE_JOB_BANNER_SUCCESS_SUBADMIN";
export const UPDATE_JOB_BANNER_ERROR_SUBADMIN =
  "UPDATE_JOB_BANNER_ERROR_SUBADMIN";

export const ADD_SUB_ADMIN_SUBADMIN = "ADD_SUB_ADMIN_SUBADMIN";
export const ADD_SUB_ADMIN_SUCCESS_SUBADMIN = "ADD_SUB_ADMIN_SUCCESS_SUBADMIN";
export const ADD_SUB_ADMIN_ERROR_SUBADMIN = "ADD_SUB_ADMIN_ERROR_SUBADMIN";

export const UPDATE_SUB_ADMIN_SUBADMIN = "UPDATE_SUB_ADMIN_SUBADMIN";
export const UPDATE_SUB_ADMIN_SUCCESS_SUBADMIN =
  "UPDATE_SUB_ADMIN_SUCCESS_SUBADMIN";
export const UPDATE_SUB_ADMIN_ERROR_SUBADMIN =
  "UPDATE_SUB_ADMIN_ERROR_SUBADMIN";

export const GET_SUB_ADMIN_SUBADMIN = "GET_SUB_ADMIN_SUBADMIN";
export const GET_SUB_ADMIN_SUCCESS_SUBADMIN = "GET_SUB_ADMIN_SUCCESS_SUBADMIN";
export const GET_SUB_ADMIN_ERROR_SUBADMIN = "GET_SUB_ADMIN_ERROR_SUBADMIN";
// export * from "./action";
