import { all, fork, call, takeEvery, put, select } from "redux-saga/effects";
import {
  GET_CLIENT_JOBS,
  GET_CLIENT_JOBS_ANALYSIS,
  GET_CLIENT_JOBS_LIST,
  GET_CLIENT_REVIEW,
  GET_FIND_TRADERS,
  PATCH_CLIENT_JOB_STATUS,
  POST_JOB,
  POST_JOB_TOKEN,
  POST_REVIEW,
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_CHART,
  GET_CLIENT_POSTED_JOBS,
  REFERED_GUARD_DETAILS,
  UPDATE_CLIENT_PASSWORD,
  UPDATE_CLIENT_PROFILE,
  SUBSCRIPTION_PAYMENT,
  COUNT_JOBS,
  PAYMENT_DATA,
} from "../actions";
import {
  getClientJobsAnalysisError,
  getClientJobsAnalysisSuccess,
  getClientJobsError,
  getClientJobsListError,
  getClientJobsListSuccess,
  getClientJobsSuccess,
  getClientReviewError,
  getClientReviewSuccess,
  getFindTradersError,
  getFindTradersSuccess,
  patchClientJobStatusError,
  patchClientJobStatusSuccess,
  postJobError,
  postJobSuccess,
  postJobTokenError,
  postJobTokenSuccess,
  postReviewError,
  postReviewSuccess,
  getClientDashboardSuccessAction,
  getClientDashboardErrorAction,
  getClientDashboardChartSuccessAction,
  getClientDashboardChartErrorAction,
  getClientPostedJobsSuccess,
  getClientPostedJobsError,
  referedGuardDetailsSuccess,
  referedGuardDetailsError,
  updateClientPasswordSuccess,
  updateClientPasswordError,
  updateClientProfileSuccess,
  updateClientProfileError,
  countJobsSuccess,
  countJobsError,
  subscriptionPaymentSuccess,
  subscriptionPaymentError,
  getPaymentsSuccess,
  getPaymentsError,
} from "./action";

const ApiLinks = process.env.REACT_APP_BASE_URL;

// let token;
// if (typeof window !== 'undefined') {
//   token = window.localStorage.getItem('token');
// }

function* getClientDashboardCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getDashboardAsync, payload, token);

    yield put(getClientDashboardSuccessAction(getApiRes));
  } catch (error) {
    yield put(getClientDashboardErrorAction());
  }
}

const getDashboardAsync = (_, token) =>
  fetch(`${ApiLinks}/client/dashboard/`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetClientDashboardData() {
  yield takeEvery(GET_CLIENT_DASHBOARD, getClientDashboardCall);
}

function* getClientDashboardChartCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getDashboardChartAsync, payload, token);

    yield put(getClientDashboardChartSuccessAction(getApiRes));
  } catch (error) {
    yield put(getClientDashboardChartErrorAction());
  }
}

const getDashboardChartAsync = (_, token) =>
  fetch(`${ApiLinks}/client/dashboard/charts`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetClientDashboardChartData() {
  yield takeEvery(GET_CLIENT_DASHBOARD_CHART, getClientDashboardChartCall);
}

// get Client Jobs
function* getClientJobsCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getClientJobsAsync, paylaod, token);

    yield put(getClientJobsSuccess(getApiRes));
  } catch (error) {
    yield put(getClientJobsError());
  }
}

const getClientJobsAsync = async (payload, token) => {
  const filter = payload?.payload?.action
    ? `${payload?.payload?.action}=${payload?.payload?.value}`
    : "";

  try {
    const res = await fetch(`${ApiLinks}/client/jobs/list?${filter}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
export function* watchgetClientJobsData() {
  yield takeEvery(GET_CLIENT_JOBS, getClientJobsCall);
}
// get Client posted Jobs
function* getClientPostedJobsCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getClientPostedJobsAsync, paylaod, token);

    yield put(getClientPostedJobsSuccess(getApiRes));
  } catch (error) {
    yield put(getClientPostedJobsError());
  }
}

const getClientPostedJobsAsync = async (payload, token) => {
  try {
    const res = await fetch(`${ApiLinks}/client/jobs/list`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
export function* watchgetClientPostedJobsData() {
  yield takeEvery(GET_CLIENT_POSTED_JOBS, getClientPostedJobsCall);
}
// get find Traders
function* getFindTradersCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getFindTradersAsync, paylaod, token);

    yield put(getFindTradersSuccess(getApiRes));
  } catch (error) {
    yield put(getFindTradersError(error));
  }
}

const getFindTradersAsync = (payload, token) =>
  fetch(`${ApiLinks}/client/jobs/${payload.payload}/find-guards`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchgetFindTradersData() {
  yield takeEvery(GET_FIND_TRADERS, getFindTradersCall);
}
// get Client Jobs
function* getClientJobsListCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getClientJobsListAsync, paylaod, token);

    yield put(getClientJobsListSuccess(getApiRes));
  } catch (error) {
    yield put(getClientJobsListError());
  }
}

const getClientJobsListAsync = (payload, token) =>
  fetch(
    `${ApiLinks}/client/application/${payload?.payload.id}/${payload.payload.status}/list/`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchgetClientJobsListData() {
  yield takeEvery(GET_CLIENT_JOBS_LIST, getClientJobsListCall);
}
//get Jobs Analysis
function* getClientJobsAnalysisCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getClientJobsAnalysisAsync, paylaod, token);

    yield put(getClientJobsAnalysisSuccess(getApiRes));
  } catch (error) {
    yield put(getClientJobsAnalysisError());
  }
}

const getClientJobsAnalysisAsync = (payload, token) =>
  fetch(`${ApiLinks}/client/application/${payload.payload}/analytics`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchgetClientJobsAnalysisData() {
  yield takeEvery(GET_CLIENT_JOBS_ANALYSIS, getClientJobsAnalysisCall);
}
//get client  review
function* getClientReviewCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getClientReviewAsync, paylaod, token);

    yield put(getClientReviewSuccess(getApiRes));
  } catch (error) {
    yield put(getClientReviewError());
  }
}

const getClientReviewAsync = (payload, token) =>
  fetch(`${ApiLinks}/client/review`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchgetClientReviewData() {
  yield takeEvery(GET_CLIENT_REVIEW, getClientReviewCall);
}
// POST A JOB
function* postJobCall(paylaod) {
  try {
    const getApiRes = yield call(postJobAsync, paylaod);

    yield put(postJobSuccess(getApiRes));
  } catch (error) {
    yield put(postJobError(error));
  }
}
const postJobAsync = (payload) =>
  fetch(`${ApiLinks}/client/job/post`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
export function* watchPostJobData() {
  yield takeEvery(POST_JOB, postJobCall);
}
//post a job with token
function* postJobTokenCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(postJobTokenAsync, paylaod, token);

    yield put(postJobTokenSuccess(getApiRes));
  } catch (error) {
    yield put(postJobTokenError(error));
  }
}
const postJobTokenAsync = (payload, token) =>
  fetch(`${ApiLinks}/client/job/post`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostJobTokenData() {
  yield takeEvery(POST_JOB_TOKEN, postJobTokenCall);
}

//post a review
function* postReviewCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(postReviewAsync, paylaod, token);

    yield put(postReviewSuccess(getApiRes));
  } catch (error) {
    yield put(postReviewError(error));
  }
}
const postReviewAsync = (payload, token) =>
  fetch(`${ApiLinks}/client/review`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostReviewData() {
  yield takeEvery(POST_REVIEW, postReviewCall);
}

//upadte job status
function* patchClientJobStatusCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(patchClientJobStatusAsync, paylaod, token);

    yield put(patchClientJobStatusSuccess(getApiRes));
  } catch (error) {
    yield put(patchClientJobStatusError());
  }
}
const patchClientJobStatusAsync = async (payload, token) => {
  try {
    let res;

    if (payload.payload.data.status === "requested") {
      res = await fetch(`${ApiLinks}/client/application/invite`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload.payload.data),
      });
    } else {
      res = await fetch(
        `${ApiLinks}/client/application/${payload.payload.id}/update`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload.payload.data),
        }
      );
    }

    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
export function* watchpatchClientJobStatusData() {
  yield takeEvery(PATCH_CLIENT_JOB_STATUS, patchClientJobStatusCall);
}

function* referedGuardDetailsCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(referedGuardDetailsAsync, paylaod, token);

    yield put(referedGuardDetailsSuccess(getApiRes));
  } catch (error) {
    yield put(referedGuardDetailsError());
  }
}

const referedGuardDetailsAsync = async (payload, token) => {
  try {
    const res = await fetch(
      `${ApiLinks}/client/application/referred-by/${payload.payload}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};

export function* watchReferedGuardData() {
  yield takeEvery(REFERED_GUARD_DETAILS, referedGuardDetailsCall);
}

function* updateClientPasswordCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(updateClientPasswordAsync, paylaod, token);

    yield put(updateClientPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(updateClientPasswordError());
  }
}

const updateClientPasswordAsync = async (payload, token) => {
  let res = await fetch(`${ApiLinks}/client/profile/update-password`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  });

  const data = await res.json();
  return data;
};

export function* watchUpdateClientPassword() {
  yield takeEvery(UPDATE_CLIENT_PASSWORD, updateClientPasswordCall);
}

function* updateClientProfileCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(updateClientProfileAsync, paylaod, token);

    yield put(updateClientProfileSuccess(getApiRes));
  } catch (error) {
    yield put(updateClientProfileError());
  }
}

const updateClientProfileAsync = async (payload, token) => {
  let res = await fetch(`${ApiLinks}/client/profile/update-profile`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  });

  const data = await res.json();
  return data;
};

export function* watchPatchClientProfile() {
  yield takeEvery(UPDATE_CLIENT_PROFILE, updateClientProfileCall);
}

function* subscriptionPaymentCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(subscriptionPaymentAsync, paylaod, token);

    yield put(subscriptionPaymentSuccess(getApiRes));
  } catch (error) {
    yield put(subscriptionPaymentError());
  }
}

const subscriptionPaymentAsync = async (payload, token) => {
  fetch(`${ApiLinks}/client/payments/create-checkout-session`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchSubscriptionPayment() {
  yield takeEvery(SUBSCRIPTION_PAYMENT, subscriptionPaymentCall);
}

function* paymentsDataCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(paymentsDataAsync, paylaod, token);

    yield put(getPaymentsSuccess(getApiRes));
  } catch (error) {
    yield put(getPaymentsError());
  }
}

const paymentsDataAsync = async (_, token) => {
  fetch(`${ApiLinks}/client/payments/`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchPayments() {
  yield takeEvery(PAYMENT_DATA, paymentsDataCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchgetClientJobsData),
    fork(watchgetFindTradersData),
    fork(watchgetClientJobsListData),
    fork(watchgetClientPostedJobsData),
    fork(watchgetClientReviewData),
    fork(watchPostJobData),
    fork(watchPostJobTokenData),
    fork(watchPostReviewData),
    fork(watchpatchClientJobStatusData),
    fork(watchgetClientJobsAnalysisData),
    fork(watchGetClientDashboardData),
    fork(watchGetClientDashboardChartData),
    fork(watchReferedGuardData),
    fork(watchUpdateClientPassword),
    fork(watchPatchClientProfile),
    fork(watchSubscriptionPayment),
    fork(watchPayments),
  ]);
}
