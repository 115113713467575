import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {
        licenseType: "Front-Line",
        frontLineRoleType : null,
        operatorAs:null,
        selectedLocationAddress: null

    }
}

export const traderSlice = createSlice({
    name: 'trader',
    initialState,
    reducers: {
        setLicenseType(state, action) {
            state.data["licenseType"] = action.payload
        },
        setFrontLineRoleType(state, action) {
            state.data["frontLineRoleType"] = action.payload
        },
        setOperatorAs(state,action){
            state.data["operatorAs"] = action.payload
        },
        setSelectedLocationAddress(state,action){
            state.data["selectedLocationAddress"] = action.payload
        },

    }
})

export const traderSignupReducer = traderSlice.reducer;
export const traderSignUpActions = traderSlice.actions;