import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import ConfigureStore from "./redux/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={ConfigureStore()}>
    <Suspense Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>
);

serviceWorkerRegistration.register();
reportWebVitals();
