import React from 'react'

const Introduction = ({currMember}) => {
    return (
        <div className='border-b py-10 w-full'>
            <p className="text-black font-semibold mb-4">
                Description
            </p>
            <p className="w-9/12">
               {currMember?.guard_description}
            </p>
        </div>
    )
}

export default Introduction