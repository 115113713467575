import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLicenceAction } from '../../../redux/guard/action';
import { Backdrop, CircularProgress } from '@mui/material';
import Content from '../trades/component/content';

function GuardLicense() {
    const dispatch = useDispatch();
    const { getLicence } = useSelector((state) => state.GuardSection);
    const { getLicenceLoading } = useSelector((state) => state.GuardSection);
    const [licenceData, setLicenceData] = useState([]);
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        dispatch(getLicenceAction());
    }, [])
    useEffect(() => {
        if (getLicence?.success && initial === true) {
          setLicenceData(getLicence.data);
          setInitial(false)
        }
      }, [getLicence]);
    
  return (
    <>
    <div className="md:px-10 px-3 md:py-5 py-2 bg-[#F1F2F6] w-full flex gap-7">
        <Content licenceData={licenceData} license={true} />
      </div>

      {getLicenceLoading ? (
          <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLicenceLoading}
          // onClick={handleClose}
          >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
      </>
  )
}

export default GuardLicense