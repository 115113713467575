import { Circle, GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { useEffect, useState } from "react";

const MapWithCircle = ({
  address,
  circleRadius,
  google,
  mapContainerClass,
  mapBorderRadius,
  blackBorder,
}) => {
  // Add the 'google' prop here
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const getAddressFromCoordinates = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyDv8pOMxP-jYLK9F8KWZd4HLC4Dw-xLWXg&loading=async&callback=initMap`
      );
      const data = await response.json();
      setUserLocation(data.results[0].geometry?.location);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    if (address) {
      getAddressFromCoordinates();
    }
    //eslint-disable-next-line
  }, [address]);

  const [zoomLevel, setZoomLevel] = useState(10);

  useEffect(() => {
    if (circleRadius) {
      setZoomLevel(circleRadius < 21 ? 10 : 8);
    }
  }, [circleRadius]);

  const mapStyle = {
    width: "auto",
    height: "100%",
    position: "static !important",
    borderRadius: mapBorderRadius ? mapBorderRadius : "0px",
    border: blackBorder ? blackBorder : "none",
  };

  return (
    <div
      className={
        mapContainerClass ? mapContainerClass : "relative mx-auto h-96 "
      }
    >
      <div className="h-full">
        <Map
          google={google} // Pass the 'google' prop here
          style={mapStyle}
          center={userLocation}
          initialCenter={userLocation}
          zoom={zoomLevel}
        >
          <Marker position={userLocation} />
          <Circle
            center={userLocation}
            radius={circleRadius && circleRadius * 1609.34}
            strokeColor="blue"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="blue"
            fillOpacity={0.35}
          />
        </Map>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDv8pOMxP-jYLK9F8KWZd4HLC4Dw-xLWXg",
})(MapWithCircle);
