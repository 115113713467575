import React from "react";
import UpdateUserPassword from "./updateUserPassword";

const UserDetails = ({ currUser }) => {
  return (
    <>
      <div className="w-full pt-10  border-t border-gray-200">
        <p className="text-lg underline font-medium mb-8">Personal details</p>
        <div className="flex gap-10">
          <div className="mb-5">
            <p className="text-gray-400 text-sm font-normal">Email</p>
            <div className="font-semibold"> {currUser.email}</div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">Phone </p>
            <div className="font-semibold">{currUser.phone}</div>
          </div>
        </div>
      </div>
      <UpdateUserPassword currUser={currUser} />

      <div className="w-full py-10  border-y border-gray-200">
        <p className="text-lg underline font-medium mb-8">Active jobs</p>
        <div className="flex gap-10">
          <div className="mb-5">
            <p className="text-black text-sm font-normal">
              {" "}
              {currUser.total_jobs_posted}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
