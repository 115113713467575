import React, { useEffect, useState } from "react";
import TopSection from "./components/topSection";
import Center from "./components/Center";
import { getDashboardChartsAction } from "../../../redux/admin/action";
import { useDispatch, useSelector } from "react-redux";

function Dashboard() {
  const dispatch = useDispatch();
  const { getDashboardCharts, getDashboardChartsLoading } = useSelector(
    (state) => state.Adminsection
  );
  const [data, setdata] = useState([]);
  useEffect(() => {
    dispatch(getDashboardChartsAction());
  }, [dispatch]);

  useEffect(() => {
    if (getDashboardCharts?.success === true) {
      // console.log(getDashboardCharts?.data, "user stats");
      setdata(getDashboardCharts.data);
    }
  }, [getDashboardCharts]);

  return (
    <div className="bg-[#F1F2F6] md:p-10 p-5 w-full min-h-screen">
      <TopSection />
      <Center data={data} loading={getDashboardChartsLoading} />
    </div>
  );
}

export default Dashboard;
