import {
  MESSAGE_DATA,
  MESSAGE_DATA_SUCCESS,
  MESSAGE_DATA_ERROR,
  SEND_MESSAGE_DATA,
  SEND_MESSAGE_DATA_SUCCESS,
  SEND_MESSAGE_DATA_ERROR,
} from "../actions";

const INIT_STATE = {
  getMessagesData: null,
  getMessagesLoading: false,
  getMessagesError: "",

  sendMessageData: null,
  sendMessageLoading: false,
  sendMessageError: "",
};

const JobSection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MESSAGE_DATA:
      return {
        ...state,
        getMessagesLoading: true,
      };

    case MESSAGE_DATA_SUCCESS:
      return {
        ...state,
        getMessagesData: action.payload,
        getMessagesLoading: false,
        getMessagesError: "",
      };

    case MESSAGE_DATA_ERROR:
      return {
        ...state,
        getMessagesLoading: false,
        getMessagesError: action.payload.message,
      };

    case SEND_MESSAGE_DATA:
      return {
        ...state,
        sendMessageLoading: true,
      };

    case SEND_MESSAGE_DATA_SUCCESS:
      return {
        ...state,
        sendMessageData: action.payload,
        sendMessageLoading: false,
        sendMessageError: "",
      };

    case SEND_MESSAGE_DATA_ERROR:
      return {
        ...state,
        sendMessageLoading: false,
        sendMessageError: action.payload.message,
      };

    default:
      return { ...state };
  }
};

export default JobSection;
