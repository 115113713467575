import React from 'react'
import { RiForbid2Line } from 'react-icons/ri'
import calander from "../../../assets/images/calender.svg"
import clock from "../../../assets/images/clock.svg"
import moment from 'moment'

function JobDetails({
    data,
    setCurrJob,
    activeTab,
    handler,
    declinedHandler,
  }) {
  return (
    <div className={"w-full h-full relative "
    //  + poppins.className
     }>
        <div className="w-full">
          <div className="flex w-full pl-14 justify-between items-start">
            <h2 className="text-2xl w-1/2 mt-20 font-semibold text-secondary">
              {data?.jobTitle}
            </h2>
            <div
              onClick={() => setCurrJob(null)}
              className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
            >
              &#10005;
            </div>
          </div>
          <div className="flex pl-14 items-center gap-2 mt-6 text-[12px]">
            Posted by{" "}
            {activeTab?.toString() === "jobs"
              ? data?.postedBy?.first_name
              : data?.postedBy?.first_name?.toUpperCase()}{" "}
            on{" "}
            <span className="text-primary">
              {moment(data?.createdAt).format("DD MMMM YYYY")}
            </span>
          </div>

          <div className="border-b border-[#E5E7EB] pl-14 py-10">
            <h2 className="text-sm text-gray-400">Job description</h2>
            <p className="my-3 text-[16px]">{data?.jobDescription}</p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              I am looking for
            </h2>

            <div className="flex mt-6 mb-6 gap-6">
              {data?.lookingFor?.map((item, index) => (
                <div
                  className="rounded-[50px] border border-[#366CFE]  py-[10px] px-[15px] text-[#366CFE] text-xs bg-[#EDF2FF]"
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
              Location
            </h2>
            <p className="my-3 text-sm text-secondary">{data?.location?.name}</p>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Start date / time
              </h2>
              {data?.startDateTime?.map((item, index) => (
                <div className="flex mt-6 mb-6" key={index}>
                  <div>
                    <span>Date</span>
                    <div className="flex w-[200px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[30px] ml-[5px]">
                        {moment(item.date).format("DD/MM/YYYY")}
                      </p>
                      <img
                        width={500}
                        height={500}
                        src={calander}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div className="mr-6 h-[50px] w-[40px] border-b border-[#89909D]"></div>
                  <div>
                    <span>From time</span>
                    <div className="flex w-[210px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[50px] ml-[5px]">{moment(item.fromTime).format('hh:mm a')}</p>
                      <img
                        width={500}
                        height={500}
                        src={clock}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                  <div>
                    <span>To time</span>
                    <div className="flex w-[210px] rounded-[20px] p-2 px-4 border border-[#366CFE] mr-6 text-[16px]">
                      <p className="pt-[8px] mr-[50px] ml-[5px]">{moment(item.toTime).format('hh:mm a')}</p>
                      <img
                        width={500}
                        height={500}
                        src={clock}
                        className="w-10 h-10"
                        alt="img1"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border-b pl-14 border-[#E5E7EB] w-full">
            <div className="w-1/3">
              <h2 className="ext-[16px] w-1/2 mt-4 font-medium text-secondary">
                Budget
              </h2>
              <p className="text-gray-400 pt-4">£{data?.budget} x hr</p>
            </div>
          </div>

          <div className="pl-14 border-[#E5E7EB] w-full mb-[50px]">
            <div className="flex flex-row pr-12 pt-10 max-lg:pr-4 items-center ">
              {activeTab?.toString() === "requested" ? (
                <button
                  onClick={() => handler("requested", data)}
                  className={`bg-[#18B234] w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3`}
                >
                  Confirm
                </button>
              ) : activeTab?.toString() === "jobs" ? (
                <button
                  onClick={() => handler("open", data)}
                  className="bg-primary w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  Apply job
                </button>
              ) : activeTab?.toString() === "hire_request" ? (
                <button
                  onClick={() => handler("hire_request", data)}
                  className="bg-primary w-[104px] h-[36px] rounded-[10px] text-sm text-white justify-center flex items-center mr-3"
                >
                  I was Hired
                </button>
              ) : null}
              {activeTab?.toString() !== "hired" 
              && activeTab?.toString() !== "declined"
              && activeTab?.toString() !== "lost" 
              && activeTab?.toString() !== "jobs" 
              ? (
                <button
                  onClick={() => declinedHandler(data)}
                  className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3"
                >
                  <RiForbid2Line className="text-xl text-white" />
                </button>
              ) : null}
            </div>
          </div>
          <div>.</div>
        </div>
      </div>
  )
}

export default JobDetails