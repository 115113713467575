import React, { useEffect, useState } from "react";
import moment from "moment";
import { MenuItem, Select } from "@mui/material";
import { Box, Typography, Modal } from "@mui/material";
import { patchLicenseAction } from "../../../../redux/admin/action";
import { useDispatch, useSelector } from "react-redux";

const copy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M5.83331 17.5026C4.74612 17.5026 4.20252 17.5026 3.77372 17.325C3.20199 17.0882 2.74775 16.6339 2.51093 16.0622C2.33331 15.6334 2.33331 15.0898 2.33331 14.0026V6.06927C2.33331 4.76248 2.33331 4.10909 2.58763 3.60996C2.81134 3.17091 3.16829 2.81396 3.60734 2.59026C4.10646 2.33594 4.75986 2.33594 6.06665 2.33594H14C15.0872 2.33594 15.6308 2.33594 16.0596 2.51355C16.6313 2.75037 17.0855 3.20461 17.3224 3.77634C17.5 4.20514 17.5 4.74874 17.5 5.83594M14.2333 25.6693H21.9333C23.2401 25.6693 23.8935 25.6693 24.3926 25.415C24.8317 25.1912 25.1886 24.8343 25.4123 24.3952C25.6666 23.8961 25.6666 23.2427 25.6666 21.9359V14.2359C25.6666 12.9291 25.6666 12.2758 25.4123 11.7766C25.1886 11.3376 24.8317 10.9806 24.3926 10.7569C23.8935 10.5026 23.2401 10.5026 21.9333 10.5026H14.2333C12.9265 10.5026 12.2731 10.5026 11.774 10.7569C11.335 10.9806 10.978 11.3376 10.7543 11.7766C10.5 12.2758 10.5 12.9291 10.5 14.2359V21.9359C10.5 23.2427 10.5 23.8961 10.7543 24.3952C10.978 24.8343 11.335 25.1912 11.774 25.415C12.2731 25.6693 12.9265 25.6693 14.2333 25.6693Z"
      stroke="#366CFE"
      strokeWidth="1.45201"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const circle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 16 17"
    fill="none"
  >
    <circle cx="8" cy="8.5" r="7.5" stroke="black" />
  </svg>
);

const LicenseDetailsCard = ({
  license,
  setInitial,
  setIsLicenseStatusUpdated,
  currMember,
  setCurrentMember,
}) => {
  const [account, setAccount] = useState(license?.status);
  const [openModal, setOpenModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const dispatch = useDispatch();

  const { patchLicenseStatus, patchLicenseStatusLoading } = useSelector(
    (state) => state.Adminsection
  );

  const [statusStyles, setStatusStyles] = useState({
    height: "40px",
    minWidth: "150px",
    borderRadius: "9999px",
    color: "#067647",
    backgroundColor: "#ECFDF3",
    border: "1px solid #ABEFC6",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (account === "verified") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#067647",
        backgroundColor: "#ECFDF3",
        border: "1px solid #ABEFC6",
      });
    } else if (account === "suspended") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#F2A50F",
        backgroundColor: "#F2A50F1A",
        border: "1px solid #F2A50F",
      });
    } else if (account === "close") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#D92D20",
        backgroundColor: "#D92D201A",
        border: "1px solid #D92D20",
      });
    } else if (account === "recommended") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#800080",
        backgroundColor: "#8000801A",
        border: "1px solid #800080",
      });
    } else if (account === "unverified") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#6F6F6F",
        backgroundColor: "#6F6F6F1A",
        border: "1px solid #6F6F6F",
      });
    }
    // dispatch(
    //   patchTradeMembersAction({
    //     trader_id: guard_id?.id,
    //     status: account,
    //   })
    // );
  }, [account]);

  useEffect(() => {
    if (patchLicenseStatus.success) {
      setOpenModal(false);
    } else if (patchLicenseStatus.success === false) {
      setOpenModal(false);
    }
  }, [patchLicenseStatus]);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const statusTrn = {
    unverified: {
      button: "Unverify",
      title: "License Unverified",
      description: `Are you sure you want to update this user's License status to ${account}?`,
      bg: "bg-[#6F6F6F1A]",
      color: "#6F6F6F",
      bt: "bg-[#6F6F6F] text-white",
    },
    verified: {
      button: "Verify",
      title: "License verification",
      description: `Are you sure you want to update this user's License status to ${account}?`,
      bg: "bg-[#3F88251A]",
      color: "#3F8825",
      bt: "bg-[#3F8825] text-white",
    },
    suspended: {
      button: "Suspend",
      title: "Suspend account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
    recommended: {
      button: "Confirm",
      title: "License Recommendation",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#80008040]",
      color: "#800080",
      bt: "bg-[#800080] text-white",
    },
    close: {
      button: "Close",
      title: "License deletion",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };

  const handleSelect = (value) => {
    setAccount(value);
    setOpenModal(true);
  };

  const callDispatch = () => {
    // patchLicenseAction
    dispatch(
      patchLicenseAction({
        id: license?.id,
        status: account,
      })
    );
    setInitial(true);
    setIsLicenseStatusUpdated(true);
    setCurrentMember(currMember);
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn[account]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div key={license.id} className="border-b mb-4">
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn[account]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {`Are you sure you want to update this user's License status to ${account}?`}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn[account]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchLicenseStatusLoading
                ? "loading..."
                : statusTrn[account]?.button}
            </button>
          </div>
        </Box>
      </Modal>
      <h4 className="font-semibold mb-5 underline">License details</h4>
      <div className="flex flex-wrap gap-8">
        <div>
          <p className="text-[14px]">{license.sector}</p>
          <Select
            sx={statusStyles}
            value={account}
            defaultValue={license?.status}
            onChange={(e) => handleSelect(e.target.value)}
          >
            <MenuItem value={"verified"}>Verify </MenuItem>
            <MenuItem value={"unverified"}>Unverify</MenuItem>
            <MenuItem value={"suspended"}>Suspend</MenuItem>
            <MenuItem value={"recommended"}>Recommend</MenuItem>
            <MenuItem value={"close"}>Delete License</MenuItem>
          </Select>
        </div>
        <div>
          <p className="text-gray-400 text-[12px]">Expiry date from</p>
          <p className="text-[14px]">
            {moment(license.expiry_date_from).format("DD/MM/YYYY")}
          </p>
        </div>
        <div>
          <p className="text-gray-400 text-[12px]">Expiry date to</p>
          <p className="text-[14px]">
            {moment(license.expiry_date_to).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex">
          <div>
            <p className="text-[14px]">{license.sia_number}</p>
            <p className="text-gray-400 text-[10px]">SIA License Number </p>
          </div>
          <div
            onClick={() => handleCopyClick(license.sia_number)}
            className="pl-4 cursor-pointer"
          >
            {isCopied ? <span className="text-green-800">Copied!</span> : copy}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 my-10">
        {license?.trades?.map((trade, i) => {
          return (
            <div
              key={i}
              className="flex flex-row   justify-center	items-center py-[5px] px-[20px] text-[12px] rounded-[50px] border"
            >
              <p className="pr-4">{circle} </p>
              <span>{trade}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LicenseDetailsCard;
