import React from "react";
import benefit_1 from "../../../assets/images/how-it-works/benefit_1.jpg";
import benefit_2 from "../../../assets/images/how-it-works/benefit_2.jpg";
import benefit_4 from "../../../assets/images/how-it-works/benefit_4.jpg";
import benefit_5 from "../../../assets/images/how-it-works/benefit_5.jpg";
import benefit_6 from "../../../assets/images/how-it-works/benefit_6.webp";

import difference_1 from "../../../assets/images/how-it-works/difference_1.png";
import difference_2 from "../../../assets/images/how-it-works/difference_2.png";
import difference_3 from "../../../assets/images/how-it-works/difference_3.png";
import difference_4 from "../../../assets/images/how-it-works/difference_4.png";
import difference_5 from "../../../assets/images/how-it-works/difference_5.png";
import difference_6 from "../../../assets/images/how-it-works/difference_6.png";
import difference_7 from "../../../assets/images/how-it-works/difference_7.png";
import difference_8 from "../../../assets/images/how-it-works/difference_8.png";

import { motion } from "framer-motion";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};
const _viewport = { once: true };

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

export default function Benefits({ toggle }) {
  return (
    <>
      <div className="flex flex-col items-center  py-[50px] sm:py-[100px] ">
        <span className=" _h1_top">Features</span>

        <motion.h1
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className=" _h1"
        >
          Benefits & Perks
        </motion.h1>
        <motion.p
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="_p"
        >
          Enjoy a range of exclusive benefits and perks tailored to enhance your
          experience with iGuardu.
        </motion.p>

        <div className="flex items-center gap-y-[30px] sm:gap-y-[60px] justify-between w-full max-w-[1100px] flex-col lg:flex-row flex-wrap mt-[50px] sm:mt-[100px] ">
          <div className="flex flex-col max-w-[500px] ">
            <motion.img
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              src={toggle ? difference_5 : difference_1}
              alt="schoolbag"
              className="w-full mx-auto h-full object-contain max-w-[250px] max-h-[200px]"
            />
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="mx-auto  text-[20px] sm:text-[24px]  font-medium mb-[20px] text-center mt-[20px]"
            >
              {toggle ? "Comprehensive Talent Pool" : "Easy profile setup"}
            </motion.div>
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-center text-[16px] sm:text-[18px] font-thin"
            >
              {toggle
                ? "IGardu offers access to a diverse and highly skilled pool of security professionals, ensuring that clients can find the perfect fit for their specific needs."
                : "Set up your profile in no time to kickstart your journey of exploring diverse job opportunities tailored to your skills and preferences."}
            </motion.div>
          </div>

          <div className="flex flex-col max-w-[500px] ">
            <div>
              <motion.img
                initial={_inital}
                viewport={_viewport}
                whileInView={_whileInView}
                src={toggle ? difference_6 : difference_2}
                alt="schoolbag"
                className="w-full mx-auto h-full object-contain max-w-[250px] max-h-[200px]"
              />
            </div>
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="mx-auto  text-[20px] sm:text-[24px]  font-medium mb-[20px] text-center mt-[20px]"
            >
              {toggle ? "Advanced Matching Algorithms" : "Job alerts"}
            </motion.div>
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-center text-[16px] sm:text-[18px] font-thin"
            >
              {toggle
                ? "Leveraging advanced matching algorithms, IGardu delivers highly relevant and tailored candidate recommendations, saving clients time and effort in their search for the ideal candidate."
                : "Receive personalised job alerts tailored to match your unique preferences and requirements, ensuring that you stay informed about relevant job opportunities that align perfectly with your career."}
            </motion.div>
          </div>
        </div>

        <div className="flex items-center gap-y-[30px] sm:gap-y-[60px] justify-between w-full max-w-[1100px] flex-col lg:flex-row flex-wrap mt-[30px] sm:mt-[60px] ">
          <div className="flex flex-col  max-w-[500px] ">
            <motion.img
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              src={toggle ? difference_7 : difference_3}
              alt="schoolbag"
              className="w-full mx-auto h-full object-contain max-w-[250px] max-h-[200px]"
            />
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="mx-auto text-[20px] sm:text-[24px] font-medium mb-[20px] text-center mt-[20px]"
            >
              {toggle
                ? "Streamlined Recruitment Process"
                : "Instant job notifications"}
            </motion.div>
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-center text-[16px] sm:text-[18px] font-thin"
            >
              {toggle
                ? "Stay updated and informed with timely notifications about the latest job openings in your preferred field, ensuring you never miss out on any opportunity to advance your career."
                : "With intuitive features such as easy job posting, application management, and communication tools, IGardu streamlines the entire recruitment process."}{" "}
              Find new connections and keep track of who you've reached out to
            </motion.div>
          </div>
          <div className="flex flex-col max-w-[500px] ">
            <motion.img
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              src={toggle ? difference_8 : difference_4}
              alt="schoolbag"
              className="w-full mx-auto h-full object-contain max-w-[230px] max-h-[230px]"
            />
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="mx-auto   text-[20px] sm:text-[24px] font-medium mb-[20px] text-center mt-[20px]"
            >
              {toggle ? "Enhanced Security Measures" : "Networking Tracking"}
            </motion.div>
            <motion.div
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-center text-[16px] sm:text-[18px] font-thin"
            >
              {toggle
                ? "IGardu implements robust security measures to safeguard client data and ensure confidentiality throughout the recruitment process, offering peace of mind and trust in the platform's integrity."
                : "Monitor and manage your networking activities, keeping tabs on your connections and interactions within the platform to nurture and expand your professional network effectively."}
            </motion.div>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col items-center pb-[50px] sm:pb-[100px] ">
        <div className="flex gap-[20px] lg:gap-[40px] items-center flex-col-reverse lg:flex-row justify-between max-w-[1260px] mx-auto w-full mt-[70px] lg:mt-[100px]">
          <div className="mx-auto lg:mx-0 mt-0 lg:mt-[50px] flex flex-col items-center lg:items-start">
            <motion.h2
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[580px] text-[#272a3f] text-center lg:text-left font-medium text-[clamp(42px,calc(56/1340*100vw),56px)] leading-[1.3]  my-[10px]">
              Join with your club for exclusive opportunities
            </motion.h2>
            <motion.p
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[580px] text-[#3b4147] text-center lg:text-left font-[300] text-[clamp(16px,calc(20/1340*100vw),20px)] leading-[1.5] ">
              Join your club and gain access to exclusive features like job
              spotlights and alumni network access.
            </motion.p>
          </div>

          <motion.img
            initial={_inital}
            viewport={_viewport}
            whileInView={_whileInView}
            src={benefit_6}
            alt="steps_1"
            className="max-w-[600px] lg:max-w-[clamp(42px,calc(500/1340*100vw),500px)] w-full mx-auto lg:mx-0"
          />
        </div>
      </div> */}
    </>
  );
}
