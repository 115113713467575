import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import UploadDOcumentCard from "./UploadDocumentCard";
import { Link } from "react-router-dom";
import { FaFilePdf, FaFileImage } from "react-icons/fa";

function GuardDocumentForm({
  setFieldValue,
  values,
  data,
  confirmIdValid,
  setConfirmIdValid,
}) {
  const [selectedDocument, setSelectedDocument] = useState("license");
  const [selectedSide, setSelectedSide] = useState("front");
  const [licenseDocuments, setLicenseDocuments] = useState(values.documents);
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setConfirmIdValid(!isChecked);
  };

  const onSelectHandler = (e) => {
    setSelectedDocument(e.target.value);
    setSelectedSide("front"); // Reset selected side when changing document type
    setLicenseDocuments([]);
  };

  const handleUploadSuccess = (result) => {
    const updatedLicenseDocuments = [...licenseDocuments];
    if (selectedDocument === "passport") {
      updatedLicenseDocuments[0] = {
        doc_type: "passport",
        direction: "",
        url: result.secure_url,
        size: result.bytes,
      };
      setLicenseDocuments(updatedLicenseDocuments);
      setFieldValue("documents", updatedLicenseDocuments);
    } else if (selectedDocument === "license") {
      // const updatedLicenseDocuments = [...licenseDocuments];
      const indexToUpdate = selectedSide === "front" ? 0 : 1;
      updatedLicenseDocuments[0] = {
        doc_type: "license",
        direction: selectedSide,
        url: result.secure_url,
        size: result.bytes,
      };
      setLicenseDocuments(updatedLicenseDocuments);
      setFieldValue("documents", updatedLicenseDocuments);
    }
  };

  const getFileExtension = (filename) => {
    return filename?.slice(((filename?.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  return (
    <div className="w-full popins">
      <div className="w-full flex md:flex-row flex-col gap-5">
        <div className="md:w-1/3 w-full ">
          <Select
            variant="standard"
            sx={{
              border: "0px solid red",
              fontSize: "12px",
              width: "100%",
            }}
            value={selectedDocument}
            onChange={onSelectHandler}
          >
            <MenuItem
              value="license"
              sx={{
                fontSize: "12px",
              }}
            >
              License
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "12px",
              }}
              value="passport"
            >
              Passport
            </MenuItem>
          </Select>
        </div>
        {selectedDocument === "license" ? (
          <div className="flex w-5/6 justify-evenly text-xs">
            <button
              type="button"
              className={
                selectedSide === "front"
                  ? "text-[#000000] font-semibold"
                  : "text-[#999999]"
              }
              onClick={() => setSelectedSide("front")}
            >
              Upload Front Image
            </button>
            <button
              type="button"
              className={
                selectedSide === "back"
                  ? "text-[#000000] font-semibold"
                  : "text-[#999999]"
              }
              onClick={() => setSelectedSide("back")}
            >
              Upload Back Image
            </button>
          </div>
        ) : selectedDocument === "passport" ? (
          <div className="flex w-5/6 justify-evenly text-xs">
            <div className="text-[#000000]">Upload your passport</div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {data.documents.length > 0 && (
        <>
          <div className="mt-5">
            <span className="font-bold">Uploaded Documents</span>
          </div>
          <div className="mt-3 gap-2 uploaded-document-card__container">
            {data.documents.map((doc, index) => {
              return (
                <Link key={index} to={doc.url} target="_blank">
                  <div className="uploaded-document__card">
                    {getFileExtension(doc.document_url) === "pdf" ? (
                      <FaFilePdf size={17} color="#d92d20" />
                    ) : (
                      <FaFileImage size={17} color="#d92d20" />
                    )}
                    <span>
                      {doc.doc_type.toUpperCase()}{" "}
                      {doc.doc_type === "license" && "(" + doc.direction + ")"}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}

      <div className="flex justify-center align-center">
        {selectedDocument === "passport" && (
          <UploadDOcumentCard
            selectedDocument={"passport"}
            selectedSide={""}
            onSuccess={handleUploadSuccess}
            src={
              licenseDocuments?.length > 0
                ? licenseDocuments.filter(
                    (item) => item?.doc_type === "passport"
                  )[0]?.url
                : ""
            }
          />
        )}
        {selectedSide === "front" && selectedDocument === "license" && (
          <UploadDOcumentCard
            selectedDocument={"license"}
            selectedSide={"front"}
            src={
              licenseDocuments?.length > 0
                ? licenseDocuments.filter(
                    (item) =>
                      item?.doc_type === "license" &&
                      item?.direction === "front"
                  )[0]?.url
                : ""
            }
            onSuccess={handleUploadSuccess}
          />
        )}
        {selectedSide === "back" && selectedDocument === "license" && (
          <UploadDOcumentCard
            selectedDocument={"license"}
            src={
              licenseDocuments?.length > 0
                ? licenseDocuments.filter(
                    (item) =>
                      item?.doc_type === "license" && item?.direction === "back"
                  )[0]?.url
                : ""
            }
            selectedSide={"back"}
            onSuccess={handleUploadSuccess}
          />
        )}
      </div>
      <div className="w-full">
        {/* <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={confirmIdValid} />}
            label="I confirm Id is valid until the expiry date and is in color."
            sx={{
              fontSize: "10px",
            }}
            onChange={(e) => setConfirmIdValid(e.target.checked)}
          />
        </FormGroup> */}
        <div className="flex gap-2 items-center mb-2">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="w-5 h-5"
          />
          <label>
            I confirm Id is valid until the expiry date and is in color.
          </label>
        </div>
      </div>
    </div>
  );
}

export default GuardDocumentForm;
