import React, { useEffect, useState } from "react";
import Header from "./components/header";
import Goal from "./components/goal";
import { StepHeader } from "./components/steps_ recruiter";
import FeaturedCompanies from "./components/featuredCompanies";
import Benefits from "./components/benefits";

import StudentsReview from "./components/studentsReview";
import Navbar from "../../pages/main/homepage/components/navbar";
import EasyStep from "../../pages/main/homepage/components/easyStep";
import { useNavigate } from "react-router-dom";
import BecomeAPart from "../about-us/become-a-part.jsx";
import about_become_part_3 from "../../assets/images/about-us/about_become_part_3.webp";
import Footer from "../../pages/main/homepage/components/footer";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getRole, getToken } from "../../redux/auth/action.js";

const HowItWorks = ({ toggle }) => {
  const [login, setLogin] = useState(false);
  const [loginRole, setLoginRole] = useState("");
  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, []);

  useEffect(() => {
    if (token !== null && role !== null) {
      setLogin(true);
      setLoginRole(role);
    } else {
      setLogin(false);
      setLoginRole("");
    }
  }, [token, role]);
  // const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    if (toggle) {
      navigate("/how-it-works");
    } else {
      navigate("/how-it-works/recruiter");
    }
  };

  return (
    <>
      <div
        className={`w-full bg-gradient-to-r ${
          toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"
        }  px-[20px] pt-[20px] relative overflow-x-hidden`}
      >
        {/* <div
          className="absolute top-0 left-0 h-full w-full z-[1]"
          style={{
            background:
              "url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center",
          }}
        /> */}
        <Navbar transparent />

        <div className="max-w-[1340px] mx-auto relative z-[10] mt-[50px]">
          {/* <Nav toggle={toggle} /> */}
          <Header
            handleToggle={handleToggle}
            toggle={toggle}
            login={login}
            loginRole={loginRole}
          />
        </div>
      </div>
      <div className={`w-full  px-[20px] pt-[20px] `}>
        <div className="max-w-[1340px] mx-auto">
          <Goal toggle={toggle} />
        </div>
      </div>

      <div className="bg-[#F5FAFF] pb-[50px]">
        <EasyStep
          activeTab={toggle ? "clients" : "security"}
          hideToggle
          header={<StepHeader toggle={toggle} />}
        />
      </div>

      <div
        className={`w-full  px-[20px] pt-[20px] bg-gradient-to-r ${
          toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"
        }  relative`}
      >
        <div className="max-w-[1340px] mx-auto">
          <FeaturedCompanies toggle={toggle} />
        </div>
      </div>

      {/* {toggle ? (
        <>
          <div className={`w-full  px-[20px] pt-[20px]`}>
            <div className="max-w-[1340px] mx-auto">
              <Difference1 toggle={toggle} />
            </div>
          </div>

          <div className={`w-full  px-[20px] pt-[20px] bg-[#F5FAFF]`}>
            <div className="max-w-[1340px] mx-auto">
              <Difference2 toggle={toggle} />
            </div>
          </div>

          <div className={`w-full  px-[20px] pt-[20px]`}>
            <div className="max-w-[1340px] mx-auto">
              <Difference3 toggle={toggle} />
            </div>
          </div>

          <div className={`w-full  px-[20px] pt-[20px] bg-[#F5FAFF]`}>
            <div className="max-w-[1340px] mx-auto">
              <Difference4 toggle={toggle} />
            </div>
          </div>
        </>
      ) : (
        <div className={`w-full  px-[20px] pt-[20px]  bg-[#F5FAFF] `}>
          <div className="max-w-[1340px] mx-auto">
            <Benefits toggle={toggle} />
          </div>
        </div>
      )} */}
      <Benefits toggle={toggle} />

      <div
        className={`w-full  px-[20px] pt-[20px] bg-gradient-to-r ${
          toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"
        } relative`}
      >
        <div className="max-w-[1340px] mx-auto">
          <StudentsReview toggle={toggle} />
        </div>
      </div>

      <div className={`w-full  px-[20px] pt-[20px]`}>
        <div className="max-w-[1340px] mx-auto">
          {/* <Questions /> */}
          <BecomeAPart image={about_become_part_3} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowItWorks;
