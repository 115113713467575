import { all, fork, call, takeEvery, put, select } from "redux-saga/effects";

import { MESSAGE_DATA, SEND_MESSAGE_DATA } from "../actions";
import {
  getMessagesError,
  getMessagesSuccess,
  sendMessageError,
  sendMessageSuccess,
} from "./action";

const ApiLinks = process.env.REACT_APP_BASE_URL;

function* sendMessageCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(sendMessageAsync, paylaod, token);

    yield put(sendMessageSuccess(getApiRes));
  } catch (error) {
    yield put(sendMessageError());
  }
}

const sendMessageAsync = async (payload, token) => {
  fetch(
    `${ApiLinks}/${payload.payload.user_type}/jobs/${payload.payload.job_id}/discussion`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload.payload.data),
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchSendMessage() {
  yield takeEvery(SEND_MESSAGE_DATA, sendMessageCall);
}

function* getMessagesCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(getMessageDataAsync, paylaod, token);

    yield put(getMessagesSuccess(getApiRes));
  } catch (error) {
    yield put(getMessagesError());
  }
}

const getMessageDataAsync = async (payload, token) => {
  try {
    const res = await fetch(
      `${ApiLinks}/${payload.payload.user_type}/jobs/${payload.payload.job_id}/discussion?limit=${payload.payload.limit}&page=${payload.payload.page}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};

export function* watchMessageData() {
  yield takeEvery(MESSAGE_DATA, getMessagesCall);
}

export default function* rootSaga() {
  yield all([fork(watchMessageData), fork(watchSendMessage)]);
}
