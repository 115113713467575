import React from "react";
import "../../components/styles/Pagination.css";

const Pagination = ({
  currentPage,
  totalPages,
  handlePageChange,
  getPageNumbers,
}) => {
  return (
    <div className="pagination_container">
      {currentPage > 1 && (
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img
            height={12}
            width={12}
            src={"/previous.svg"}
            alt={"previous icon"}
          />
        </button>
      )}
      {getPageNumbers().map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageChange(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      ))}
      {currentPage < totalPages && (
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <img height={12} width={12} src={"/next.svg"} alt={"next icon"} />
        </button>
      )}
    </div>
  );
};

export default Pagination;
