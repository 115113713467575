import guardProfilePic from "../../../../assets/images/guardProfilePic.png";
import chatImage from "../../../../assets/images/chat.svg";
import { TiCameraOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileImageAction } from "../../../../redux/guard/action";

const ApiLinks = process.env.REACT_APP_BASE_URL;
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME; // replace with your own cloud name
const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET_NAME;

const mailToLink = `mailto:info@igardu.com`;

const PhotoImage = ({ data, setUploadImageLoading }) => {
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState("");
  const { updateProfileImage } = useSelector((state) => state.GuardSection);
  const { updateProfileImageLoading } = useSelector(
    (state) => state.GuardSection
  );

  useEffect(() => {
    if (updateProfileImage?.message) {
      notify(updateProfileImage.message);
    }
  }, [updateProfileImage]);

  useEffect(() => {
    setUploadImageLoading(updateProfileImageLoading);
  }, [updateProfileImageLoading]);

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  const uploadAvatar = async ({ event }) => {
    const files = event.target.files;
    try {
      if (files && files?.length > 0) {
        const _selectedFile = files[0];
        setProfileImage(URL.createObjectURL(_selectedFile));
        console.log(_selectedFile, "selected image");
        const fileSizeInMB = _selectedFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          alert("File size should be 2MB or less.");
          return;
        }

        const formData = new FormData();
        formData.append("file", _selectedFile);
        formData.append("upload_preset", uploadPreset);
        axios
          .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)
          .then((res) => {
            console.log(res, "image upload");
            const payload = {
              url: res.data.secure_url,
              name: "profile_pic",
            };
            dispatch(updateProfileImageAction(payload));
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      toast.error("Error updaing profile image");
    } finally {
    }
  };

  return (
    <div className="md:w-[25%] poppins">
      <center className="h-[262px] bg-white rounded-2xl flex items-center justify-center flex-col mb-6">
        <div className="text-sm	">Profile</div>
        <div className=" my-6 relative bg-[#E4E4E4] rounded-full">
          <img
            src={
              profileImage
                ? profileImage
                : data?.profile_image
                ? data?.profile_image
                : "/profile-image.webp"
            }
            alt="guard"
            width={95}
            height={95}
            className="rounded-full object-cover profile_image"
          />
          <label
            htmlFor="user_profile_avatar"
            className="bg-[#366CFE] rounded-full p-2.5 cursor-pointer absolute bottom-0 right-0 mb-0.5"
          >
            <TiCameraOutline className="text-white" />
          </label>
        </div>

        <input
          type="file"
          className=" hidden"
          id="user_profile_avatar"
          accept="image/*"
          onChange={(e) => {
            uploadAvatar({ event: e });
            e.target.value = null;
          }}
        />

        <div className="text-base	">
          {data?.first_name} {data?.last_name}
        </div>
      </center>
      <center className="h-[350px] bg-white rounded-2xl items-center flex-col justify-center px-10 md:flex hidden">
        <div>
          <img
            width={53}
            height={53}
            src={chatImage}
            alt=""
            className="w-full h-full"
          />
        </div>
        <div className="pt-6 text-base	">Need Help?</div>
        <div className="text-[10px] py-6 font-normal leading-4	text-[#242424]	">
          Have questions or concerns regarding your trastra account? Our experts
          are here to help!
        </div>
        <div className="flex">
          <Link
            to={mailToLink}
            className="px-3 py-3 button_style
     bg-[#145EEE]  rounded-[5px] text-white text-[14px] font-semibold  duration-300  "
          >
            <button>Send email us</button>
          </Link>
        </div>
      </center>
    </div>
  );
};

export default PhotoImage;
