import ProcessTracker from "./components/ProcessTracker";
import SelectJobType from "./components/ProcessTwo";
import JobTitleDesc from "./components/ProcessThree";
import React, { useEffect, useState } from "react";
import HowManyNeed from "./components/HowManyNeed";
import PlannedDate from "./components/PlannedDate";
import SelectBudget from "./components/SelectBudget";
import ProcessFive from "./components/ProcessFive";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { postJobAction, postJobTokenAction } from "../../redux/client/action";
import { useNavigate } from "react-router-dom";
import { getRole, getToken } from "../../redux/auth/action";
import ProgressTwo from "../../pages/auth/trader-signup/components/ProgressTwo";
import { Backdrop, CircularProgress } from "@mui/material";

const PostAJob = () => {
  const [login, setLogin] = useState(false);
  const dispatch = useDispatch();
  const router = useNavigate();
  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);
  const { postJob } = useSelector((state) => state.ClientSection);
  const { postJobTokenLoading } = useSelector((state) => state.ClientSection);
  const { postJobToken } = useSelector((state) => state.ClientSection);
  const { postJobError } = useSelector((state) => state.ClientSection);
  const { postJobLoading } = useSelector((state) => state.ClientSection);
  const [stepper, setstepper] = useState(0);
  const [jobTitle, setJobTitle] = useState("");
  const [curr, setCurr] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [value, setValue] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [postJobSuccess, setPostJobSuccess] = useState(false);

  useEffect(() => {
    if (token !== null && role !== null) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [token, role]);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, [dispatch]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (token !== undefined && role !== undefined) {
        if (token === null) {
          setLoggedIn(false);
        } else if (role !== "client") {
          warn(`You can't post a job logged in as a ${role}`);
          router("/");
        } else if (role === "client") {
          setLoggedIn(true);
        }
      }
    }
  }, [token, role, dispatch, router]);

  useEffect(() => {
    if (postJob?.success) {
      notify(postJob.message);
      setPostJobSuccess(true);
      // router("/");
    } else if (postJob?.success === false) {
      notifyfail(postJob.message);
    }
  }, [postJob]);
  useEffect(() => {
    if (postJobToken?.success) {
      console.log(postJobToken, "postJobToken");
      notify(postJobToken.message);
      // router("/");
      setPostJobSuccess(true);
    }
  }, [postJobToken]);

  const initialValues = {
    lookingFor: ["", ""],
    jobTitle: "",
    jobDescription: "",
    quantity: 1,
    startDateTime: [
      {
        date: null,
        fromTime: null,
        toime: null,
      },
    ],
    location: {
      name: "",
      postcode: "000000",
      coordinates: [""],
    },
    proximity: "5",
    minBudget: 0,
    maxBudget: 11,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsConditions: false,
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    phone: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email address"),
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "* Passwords must match")
      .required("This field is required"),
    termsConditions: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const setAddrVal = (val) => {
    setSelectedAddress(val);
  };
  const onSubmit = (val) => {
    if (loggedIn) {
      const payload = {
        lookingFor: val.lookingFor,
        jobTitle: val.jobTitle,
        jobDescription: val.jobDescription,
        quantity: val.quantity,
        startDateTime:
          value === "yes"
            ? val.startDateTime.map((item) => ({
                date: item.date,
                fromTime: item.fromTime,
                toTime: item.toTime,
              }))
            : [],
        location: {
          address: val.location.name,
          lat: val.location.coordinates[0],
          lng: val.location.coordinates[1],
        },
        // proximity: `${val.proximity}m`,
        // minBudget: val.minBudget,
        maxBudget: val.maxBudget,
      };
      dispatch(postJobTokenAction(payload));
    } else {
      const payload = {
        lookingFor: val.lookingFor,
        jobTitle: val.jobTitle,
        jobDescription: val.jobDescription,
        quantity: val.quantity,
        startDateTime:
          value === "yes"
            ? val.startDateTime.map((item) => ({
                date: item.date,
                fromTime: item.fromTime,
                toTime: item.toTime,
              }))
            : [],
        location: {
          address: val.location.name,
          lat: val.location.coordinates[0],
          lng: val.location.coordinates[1],
        },
        // proximity: `${val.proximity}m`,
        // minBudget: val.minBudget,
        maxBudget: val.maxBudget,
        first_name: val.first_name,
        last_name: val.last_name,
        phone: val.phone.toString(),
        email: val.email,
        password: val.password,
      };
      dispatch(postJobAction(payload));
    }
  };

  const handleBack = () => {
    setstepper(stepper - 1);
  };
  const handleNext = (values, errors) => {
    if (stepper === 0) {
      if (values?.lookingFor[0] === "") {
        notifyfail("Fields are required");
      } else if (values?.lookingFor[1] === "") {
        notifyfail("looking for  fields are required");
      } else {
        setstepper(1);
      }
    } else if (stepper === 1) {
      if (values?.jobTitle === "") {
        notifyfail("Job title fields are required");
      } else if (values?.jobDescription === "") {
        notifyfail("Job description fields are required");
      } else {
        setstepper(2);
      }
    } else if (stepper === 3) {
      if (values?.startDateTime?.length === 0 && value === "yes") {
        notifyfail("Date and time are required");
      } else if (values?.startDateTime.length > 0 && value === "yes") {
        values?.startDateTime?.map((item) => {
          if (item?.date === null) {
            notifyfail("Start Date fields are required");
          } else if (item?.fromTime === null) {
            notifyfail("Start from time fields are required");
          } else if (item?.toTime === null) {
            notifyfail("start to time fields are required");
          } else {
            setstepper(4);
          }
        });
      }
    } else if (stepper === 4) {
      if (values?.location?.name === "") {
        notifyfail("Location fields are required");
      } else {
        setstepper(5);
      }
    } else if (stepper === 5) {
      if (values?.maxBudget < 11) {
        notifyfail("The min budget is 11£");
      } else {
        if (!loggedIn) {
          setstepper(6);
        }
      }
    } else {
      setstepper(stepper + 1);
    }
  };

  const notify = (value) => {
    toast.success(value);
  };

  const warn = (value) => {
    toast.custom(
      <div
        style={{
          border: "1px solid red",
          color: "red",
          background: "#fff",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        ⚠️ {value} ⚠️
      </div>
    );
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  return (
    <div className="pb-32">
      <div className="w-full bg-primary p-10">
        <h1 className="w-full my-2 text-white text-3xl max-md:text-xl font-bold text-center">
          Post a job
        </h1>
        <p className="w-full text-white text-center">
          Select the most appropriate category for your security needs. <br />
          You may select more than one if necessary.
        </p>
      </div>
      {postJobSuccess ? (
        <div className="w-3/12 mx-auto my-[60px] text-center">
          <p className="text-[32px] text-primary">Job posted successfully.</p>
          {login ? (
            <div>
              <p>Job has been posted successfully. </p>
              <br />
              <p>Visit dashboard to view your jobs. </p>
            </div>
          ) : (
            <div>
              <p>A verification link has been sent to your email. </p>
              <p>Pls verify and login to continue. </p>
            </div>
          )}

          <button
            className="bg-primary w-[90px] px-3 py-2 rounded-[20px] mt-[50px] text-sm text-white"
            onClick={() => {
              router("/");
              dispatch(postJobTokenAction([]));
            }}
          >
            {"< Back"}
          </button>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={loggedIn ? null : validationSchema}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              {stepper === 0 && (
                <SelectJobType
                  setActiveStep={handleNext}
                  jobTitle={jobTitle}
                  setJobTitle={setJobTitle}
                  curr={curr}
                  setCurr={setCurr}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
              {stepper === 1 && <JobTitleDesc jobTitle={jobTitle} />}
              {stepper === 2 && (
                <HowManyNeed setFieldValue={setFieldValue} values={values} />
              )}
              {stepper === 3 && (
                <PlannedDate
                  setActiveStep={setstepper}
                  setFieldValue={setFieldValue}
                  values={values}
                  setValue={setValue}
                  value={value}
                />
              )}
              {stepper === 4 && (
                <ProgressTwo
                  setAddress={setAddrVal}
                  setFieldValue={setFieldValue}
                  setActiveStep={setstepper}
                  values={values}
                  text="Your location for the job"
                />
              )}
              {/* {stepper === 5 && (
              <SelectRadius
                val={selectedAddress}
                setFieldValue={setFieldValue}
                values={values}
              />
            )} */}
              {stepper === 5 && <SelectBudget />}
              {loggedIn ? null : stepper === 6 && <ProcessFive />}
              <ProcessTracker
                handleBack={handleBack}
                handleNext={handleNext}
                progress={loggedIn ? (stepper / 5) * 100 : (stepper / 6) * 100}
                values={values}
                postJobTokenLoading={postJobTokenLoading || postJobLoading}
                errors={errors}
                lastText="Post A Job"
              />
            </Form>
          )}
        </Formik>
      )}
      {postJobTokenLoading || postJobLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={postJobTokenLoading || postJobLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </div>
  );
};

export default PostAJob;
