import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import company_1 from "../../../assets/images/how-it-works/company_1.svg";
import company_2 from "../../../assets/images/how-it-works/company_2.svg";
import company_3 from "../../../assets/images/how-it-works/company_3.svg";
import company_4 from "../../../assets/images/how-it-works/company_4.svg";
import company_5 from "../../../assets/images/how-it-works/company_5.svg";
import company_6 from "../../../assets/images/how-it-works/company_6.svg";
import company_7 from "../../../assets/images/how-it-works/company_7.svg";
import company_8 from "../../../assets/images/how-it-works/company_8.svg";
import company_9 from "../../../assets/images/how-it-works/company_9.svg";
import company_10 from "../../../assets/images/how-it-works/company_10.svg";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};
const _viewport = { once: true };

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const schoolData = [
  {
    image: company_1,
  },
  {
    image: company_2,
  },
  {
    image: company_3,
  },
  {
    image: company_4,
  },
  {
    image: company_5,
  },
  {
    image: company_6,
  },
  {
    image: company_7,
  },
  {
    image: company_8,
  },
  {
    image: company_9,
  },
  {
    image: company_10,
  },
];

const FeaturedCompanies = ({ toggle }) => {
  return (
    <div className="py-[50px] sm:py-[100px]">
      <div className="">
        <motion.div
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="lg:text-7xl text-2xl mb-20 text-center text-[#dbe1e8]">
          Featured Companies
        </motion.div>
        <div className="flex flex-wrap gap-[30px] items-center justify-center">
          {schoolData.map((item, index) => (
            <motion.img
              initial={_inital}
              viewport={_viewport}
              whileInView={{
                y: 1,
                opacity: 1,
                scale: 1,
                transition: { duration: 0.2 * index },
              }}
              key={index}
              src={item.image}
              alt=""
              className="h-[70px] w-[190px] object-contain"
            />
          ))}
          <div className="text-[#ffffff80] h-[70px] w-[190px]">+ many more</div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCompanies;
