import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getRole, getToken } from "../../../../redux/auth/action";
import logo from "../../../../assets/images/iguardublue.svg";
import Dropdown from "../../../../components/dropdown/dropdown";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Accordion from "../../../../components/accordion/accordion";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { GrDocumentUser } from "react-icons/gr";
import { IoExitOutline } from "react-icons/io5";
import logo_white from "../../../../assets/images/how-it-works/logo.png";
import { Box, Button, Modal } from "@mui/material";
import { subscriptionPaymentAction } from "../../../../redux/client/action";
import { getProfileAction } from "../../../../redux/profile/action";
import { loadStripe } from "@stripe/stripe-js";

function Navbar({ user, transparent = false }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginRole, setLoginRole] = useState("");
  const dispatch = useDispatch();
  const { getProfile } = useSelector((state) => state.ProfileSection);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState(null);
  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);

  useEffect(() => {
    if (getProfile?.success) {
      setClientData(getProfile?.data);
    }
  }, [getProfile]);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
    if (token) {
      dispatch(getProfileAction(token));
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && role) {
      setLogin(true);
      setLoginRole(role);
    } else {
      setLogin(false);
      setLoginRole("");
    }
  }, [token, role]);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to handle scroll event
    function handleScroll() {
      // Update the state with the current scroll position
      setScrollY(window.scrollY);
    }

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const is_transparent = transparent && scrollY < 63 ? true : false;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubscribe = async () => {
    const stripe = await loadStripe(
      "pk_test_51ObL8pSFaFJFGBYemzQX9kD0M6ijJfb9nVHwxG7gm9Kowbzez4kYrAAebbpDnOy3sV1C8UIdxB9tNMU2oWFivSqw00lmTkp78b"
    );

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/client/payments/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });

    if (result.error) {
      console.log(result.error);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const checkIfSubscribed = async () => {
    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/client/jobs/count-jobs`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    setLoading(false);
    if (data?.success) {
      navigate("/post-a-job");
    } else {
      setOpen(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: is_transparent ? "#ffffff00" : "#fff",
      }}
      className="  py-2 fixed top-0 left-0 z-[999] md:py-4 w-full px-3  mid-xl:px-[120px] mx-auto "
    >
      <div className="flex items-center flex-row justify-between md:justify-around max-w-[1340px] mx-auto">
        <div>
          <Link to="/">
            <img
              width={240}
              height={80}
              src={is_transparent ? logo_white : logo}
              className="w-[80px] md:w-[109px] "
              alt=""
            />
          </Link>
        </div>

        {/* desktop-navigation */}

        <div className=" hidden md:block ml-auto items-center justify-center ">
          <nav className="gap-6 flex flex-row items-center">
          <Dropdown
              name={"About Us"}
              options={residentDropdown}
              background={is_transparent ? "#ffffff" : undefined}
            />
            {loginRole !== "guard" ? (
              <button
                className={`${
                  is_transparent ? "border-white bg-white " : "border-gray-300 "
                } hover:border-primary hover:bg-primary hover:text-white border-2 gap-5 flex justify-between rounded-full px-4 py-1 font-[600] text-[16px]`}
                // onClick={() =>
                //   token ? checkIfSubscribed() : navigate("post-a-job")
                // }
                onClick={() => navigate("post-a-job")}
                style={{ backgroundColor: loading && "#145deb" }}
              >
                {loading ? (
                  <img src={"/loading.svg"} alt="loading" className="w-6 h-6" />
                ) : (
                  <p>Register as Client and Post a job</p>
                )}
              </button>
            ) : null}

            {/* {!user ? (
            <> */}
            
            {loginRole !== "client" ? (
              <Dropdown
                name={"Guards"}
                background={is_transparent ? "#ffffff" : undefined}
                options={
                  login ? tradeMembersDropdownPostLogin : tradeMembersDropdown
                }
              />
            ) : (
              ""
            )}
            {/* </>
          ) : (
            <>
              <Dropdown label={"Quotes"} options={residentDropdown} />

              <Dropdown label={"Profile"} options={profileDropdown} />
            </>
          )} */}
          </nav>
        </div>

        <div className="hidden md:block">
          <div className=" flex flex-row gap-3 ">
            {login ? (
              <Link
                to={
                  loginRole !== "admin"
                    ? `/${loginRole}/dashboard`
                    : "/dashboard"
                }
              >
                <button
                  className={`px-4 md:px-6 lg:px-8 py-1 md:py-2 lg:py-2  
 lg:ml-[10px] mid-xl:ml-[0px] bg-primary rounded-full
  text-white text-[15px] font-semibold border-[1px] ${
    is_transparent ? "border-primary" : "border-white "
  } duration-300 `}
                >
                  Dashboard
                </button>
              </Link>
            ) : (
              <Link to="/sign-in">
                <button
                  className={`px-4 md:px-6 lg:px-8 py-1 md:py-2 lg:py-2  
lg:ml-[10px] mid-xl:ml-[0px] bg-primary rounded-full
text-white text-[15px] font-semibold border-[1px] ${
                    is_transparent ? "border-primary" : "border-white "
                  }  duration-300 `}
                >
                  Login
                </button>
              </Link>
            )}
          </div>
        </div>

        {/* mobile toogle button */}

        <div className="block md:hidden">
          <button
            onClick={toggleMenu}
            className={`lg:hidden  text-black text-lg transform p-[3px] border-[1px] border-none ${
              menuOpen ? "rotate-90" : ""
            } transition-transform`}
          >
            {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}{" "}
            {/* Use icons */}
          </button>
        </div>
      </div>

      {/* mobile menu */}
      <div
        className={`lg:hidden fixed top-0 left-0 h-screen w-5/6 bg-gray-800 text-white transition-transform transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } navbar`}
      >
        <nav className="mt-16 p-4 flex flex-col gap-6">
          {loginRole !== "guard" ? (
            <button
              className="text-white hover:text-primary font-medium text-sm flex items-start"
              // onClick={() =>
              //   token ? checkIfSubscribed() : navigate("post-a-job")
              // }
              onClick={() => navigate("post-a-job")}
            >
              Register as Client and Post a job
            </button>
          ) : (
            // <Link href="/" className="text-white hover:text-primary">
            //   Leads
            // </Link>
            <></>
          )}

          {!user ? (
            <>
              <Accordion name="About Us" options={residentDropdown} />
              {loginRole !== "client" && (
                <Accordion
                  name="Guards"
                  options={tradeMembersDropdown}
                />
              )}
            </>
          ) : (
            <>
              <Accordion name="Quotes" options={residentDropdown} />
              <Accordion name="Profile" options={profileDropdown} />
            </>
          )}

          <div className=" flex flex-row gap-3 w-full">
            {login ? (
              <Link
                to={
                  loginRole !== "admin"
                    ? `/${loginRole}/dashboard`
                    : "/dashboard"
                }
                className="w-full"
              >
                <button
                  className={`px-4  py-1  bg-white rounded-full
  text-primary text-[15px] font-semibold border-[1px] border-white w-full `}
                >
                  Dashboard
                </button>
              </Link>
            ) : (
              <Link to="/sign-in" className="w-full">
                <button
                  className={`px-4  py-1  bg-white rounded-full
  text-primary text-[15px] font-semibold border-[1px] border-white w-full `}
                >
                  Login
                </button>
              </Link>
            )}
          </div>
        </nav>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <div className="flex flex-col items-center">
            <p className="text-[25px] font-semibold">Need to subscribe!</p>
            <p className="my-5 text-center">
              {" "}
              You have exceeded the maximum job posting limit. To post more you
              need to subscribe. Would you like to continue?
            </p>
            <div className="flex gap-5">
              <Button variant="contained" onClick={handleSubscribe}>
                Yes
              </Button>
              <Button variant="outlined" onClick={handleClose}>
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Navbar;

const residentDropdown = [
  {
    id: "545hhh",
    name: "How it works",
    link: "/how-it-works",
    icon: <FaRegCircleQuestion />,
  },
  {
    id: "642fty",
    name: "Find trades",
    link: "/",
    icon: <FaSearch />,
  },
  {
    id: "579hofh",
    name: "About us",
    link: "/about-us",
    icon: <GrDocumentUser />,
  },
];

const tradeMembersDropdown = [
  {
    id: "tsig754",
    name: "Guard sign up",
    link: "/trader-signup",
    icon: <IoExitOutline />,
  },
  {
    id: "545hhh",
    name: "How it works",
    link: "/how-it-works",
    icon: <FaRegCircleQuestion />,
  },
];

const tradeMembersDropdownPostLogin = [
  {
    id: "545hhh",
    name: "How it works",
    link: "/how-it-works",
    icon: <FaRegCircleQuestion />,
  },
];

const profileDropdown = [
  {
    id: "545hhh",
    name: "My Profile",
    link: "/",
    // icon: <MyProfileIcon />
  },
  {
    id: "642fty",
    name: "Trades",
    link: "/",
    // icon: <TradesIcon />
  },
  {
    id: "579hofh",
    name: "Working Area",
    link: "/",
    // icon: <WorkingAreaIcon />
  },
  {
    id: "5449hTfh",
    name: "References",
    link: "/",
    // icon: <ReferencesIcon />
  },
];
