import { Field } from "formik";
import React from "react";

const ProgressSeven = () => {
  return (
    <div className="w-full flex justify-center flex-wrap pt-8 pb-[200px]">
      <h1 className="text-4xl nekst w-full text-center font-medium">
        Introduce yourself to customers
      </h1>
      <p className="w-7/12 mx-auto text-center text-gray-400 my-4">
        Introduce your business by describing the work you normally undertake
        and what makesyou stand our from other tradespoeple. You can always edit
        this later
      </p>

      <Field
        as="textarea"
        placeholder="Include any details you think the trade person should know"
        name="description"
        id=""
        cols="30"
        rows="10"
        className="w-7/12 mx-auto rounded-xl border border-gray-400 focus:outline-primary p-3 resize-none text-gray-600 mt-16"
      ></Field>
    </div>
  );
};

export default ProgressSeven;
