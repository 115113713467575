import React, { useState } from "react";
import { Rating } from "@mui/material";
import ReviewCard from "./ReviewCard";

const ReviewSection = ({ currMember }) => {
  // const [ratingDetails, setRatingDetails] = useState([])

  const ratingDetailFn = (data) => {
    const totalRatings = parseInt(data?.number_of_ratings);
    const fiveStar = parseInt(data?.five_star);
    const fourStar = parseInt(data?.four_star);
    const threeStar = parseInt(data?.three_star);
    const twoStar = parseInt(data?.two_star);
    const oneStar = parseInt(data?.one_star);
    const maxMeterValue = 100;

    const ratingDetails = [
      {
        star: 5,
        RatingPercentage: fiveStar, //Math.round(((fiveStar / totalRatings) * 100).toFixed(2)) || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, fiveStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 4,
        RatingPercentage: fourStar, // Math.round(((fourStar / totalRatings) * 100).toFixed(2) ) || 0,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, fourStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 3,
        RatingPercentage: threeStar, // (Math.round(((threeStar / totalRatings) * 100).toFixed(2) ) || 0 ),
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, threeStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 2,
        RatingPercentage: twoStar, //Math.round(((twoStar / totalRatings) * 100).toFixed(2) ) || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, twoStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 1,
        RatingPercentage: oneStar, //Math.round(((oneStar / totalRatings) * 100).toFixed(2))  || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, oneStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
    ];

    return ratingDetails;
  };
  const ratingDetails = ratingDetailFn(currMember?.star_average);

  return (
    <div className="border-b py-10 w-full">
      <div className="flex md:flex-row flex-col gap-y-2 justify-between mt-12">
        <div className="md:w-1/5 w-full rounded-lg border py-6 border-[#CBDDFF] gap-2 flex flex-col justify-center items-center">
          <p className="text-5xl text-primary">
            {parseFloat(currMember?.average_rating || 0).toFixed(1)}
          </p>
          <Rating
            readOnly
            defaultValue={parseFloat(currMember?.average_rating).toFixed(1)}
          />
          <p className="font-semibold">Average Ratings</p>
          <p className="text-gray-400">
            {"("} {currMember?.reviews?.length} {" Reviews)"}
          </p>
        </div>

        <div className=" w-[80%] max-[1024px]:w-full h-[219px] max-[724px]:h-full  bg-white  p-6  rounded-2xl px-12">
          {ratingDetails &&
            ratingDetails?.map((rd, i) => {
              return (
                <div
                  key={i}
                  className="flex  w-full pb-3 justify-between max-[724px]:flex-col"
                >
                  <div className="md:w-[65%] ">
                    <meter
                      value={rd.ratingMeterValue}
                      min="1"
                      max="100"
                      className="w-full"
                    ></meter>
                  </div>
                  <div className="md:w-[30%] flex  md:justify-start  justify-between">
                    <span className="flex justify-start ">
                      <Rating
                        precision={0.5}
                        value={parseFloat(rd.star).toFixed(1)}
                        readOnly
                      />
                    </span>
                    <span className=" pl-4 flex md:justify-start text-[#366CFE] text-sm">
                      {rd.RatingPercentage}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full justify-between">
        {currMember?.reviews?.map((v, i) => (
          <ReviewCard key={i} {...v} />
        ))}
      </div>
    </div>
  );
};

export default ReviewSection;
