import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import JobDetails from "../../jobComponents/jobDetails";
import JobFilter from "../../jobComponents/jobFilter";
import ListLoader from "../../loader/listLoader";
import { useDispatch, useSelector } from "react-redux";
import { getClientJobsAction } from "../../../redux/client/action";
import { useLocation, useNavigate } from "react-router-dom";
import JobListCard from "../../jobComponents/jobListCard";

function ClientJob() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getClientJobs } = useSelector((state) => state.ClientSection);
  const { getClientJobsLoading, getFindTradersLoading } = useSelector(
    (state) => state.ClientSection
  );
  const [currJob, setCurrJob] = useState(null);
  const [data, setData] = useState([]);
  // const [initial, setInitial] = useState(true);

  useEffect(() => {
    const status = searchParams.get("status");
    dispatch(
      getClientJobsAction({
        action: status ? "status" : "",
        value: status ? status : null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (getClientJobs?.success) {
      // console.log(getClientJobs?.data, "jobs filtered");
      setData(getClientJobs?.data);
    }
  }, [getClientJobs]);

  const filterAction = (action, filter) => {
    dispatch(getClientJobsAction({ action, value: filter }));
  };

  useEffect(() => {
    const status = searchParams.get("status");

    if (status) {
      filterAction("status", status);
    }
  }, []);

  const declinedHandler = (value) => {
    console.log(value);
  };

  return (
    <div className="bg-[#f4f5f7] w-full px-1 md:px-4 lg:px-4 pt-5 poppins ">
      <div className="md:p-3 p-1 md:px-10">
        <Drawer
          anchor={"right"}
          sx={{
            width: "70%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "70%",
              boxSizing: "border-box",
            },
            "@media (max-width: 768px)": {
              width: "100%",
              "& .MuiDrawer-paper": {
                width: "100%",
              },
            },
          }}
          open={currJob !== null}
          onClose={() => setCurrJob(null)}
          className="dashboard"
        >
          <JobDetails
            setCurrJob={setCurrJob}
            data={currJob}
            declinedHandler={declinedHandler}
          />
        </Drawer>

        <div className="">
          <JobFilter filterAction={filterAction} />
        </div>

        <div className="pt-7 mb-3 md:px-4 px-2 flex flex-col gap-4 pb-2 bg-white rounded-[10px] poppins">
          <div className="">
            <div className="pt-3 mb-3 md:px-4 flex  flex-col gap-4 pb-2 bg-white rounded-b-[10px]">
              {getClientJobsLoading ? (
                <div className="w-full flex justify-center">
                  {" "}
                  <ListLoader />{" "}
                </div>
              ) : data?.length ? (
                data?.map((item, i) => (
                  <JobListCard
                    key={i}
                    item={item}
                    viewJob={setCurrJob}
                    jobDetails={(item) =>
                      navigate(`/client/my-jobs/details/${item.id}`)
                    }
                    getFindTradersLoading={getFindTradersLoading}
                  />
                ))
              ) : (
                <NothingHere />
              )}
            </div>
          </div>
          {/* <div className="flex items-end justify-end pb-4">
          <Pagination count={10} color="primary" />
        </div> */}
        </div>
      </div>
    </div>
  );
}

export default ClientJob;

const NothingHere = () => (
  <div className="text-[20px] font-bold  leading-[24px] text-[#AAAA] mr-2 text-center	">
    No data yet.
  </div>
);
