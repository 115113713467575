// "google-maps-react": "^2.0.6",

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/main/homepage/homepage";
import { Toaster } from "react-hot-toast";
import PostAJob from "./components/post-a-job/post-a-job";
import { ProtectedRoute } from "./components/auth/protectedRoute";
import ClientDashboard from "./components/clientSection/clientDashboard";
import ClientJob from "./components/clientSection/my-job/my-job";
import ClientJobDetails from "./components/clientSection/my-job/jobDetails/ClientJobDetails";
import ClientProfile from "./components/clientSection/profile/clientProfile";
import ClientReviews from "./components/clientSection/reviews/clientReviews";
import AddReview from "./components/clientSection/reviews/add/addReview";
import GuardDashboard from "./components/guardSection/dashboard/guardDashboard";
import GuardJobs from "./components/guardSection/my-jobs/guardJobs";
import GuardProfile from "./components/guardSection/profile/profile";
import GuardTradesComponent from "./components/guardSection/trades/tradesComponent";
import AddTrade from "./components/guardSection/trades/addTrade/addTrade";
import GuardLicense from "./components/guardSection/license/guardLicense";
import GuardAddLicense from "./components/guardSection/license/addLicense/addLicense";
import GuardReviews from "./components/guardSection/reviews/guardReviews";
import Dashboard from "./components/admin/dashboard/dashboard";
import TradeMembers from "./components/admin/trade-members/tradeMembers";
import Users from "./components/admin/users/users";
import Jobs from "./components/admin/jobs/jobs";
import Reviews from "./components/admin/review/reviews";

import SignIn from "./pages/auth/sign-in/sign-in";
import ForgetPassword from "./pages/auth/forgetPassword/forgetPassword";
import ResetPassword from "./pages/auth/resetPassword/resetPassword";
import VerifyLink from "./pages/auth/verifyLink";
import TraderSignupPage from "./pages/auth/trader-signup/trader-signup";
import NotFoundPage from "./pages/auth/notFound/not-found";

import HowItWorks from "./components/how-it-works/how-it-works";
import AboutUs from "./components/about-us/about-us";
import Initializers from "./components/Initializers/Initializers";
import TermsAndCondition from "./components/terms-and-conditions/terms-and-conditions";
import CookiesPolicy from "./components/cookies-policy/cookies-policy";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
import AdminProfile from "./components/admin/profile/profile";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import PaymentCancel from "./components/payment/PaymentCancel";
import Checkout from "./components/payment/Checkout";
import ClientTransactions from "./components/clientSection/transactions/clientTransactions";
import LoginDetails from "./components/admin/login-details/loginDetails";
import useActivityTracker from "./components/custom -hooks/useActivityTracker";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PromotedJobs from "./components/admin/promoted-jobs/promotedJobs";
import SubAdmins from "./components/admin/sub-admins/subAdmins";
import SubAdminDashboard from "./components/sub-admin/dasboard/SubAdminDashboard";
import SubAdminProfile from "./components/sub-admin/profile/profile";
import SubAdminJobs from "./components/sub-admin/jobs/jobs";
import SubAdminTradeMembers from "./components/sub-admin/trade-members/tradeMembers";
import SubAdminLoginDetails from "./components/sub-admin/login-details/loginDetails";
import SubAdminUsers from "./components/sub-admin/users/users";
import SubAdminPromotedJobs from "./components/sub-admin/promoted-jobs/promotedJobs";
import SubAdminReviews from "./components/sub-admin/reviews/reviews";
import Chat from "./components/admin/chats";
import PersonalChat from "./components/admin/personal-chats";
import PersonalChatClient from "./components/clientSection/personal-chats";
import PersonalChatGuard from "./components/guardSection/personal-chats";

function App() {
  // const { token, role } = useSelector((state) => state.Authsection);
  // const isActive = useActivityTracker(token);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/${role}/heartbeat`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "content-type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       console.log(data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   if (isActive && role != "admin") {
  //     fetchData();
  //   }
  // }, [isActive]);

  return (
    <>
      <Router>
        {/* Scroll To Top when Route Changes */}
        {/* The routing is working fine but when I am in the middle of the page and click on any link in my navbar or any other link to route to another page the next page opens from the center and not from the top. */}
        {/* Hence I added this component to resove the issue */}
        <Initializers />

        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route path="/post-a-job" element={<PostAJob />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/trader-signup" element={<TraderSignupPage />} />
          <Route path="/verify" element={<VerifyLink />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route
            path="/how-it-works/recruiter"
            element={<HowItWorks toggle />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancel />} />

          <Route element={<ProtectedRoute role="client" />}>
            <Route path="/client/dashboard" element={<ClientDashboard />} />
            <Route path="/client/my-jobs" element={<ClientJob />} />
            <Route
              path="/client/my-jobs/details/:id"
              element={<ClientJobDetails />}
            />
            <Route path="/client/my-profile" element={<ClientProfile />} />
            <Route
              path="/client/my-transactions"
              element={<ClientTransactions />}
            />
            <Route path="/client/reviews" element={<ClientReviews />} />
            <Route
              path="/client/reviews/add/:guard_id/:id"
              element={<AddReview />}
            />
            <Route
              path="/client/personal-chats"
              element={<PersonalChatClient />}
            />
          </Route>

          <Route element={<ProtectedRoute role="guard" />}>
            <Route path="/guard/dashboard" element={<GuardDashboard />} />
            <Route path="/guard/job-search" element={<GuardJobs />} />
            <Route path="/guard/my-profile" element={<GuardProfile />} />
            <Route path="/guard/my-trades" element={<GuardTradesComponent />} />
            <Route
              path="/guard/my-trades/add-trades/:id"
              element={<AddTrade />}
            />
            <Route path="/guard/my-licenses" element={<GuardLicense />} />
            <Route
              path="/guard/my-licenses/add-license"
              element={<GuardAddLicense />}
            />
            <Route path="/guard/reviews" element={<GuardReviews />} />
            <Route
              path="/guard/personal-chats"
              element={<PersonalChatGuard />}
            />
          </Route>

          <Route element={<ProtectedRoute role="admin" />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<AdminProfile />} />
            <Route path="/sub-admins" element={<SubAdmins />} />
            <Route path="/trade-members" element={<TradeMembers />} />
            <Route path="/users" element={<Users />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/promoted-jobs" element={<PromotedJobs />} />
            <Route path="/login-details" element={<LoginDetails />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/chats" element={<Chat />} />
            <Route path="/personal-chats" element={<PersonalChat />} />
          </Route>

          <Route element={<ProtectedRoute role="subadmin" />}>
            <Route path="/subadmin/dashboard" element={<SubAdminDashboard />} />
            <Route path="/subadmin/profile" element={<SubAdminProfile />} />
            <Route
              path="/subadmin/trade-members"
              element={<SubAdminTradeMembers />}
            />
            <Route path="/subadmin/users" element={<SubAdminUsers />} />
            <Route path="/subadmin/jobs" element={<SubAdminJobs />} />
            <Route
              path="/subadmin/promoted-jobs"
              element={<SubAdminPromotedJobs />}
            />
            <Route
              path="/subadmin/login-details"
              element={<SubAdminLoginDetails />}
            />
            <Route path="/subadmin/reviews" element={<SubAdminReviews />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>

      <Toaster
        containerStyle={{
          margin: "20px 0 0 - 250px",
          padding: "0 16px",
          height: "40px",
          zIndex: 9999999,
        }}
        toastOptions={{
          success: {
            style: {
              background: "#149B5F",
              padding: "16px",
              color: "#fff",
              width: "500vw",
            },
          },
          error: {
            style: {
              background: "#E61E50",
              padding: "16px",
              color: "#fff",
              width: "500vw",
            },
          },
        }}
      />
    </>
  );
}

export default App;
