import React from 'react'

const ClockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <g clipPath="url(#clip0_2238_2545)">
                <path d="M9.50033 0.791016C7.77798 0.791016 6.09432 1.30175 4.66224 2.25863C3.23016 3.21552 2.11399 4.57557 1.45488 6.16681C0.795765 7.75805 0.623311 9.50901 0.959324 11.1983C1.29534 12.8875 2.12473 14.4392 3.34261 15.6571C4.56049 16.875 6.11217 17.7043 7.80142 18.0404C9.49067 18.3764 11.2416 18.2039 12.8329 17.5448C14.4241 16.8857 15.7842 15.7695 16.741 14.3374C17.6979 12.9054 18.2087 11.2217 18.2087 9.49935C18.2059 7.19059 17.2876 4.97718 15.655 3.34464C14.0225 1.7121 11.8091 0.793739 9.50033 0.791016ZM12.435 12.4341C12.2866 12.5825 12.0853 12.6658 11.8753 12.6658C11.6654 12.6658 11.4641 12.5825 11.3156 12.4341L8.94062 10.0591C8.79214 9.91063 8.70871 9.7093 8.70866 9.49935V4.74935C8.70866 4.53939 8.79207 4.33802 8.94054 4.18956C9.089 4.04109 9.29037 3.95768 9.50033 3.95768C9.71029 3.95768 9.91166 4.04109 10.0601 4.18956C10.2086 4.33802 10.292 4.53939 10.292 4.74935V9.1716L12.435 11.3146C12.5835 11.4631 12.6668 11.6644 12.6668 11.8743C12.6668 12.0843 12.5835 12.2856 12.435 12.4341Z" fill="#366CFE" />
            </g>
            <defs>
                <clipPath id="clip0_2238_2545">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ClockIcon