import React, { useState } from "react";
import ChatSection from "./sections/ChatSection";
import ChatListing from "./sections/ChatListing";

const PersonalChat = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <div className="flex h-full w-full">
      <div className="hidden md:block">
        <ChatListing setSelectedUser={setSelectedUser} />
      </div>
      <ChatSection
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        role={"admin"}
        roomUrl={`chat/conversation/start`}
        sendMessageUrl={`chat/message/send`}
        getMessageUrl={`chat/conversation`}
      />
    </div>
  );
};

export default PersonalChat;
