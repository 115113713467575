import React, { useState } from "react";
import PaymentListCard from "../../paymentComponents/PaymentListCard";
import ListLoader from "../../loader/listLoader";

const ClientTransactions = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <div className="bg-[#f4f5f7] w-full px-1 md:px-4 lg:px-4 pt-5 poppins ">
      <div className="md:p-3 p-1 md:px-10">
        <div className="pt-7 mb-3 md:px-4 px-2 flex flex-col gap-4 pb-2 bg-white rounded-[10px] poppins">
          <div className="">
            <div className="pt-3 mb-3 md:px-4 flex  flex-col gap-4 pb-2 bg-white rounded-b-[10px]">
              {loading ? (
                <div className="w-full flex justify-center">
                  {" "}
                  <ListLoader />{" "}
                </div>
              ) : data?.length ? (
                data?.map((item, i) => <PaymentListCard />)
              ) : (
                <NothingHere />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientTransactions;

const NothingHere = () => (
  <div className="text-[20px] font-bold  leading-[24px] text-[#AAAA] mr-2 text-center	">
    No data yet.
  </div>
);
